<template>
  <div class="home" id="rootchart" :style="cssVars">
    <!-- 一行二列   -->
    <div class="content-row">
      <el-row :gutter="20">
        <el-col :xs="8" :sm="8" :md="8" :lg="8">
          <div class="charts1">
            <div class="chart_title">
              <span>CREITs </span>
              is Here ...
            </div>
            <div class="chart_content">
              <div
                id="chartColumn1"
                class="canvasArea"
                ref="chartColumn"
                style="width: 100%; height: 371px"
              ></div>
            </div>
            <div class="deadline_style data_deadline">
              数据截止日期:
              {{ dDailyTotalMvDDL }}
            </div>
          </div>
        </el-col>

        <el-col :xs="16" :sm="16" :md="16" :lg="16">
          <div class="charts2">
            <div class="chart_title">全球主要大类资产收益率对比</div>
            <!-- <div class="chart_title">Benchmark</div> -->
            <div class="legndArea">
              <span
                class="legndAreaItem"
                v-for="(node, idx) in legendName"
                :key="idx"
              >
                <span
                  :style="{ 'background-color': node.color }"
                  class="legndAreaIcon"
                ></span>
                {{ node.name }}</span
              >
            </div>
            <div class="chart_content">
              <div
                id="chartColumn2"
                ref="chartColumn2"
                style="width: 100%; height: 360px"
              ></div>
            </div>
            <div class="data_deadline2 deadline_style">
              数据截止日期: {{ sDailyReturnCompareDDL }}
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- <div class="content_row_item1">
          
      </div>

      <div class="content_row_item2">
       
      </div> -->
    </div>

    <!-- 一行二列   -->
    <div class="content-row">
      <el-row :gutter="20">
        <el-col :xs="8" :sm="8" :md="8" :lg="8">
          <div class="charts3">
            <div class="chart_title">
              <div>Markets</div>
            </div>
            <!-- <div class="updownArea">
            <CButtonsSeparate
              @Cbtns_sep="fnCbtns_sep"
              :round="true"
              :dButtons1="separrate"
              :dButtons1_current="defaultSeparrate"
            />
            </div> -->

            <div class="chart_content">
              <div class="stock_area">
                <div
                  class="stock_area_item"
                  v-for="item in dTop"
                  :key="item.index"
                  @click="fnSkip(item)"
                >
                  <span>{{ item.REITs_name }}</span>
                  <span>{{ item.reit_code }}</span>
                  <span>{{ Number(item.close).toFixed(3) }}</span>
                  <span class="upState" v-if="item.isUp > 0">{{
                    "+" + item.pct
                  }}</span>
                  <span class="flatState" v-if="item.isUp == 0">{{
                    item.pct
                  }}</span>
                  <span class="fallState" v-if="item.isUp < 0">{{
                    item.pct
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <!-- <el-col :xs="24" :sm="24" :md="24" :lg="18"> -->

        <!-- <el-row :gutter="12"> -->
        <el-col :xs="8" :sm="8" :md="8" :lg="8">
          <div class="charts5">
            <div class="chartTitle">分红\股息率</div>
            <div class="chart5Content">
              <span
                @click="fnOpenDialog"
                :class="ROOTFONTFIZEWIDTH > 1700 ? '' : 'icon-fangda6'"
                class="iconfont icon-fangda"
              ></span>
              <div
                id="chartColumn4"
                ref="chartColumn4"
                style="width: 100%; height: 450px"
              ></div>
              <div class="chart5Info">年化波动率</div>
            </div>
          </div>
        </el-col>

        <el-col :xs="8" :sm="8" :md="8" :lg="8">
          <div class="charts6">
            <div class="chartTitle">YTD总收益率</div>
            <div class="chart6Content">
              <span
                @click="fnOpenDialog2"
                :class="ROOTFONTFIZEWIDTH > 1700 ? '' : 'icon-fangda6'"
                class="iconfont icon-fangda"
              ></span>
              <div
                id="chartColumn5"
                ref="chartColumn5"
                style="width: 100%; height: 450px"
              ></div>
              <div class="chart6Info">年化波动率</div>
            </div>
          </div>
        </el-col>
        <!-- </el-row> -->

        <!-- </el-col> -->
      </el-row>
    </div>

    <!-- 一行二列   -->
    <div class="content-row">
      <el-row :gutter="12">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
          <el-col :span="24">
            <div class="charts4">
              <div class="chart_title">
                <div>Yield Spread</div>
                <span
                  @click="fnOpenDialog3"
                  class="iconfont icon-fangda icon-fangda2"
                ></span>
              </div>
              <div class="chart_content">
                <div
                  id="chartColumn3"
                  ref="chartColumn3"
                  style="width: 100%; height: 358px"
                ></div>
              </div>
              <div class="data_deadline3 deadline_style">
                数据截止日期:
                {{ dYieldSpreadDDL }}
              </div>
            </div>
          </el-col>

          <el-col :span="24"> </el-col>
        </el-col>
      </el-row>
    </div>

    <!-- :option="chartColumn4_option" -->
    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            type="primary"
            @command="fnDropdown"
            trigger="click"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="charts5">
          <div class="chartTitle" :style="{ height: height_chart }">
            分红\股息率
          </div>
          <div class="chart5Content" :style="{ height: height_chart }">
            <div
              id="chartColumn4"
              ref="chartColumn4_dialog"
              style="width: 100%; height: 100%"
            ></div>
            <div class="chart5Info">年化波动率</div>
          </div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="charts6">
          <div class="chartTitle" :style="{ height: height_chart }">
            YTD总收益率
          </div>
          <div class="chart6Content" :style="{ height: height_chart }">
            <div
              id="chartColumn5"
              ref="chartColumn5_dialog"
              style="width: 100%; height: 100%"
            ></div>
            <div class="chart6Info">年化波动率</div>
          </div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible3"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible3"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="charts4">
          <div class="chart_title">
            <div>Yield Spread</div>
          </div>
          <div class="charts4_dialog" :style="{ height: height_chart }">
            <div
              id="chartColumn3"
              ref="chartColumn3_dialog"
              style="width: 100%; height: 100%"
            ></div>

            <div class="data_deadline3 deadline_style">
              数据截止日期:
              {{ dYieldSpreadDDL }}
            </div>
          </div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CDialog from "@/components/Basic/CDialog";

import { fnGetMuchDecimals } from "@/utils/util";

export default {
  data() {
    return {
      separrate: ["All", "Up", "Down"],
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "65vh",
      top_dialog: "5vh",
      defaultSeparrate: "All",
      chartData1: {
        title: {
          text: "a",
          subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: [
              { value: 1048, name: "b" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      chartData2: {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            areaStyle: {},
          },
        ],
      },

      chartColumn: null,

      // -----------分割线---------------

      chartColumn2: null,

      // -----------分割线---------------
      chartColumn3: null,
      chartColumn3_dialog: null,

      // -----------分割线---------------

      chartColumn4: null,
      chartColumn4_dialog: null,

      // -----------分割线---------------

      chartColumn5: null,
      chartColumn5_dialog: null,

      // -----------分割线---------------
      dDailyTotalMv: [],
      dDailyTotalMv_X: [],
      dDailyTotalMv_y: [],
      dDailyTotalMvDDL: "",

      // -----------分割线---------------
      dDailyReturnCompare: [],
      ddDailyReturnCompare_X: [],
      ddDailyReturnCompare_y: [],
      ddDailyReturnCompare_Z: [],
      sDailyReturnCompareDDL: "",

      // -----------分割线---------------
      dDailyReturnVolatility_yield: [],
      dDailyReturnVolatility_yield_X: [],
      dDailyReturnVolatility_yield_Y: [],

      // -----------分割线---------------
      dDailyReturnVolatility_return: [],

      // -----------分割线---------------
      dDailyReturnYieldSpread: [],
      dDailyReturnYieldSpread_X: [],
      dDailyReturnYieldSpread_CQ: [],
      dDailyReturnYieldSpread_CN: [],
      dDailyReturnYieldSpread_YI: [],
      dDailyReturnYieldSpread_1yr: [],
      dYieldSpreadDDL: "",

      // -----------分割线---------------
      dTop: [],
      dTopTmp: [],

      dDataTmp1: [],
      dDataTmp2: [],

      ROOTFONTFIZE: 0,
      ROOTFONTFIZEWIDTH: 0,

      legendName: [],

      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
    };
  },

  created() {
    // this.fnGetDailyReturnYieldSpread();
    // this.fnGetDailyReturnVolatilityYield();
    this.test();
    this.fnGetDailyReturnVolatilityReturn();
    this.fnGetDailyTotalMv();
    this.fnGetDailyReturnCompare();
    this.fnGetTop();
  },
  mounted() {
    // this.initChart();
    // this.initChart2();
    // this.initChart3();
    // this.initChart4();
    // this.initChart5();
    this.fnGetWidth();
    window.addEventListener("resize", this.funResize);
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.funResize)
    this.chartColumn&&this.chartColumn.dispose()
    this.chartColumn2&&this.chartColumn2.dispose()
    this.chartColumn3&&this.chartColumn3.dispose()
    this.chartColumn4&&this.chartColumn4.dispose()
    this.chartColumn5&&this.chartColumn5.dispose()
  },
  methods: {
    async test() {
      await this.fnGetDailyReturnVolatilityYield();
      await this.fnGetDailyReturnYieldSpread();
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      console.log(ROOTFONTFIZE, "ROOTFONTFIZE");
      this.ROOTFONTFIZEWIDTH = ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      console.log(this.ROOTFONTFIZE, "铃木爱理");

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144) + "px";

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144) + "px";

      var chartColumn4 = document.getElementById("chartColumn4");
      chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      var chartColumn5 = document.getElementById("chartColumn5");
      chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      var chartColumn3 = document.getElementById("chartColumn3");
      chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144) + "px";

      return;
    },

    // 打开遮罩层 start
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });

      // 重点：可以记在文章里
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;

      this.$nextTick(() => {
        this.initChart5(true);
      });
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart3(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        if (this.dialogVisible3) {
          this.height_chart = "65vh";
        } else {
          this.height_chart = conf.height_chart;
        }

        this.$nextTick(() => {
          this.initChart4(true);
        });
        this.$nextTick(() => {
          this.initChart5(true);
        });
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        if (this.dialogVisible3) {
          this.height_chart = "65vh";
        } else {
          this.height_chart = conf.height_chart;
        }
        this.popUpWindowIndex = val;

        this.$nextTick(() => {
          this.initChart4(true);
        });
        this.$nextTick(() => {
          this.initChart5(true);
        });
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        if (this.dialogVisible3) {
          this.height_chart = "42vh";
        } else {
          this.height_chart = conf.height_chart;
        }
        this.popUpWindowIndex = val;

        this.$nextTick(() => {
          this.initChart4(true);
        });
        this.$nextTick(() => {
          this.initChart5(true);
        });
        this.$nextTick(() => {
          this.initChart3(true);
        });
      }
    },

    // 打开遮罩层 end

    // 点击跳转 start
    fnSkip(item) {
      let arr = [item.REITs_name, item.reit_code];
      this.$eventBus.$emit("skipPage", arr);
    },
    // 点击跳转 end

    async fnGetDailyTotalMv() {
      // const { data } = await this.$https.get("http://conghua.uii.net:8888//api/get_daily_total_mv");
      const { data } = await this.$https.get("/api/get_daily_total_mv");
      if (data.code !== 200) return this.$message.error(data.msg);
      this.dDailyTotalMv = [];
      this.dDailyTotalMv_X = [];
      this.dDailyTotalMv_y = [];
      this.dDailyTotalMv = data.data;

      for (let index = 0; index < this.dDailyTotalMv.length; index++) {
        var tr_date = this.dDailyTotalMv[index].tr_date;
        var dataVal = this.dDailyTotalMv[index].data;

        this.dDailyTotalMv_X.push(tr_date);
        this.dDailyTotalMv_y.push(dataVal);
      }

      let arrTmp1 = [...this.dDailyTotalMv_X];
      let arrTmp2 = arrTmp1[arrTmp1.length - 1].split("-");
      this.dDailyTotalMvDDL =
        arrTmp2[0] + "年" + arrTmp2[1] + "月" + arrTmp2[2] + "日";

      // this.dDailyTotalMv_X = this.dDailyTotalMv_X.map((val) => {
      //   let arr = val.split("-");
      //   return arr[0] + "年" + arr[1] + "月";
      // });

      this.dDailyTotalMv_y = this.dDailyTotalMv_y.map((val) => {
        let stringval = val + "";
        return stringval.slice(0, -8) + "";
      });
      this.$nextTick(() => {
        this.initChart();
      });
    },

    async fnGetDailyReturnCompare() {
      const { data } = await this.$https.get("/api/get_daily_return_compare");
      if (data.code !== 200) return this.$message.error(data.msg);
      this.dDailyReturnCompare = [];
      this.ddDailyReturnCompare_X = [];
      this.ddDailyReturnCompare_y = [];
      // this.ddDailyReturnCompare_Z = []
      this.dDailyReturnCompare = data.data;

      // 排序
      function fnSort(a, b) {
        return a.data - b.data;
      }
      this.dDailyReturnCompare.sort(fnSort);

      for (let index = 0; index < this.dDailyReturnCompare.length; index++) {
        let indexVal = this.dDailyReturnCompare[index].ben_name;
        let dataVal = this.dDailyReturnCompare[index].data;
        // let  ben_type =  this.dDailyReturnCompare[index].ben_type

        this.ddDailyReturnCompare_X.push(indexVal);
        this.ddDailyReturnCompare_y.push(dataVal);
        // this.ddDailyReturnCompare_Z.push(ben_type)
      }

      let ben_type = this.dDailyReturnCompare.map((node) => {
        return node.ben_type;
      });

      ben_type = [...new Set(ben_type)];

      // this.legendName = ben_type.map(node=>{
      //   if (node == "cn_equity") {
      //         return "境内股权";
      //       }
      //       if (node == "global_equity") {
      //         return "全球权益";
      //       }
      //       if (node == "REITs" || node == "公募REITs") {
      //         return "REITs";
      //       }
      //       if (node == "bond") {
      //         return "债权类";
      //       }
      //       if (node == "commodity") {
      //         return "大宗商品";
      //       }

      // })

      let colors = this.$store.state.BenchMarkcolors;
      this.legendName = ben_type.map((node) => {
        let obj = {
          name: node,
          color: "",
        };

        colors.forEach((e) => {
          if (node == e.name) {
            obj.color = e.value;
          }
        });

        return obj;
      });

      this.legendName.map((node) => {
        if (node.name == "cn_equity") {
          node.name = "境内股权";
        }
        if (node.name == "global_equity") {
          node.name = "全球权益";
        }
        if (node.name == "REITs" || node.name == "公募REITs") {
          node.name = "REITs";
        }
        if (node.name == "bond") {
          node.name = "债券";
        }
        if (node.name == "commodity") {
          node.name = "大宗商品";
        }
      });

      this.ddDailyReturnCompare_y = this.ddDailyReturnCompare_y.map((val) => {
        let numVal = val * 100;
        numVal = numVal.toFixed(2);
        return numVal;
      });

      let arr = this.dDailyReturnCompare[0].tr_date.split("-");
      this.sDailyReturnCompareDDL =
        arr[0] + "年" + arr[1] + "月" + arr[2].substring(0, 2) + "日";
      this.$nextTick(() => {
        this.initChart2(ben_type);
      });
    },

    async fnGetDailyReturnVolatilityYield() {
      const { data } = await this.$https.get(
        "/api/get_daily_return_volatility?yname=div_yield"
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dDailyReturnVolatility_yield = [];
      this.dDailyReturnVolatility_yield_X = [];
      this.dDailyReturnVolatility_yield_Y = [];
      let dataTmp = data.data;
      this.dDataTmp1 = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        let arr = [];
        let yieldVal = dataTmp[index].div_yield * 100;
        // let yieldVal = dataTmp[index].div_yield ;

        yieldVal = yieldVal.toFixed(2);

        let riskVal = dataTmp[index].risk * 100;
        riskVal = riskVal.toFixed(2);

        let indexVal = dataTmp[index].ben_name;
        let ben_type = dataTmp[index].ben_type;

        if (dataTmp[index].ben_name == "公募REITs") {
          arr.push("公募REITs");
        } else {
          arr.push(ben_type);
        }

        arr.push(riskVal);
        arr.push(yieldVal);
        arr.push(indexVal);

        this.dDailyReturnVolatility_yield.push(arr);

        this.$nextTick(() => {
          this.initChart4();
        });

        // let  ben_type =  this.dDailyReturnCompare[index].ben_type

        // this.ddDailyReturnCompare_y.push(dataVal)
        // this.ddDailyReturnCompare_Z.push(ben_type)
      }
    },

    async fnGetDailyReturnVolatilityReturn() {
      const { data } = await this.$https.get(
        "/api/get_daily_return_volatility?yname=return"
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dDailyReturnVolatility_return = [];

      let dataTmp = data.data;
      this.dDataTmp2 = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        let arr = [];
        // 1. 格式化一下值
        let returnVal = dataTmp[index].return * 100;
        returnVal = returnVal.toFixed(2);

        let riskVal = dataTmp[index].risk * 100;
        riskVal = riskVal.toFixed(2);

        //2. 取下另外2个值
        let indexVal = dataTmp[index].ben_name;
        let ben_type = dataTmp[index].ben_type;

        if (dataTmp[index].ben_name == "公募REITs") {
          arr.push("公募REITs");
        } else {
          arr.push(ben_type);
        }

        arr.push(riskVal);
        arr.push(returnVal);
        arr.push(indexVal);
        this.dDailyReturnVolatility_return.push(arr);
      }
      this.$nextTick(() => {
        this.initChart5();
      });
    },

    async fnGetDailyReturnYieldSpread() {
      const { data } = await this.$https.get("/api/get_yield_spread");

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dDailyReturnYieldSpread = [];
      this.dDailyReturnYieldSpread_X = [];
      this.dDailyReturnYieldSpread_CQ = [];
      this.dDailyReturnYieldSpread_CN = [];
      this.dDailyReturnYieldSpread_YI = [];

      let dataTmp = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dDailyReturnYieldSpread_X.push(dataTmp[index].tr_date);
        this.dDailyReturnYieldSpread_CQ.push(dataTmp[index].产权类 * 100);
        this.dDailyReturnYieldSpread_CN.push(dataTmp[index].CN10YR * 100);
        this.dDailyReturnYieldSpread_YI.push(dataTmp[index].yield_spread * 100);
      }

      let today = new Date(); // 获取今天的日期
      today.setFullYear(today.getFullYear() - 1); // 将年份减1，获取去年的日期
      let millisecond = today.getTime();
      let arrNew = dataTmp.filter((node) => {
        let val1 = new Date(node.tr_date).getTime();
        if (millisecond <= val1) {
          return node;
        }
      });

      this.dDailyReturnYieldSpread_1yr = arrNew.map((node) => {
        return node.yield_spread * 100;
      });

      // this.dDailyReturnYieldSpread_X = this.dDailyReturnYieldSpread_X.map(
      //   (val) => {
      //     let arr = val.split("-");
      //     return arr[0] + "年" + arr[1] + "月";
      //   }
      // );

      this.dYieldSpreadDDL =
        this.dDailyReturnYieldSpread_X[
          this.dDailyReturnYieldSpread_X.length - 1
        ];
      let arr = this.dYieldSpreadDDL.split("-");
      this.dYieldSpreadDDL = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";
      this.$nextTick(() => {
        this.initChart3();
      });
    },

    async fnGetTop() {
      const { data } = await this.$https.get("/api/get_top10_pct");

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dTop = data.data;

      this.dTop = this.dTop.map((item) => {
        // item["isUp"] = item.pct > 0 ? true : false;
        item["isUp"] = item.pct;
        let val = item.pct * 100;
        val = val.toFixed(2);
        val = val + "%";
        item.pct = val;
        return item;
      });

      this.dTopTmp = this.dTop;
    },

    initChart() {
      this.chartColumn = this.$echarts.init(this.$refs.chartColumn);
      this.chartColumn.clear();

      let pointerVal = this.dDailyTotalMv_y[this.dDailyTotalMv_y.length - 1];
      pointerVal = Number(pointerVal).toFixed(1);

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let flag = this.$store.state.theme == "dark" ? true : false;

      let max = Math.max(...this.dDailyTotalMv_y);
      let posi =
        Number(max) -
        Number(this.dDailyTotalMv_y[this.dDailyTotalMv_y.length - 1]);

      let option1 = {
        // title: {
        //   text: "单位：亿元", // 标题文字
        //   textStyle: {
        //     color: "rgba(218, 218, 218, 1)",
        //     fontSize: this.ROOTFONTFIZE * (15 / 144),
        //   },
        //   top: this.ROOTFONTFIZE * (20 / 144),
        //   left: this.ROOTFONTFIZE * (70 / 144),
        // },
        grid: {
          x: this.ROOTFONTFIZE * (30 / 144),
          x2: this.ROOTFONTFIZE * (50 / 144),
          top: this.ROOTFONTFIZE * (40 / 144),
          bottom: this.ROOTFONTFIZE * (45 / 144),
          containLabel: true,
        },
        tooltip: {
          // 工具提示
          trigger: "axis",
          triggerOn: "mousemove|click", // 触发时机, click代表点击, mouseOver代表鼠标移过
          // formatter: function (args) {
          //   // 文字格式化
          //   return args[0].name + "的市值是：" + args[0].data;
          // },
        },
        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          interval: "2",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            margin: this.ROOTFONTFIZE * (20 / 144),
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            // color:var(--chart_title)
          },

          data: this.dDailyTotalMv_X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          name: "单位（亿元）",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: true,
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            // formatter:function(val){
            //   let num = fnGetMuchDecimals(val)
            //   return val.toFixed(num)
            // }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: "CREITs",
            data: this.dDailyTotalMv_y,
            type: "line",

            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: flag ? "#3a313a" : "#c2c2c2", // 起始颜色和透明度
                  },
                  // {
                  //   offset: 0.5,
                  //   color: "#2b2a33", // 结束颜色和透明度
                  //   // color: "#2b2a33", // 结束颜色和透明度
                  // },
                  {
                    offset: 1,
                    // color: "#1a202a", // 结束颜色和透明度
                    color: flag ? "#313233" : "#c2c2c2",
                  },
                ],
              },
            },
            lineStyle: {
              color: "#8d5d5f",
            },
            itemStyle: {
              color: "#8d5d5f",
            },
            symbol: "none",
            markPoint: {
              label: {
                show: true,
                fontSize: this.ROOTFONTFIZE * (16 / 144),
                position: posi > 50 ? "top" : "left",
              },
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: [
                {
                  name: "最后日期的数据",
                  yAxis: this.dDailyTotalMv_y[this.dDailyTotalMv_y.length - 1],

                  xAxis: this.dDailyTotalMv_X[this.dDailyTotalMv_X.length - 1],

                  label: {
                    normal: {
                      show: true,
                      formatter: `{d|${pointerVal}} \n   {b|亿市值}`,
                      rich: {
                        d: {
                          color: "#d43030",
                          fontSize: this.ROOTFONTFIZE * (24 / 144),
                          align: "right",
                          fontWeight: "bolder",
                        },
                        b: {
                          color: flag ? "#cbcbcc" : "#404040",
                          fontSize: this.ROOTFONTFIZE * (14 / 144),
                          align: "left",
                        },
                      },
                    },
                  },
                },
              ],
              symbol: "circle", // 标记图形
              symbolSize: this.ROOTFONTFIZE * (18 / 144),
              // 标注点的样式
              itemStyle: {
                color: "#d43030", // 标注点颜色
                borderColor: "#fff",
                borderWidth: this.ROOTFONTFIZE * (2 / 144),
                label: {
                  show: true,
                },
              },
            },
            // itemStyle : { normal: {label : {show: true}}}
            label: {
              show: false,
              position: "top",
            },
          },
        ],
      };
      this.chartColumn.setOption(option1);
      this.chartColumn.resize();

      // this.funResize()
    },
    initChart2(ben_type) {
      this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);

      let Y = this.ddDailyReturnCompare_y;
      let colors = this.$store.state.BenchMarkcolors;

      let dDailyReturnCompare = this.dDailyReturnCompare;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let flag = this.$store.state.theme == "dark" ? true : false;

      let option2 = {
        grid: {
          //方法 2
          top: this.ROOTFONTFIZE * (30 / 144),
          // bottom: this.ROOTFONTFIZE * (45 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          // y2: '32%',
          containLabel: true,
        },
        tooltip: {
          // 工具提示
          // trigger:'item',
          trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
          triggerOn: "mousemove|click", // 触发时机, click代表点击, mouseOver代表鼠标移过
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node) => {
              str += `${node.marker}${node.seriesName}：${node.value}%`;

              return str;
            });
            return str; // 文字格式化 // return args[0].name + " 的YTD回报率：" + args[0].value + "%";
          },
        },
        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: this.ddDailyReturnCompare_X,
          axisLine: {
            show: true,
            onZero: true, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },
          boundaryGap: true,

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            rotate: 25,
            show: true,
            padding: [0, this.ROOTFONTFIZE * (-30 / 144), 0, 0],
            margin: this.ROOTFONTFIZE * (30 / 144),
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            // formatter: "{value}%", //y轴百分比
            formatter: function (val) {
              let num = fnGetMuchDecimals(val);
              if (val == 0) {
                return val;
              } else {
                return val.toFixed(num) + "%";
              }
            },
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: "YTD回报率",
            type: "bar",
            data: this.ddDailyReturnCompare_y.map(function (item) {
              return {
                value: item,
                label: {
                  show: true,
                  position: item > 0 ? "top" : "bottom",
                },
              };
            }),

            // data: this.ddDailyReturnCompare_y,

            itemStyle: {
              color: function (params) {
                let name = params.name;
                let obj = dDailyReturnCompare.find((item) => {
                  return item.ben_name === name;
                });
                let obj2 = {};
                if (obj.ben_name === "公募REITs") {
                  obj2 = colors.find((item) => {
                    return item.name === "REITs";
                  });
                } else {
                  obj2 = colors.find((item) => {
                    return item.name === obj.ben_type;
                  });
                }
                return obj2.value;
              },

              barBorderRadius: function (params) {},
            },
            barWidth: this.ROOTFONTFIZE * (16 / 144) + "px",
            markLine: {
              symbol: ["none", "none"], // none为标线两端的样式为无，可更改
              data: [
                {
                  yAxis: 0,
                },
              ],
              label: {
                show: false,
              },

              lineStyle: {
                type: "solid",
                color: "#7a7a79",
              },
            },
            label: {
              formatter: function (value) {
                if (value.name == "公募REITs") {
                  return `{a|${value.value}%} \n {val|YTD回报率}`;
                } else {
                  return "";
                }
              },
              rich: {
                a: {
                  color: colors.find((node) => node.name == "REITs").value,
                  // color: "#5ae8ed",

                  fontSize: this.ROOTFONTFIZE * (24 / 144),
                  fontWeight: "bolder",
                },
                val: {
                  color: flag ? "#bababa" : "#404040",
                  fontSize: this.ROOTFONTFIZE * (14 / 144),
                  verticalAlign: "bottom",
                },
              },
            },
          },
        ],
      };
      this.chartColumn2.setOption(option2);
      this.chartColumn2.resize();
    },
    initChart3(val) {
      if (!val) {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      } else {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3_dialog);
      }

      // this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);

      let xData = this.dDailyReturnYieldSpread_X;
      let cQData = this.dDailyReturnYieldSpread_CQ;
      cQData = cQData.map((node) => {
        return node.toFixed(2);
      });

      let cNData = this.dDailyReturnYieldSpread_CN;
      cNData = cNData.map((node) => {
        return node.toFixed(2);
      });

      let yIData = this.dDailyReturnYieldSpread_YI;
      yIData = yIData.map((node) => {
        return node.toFixed(2);
      });
      let yIDataPointValue = yIData[yIData.length - 1] * 100;
      yIDataPointValue = yIDataPointValue.toFixed(0);
      yIDataPointValue = yIDataPointValue;

      let oneYrData = this.dDailyReturnYieldSpread_1yr;
      oneYrData = oneYrData.map((node) => {
        return node.toFixed(3);
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option3 = {
        legend: [
          {
            show: true,
            itemWidth: this.ROOTFONTFIZE * (30 / 144),
            itemHeight: this.ROOTFONTFIZE * (12 / 144),
            data: [
              {
                name: "Yield Spread",
                icon: "rect",
              },
            ],

            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            x: this.ROOTFONTFIZE * (20 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
          {
            show: true,
            icon: "roundRect",
            itemWidth: this.ROOTFONTFIZE * (27 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            inactiveColor: "#ccc",
            data: [{ name: "10年期国债收益率", icon: "roundRect" }],

            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            x: this.ROOTFONTFIZE * (320 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
          {
            show: true,
            icon: "roundRect",
            itemWidth: this.ROOTFONTFIZE * (27 / 144),
            itemHeight: this.ROOTFONTFIZE * (5 / 144),
            inactiveColor: "#ccc",
            data: [{ name: "CREITs产权类分派率 ", icon: "roundRect" }],

            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: this.ROOTFONTFIZE * (14 / 144),
            },
            x: this.ROOTFONTFIZE * (150 / 144),
            y: this.ROOTFONTFIZE * (20 / 144),
          },
        ],

        grid: {
          left: this.ROOTFONTFIZE * (25 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          top: this.ROOTFONTFIZE * (58 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            args.filter((ele) => {
              ele.seriesName !== "CREITs 1yr Avg. of";
            });

            let obj = args.find((element) => {
              if (element.seriesName == "CREITs 1yr Avg. of") {
                return element;
              }
            });

            if (obj) {
              args.splice(obj.seriesIndex);
            }

            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let val = Number(node.value).toFixed(2);
              if (node.seriesName == "Yield Spread") {
                val = Number(val * 100).toFixed(2) + "bps";
              } else {
                val = val + "%";
              }

              // str += `${node.marker}${node.seriesName}:   ${val}`;
              str += `<span style=" display: flex;justify-content: space-between;align-items: center;"><span>${node.marker}${node.seriesName}：</span><span>${val}</span></span>`;

              // str += "<br>";

              return str;
            });

            return str;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          interval: "2",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            show: true,
            margin: this.ROOTFONTFIZE * (20 / 144),
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          data: xData,
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
            // formatter: function (args) {
            //   let argsVal = args.toFixed(1) + "%";
            //   return argsVal;
            // },
            formatter: function (val) {
              let num = fnGetMuchDecimals(val);
              if (val == 0) {
                return val;
              } else {
                return val.toFixed(num) + "%";
              }
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "solid",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: "Yield Spread",
            data: yIData,
            type: "line",
            areaStyle: {
              color: "#932d13",
            },
            itemStyle: {
              color: "#702918",
              width: 1,
            },
            symbol: "none",
            markPoint: {
              label: {
                show: true,
                position: [-135, -55],
              },
              data: [
                {
                  name: "最后日期的数据",
                  // yAxis: yIData[yIData.length - 1],
                  yAxis: 0,
                  xAxis: xData[xData.length - 1],
                  label: {
                    normal: {
                      show: true,
                      formatter: `  {d|Yield Spread}\n\n{b|${yIDataPointValue}bps}`,
                      rich: {
                        d: {
                          color: "#fff",
                          align: "right",

                          fontSize: this.ROOTFONTFIZE * (20 / 144),
                        },
                        b: {
                          align: "right",
                          color: "#fff",
                          fontSize: this.ROOTFONTFIZE * (20 / 144),
                        },
                      },
                    },
                  },
                },
              ],
              symbol: "circle", // 标记图形
              symbolSize: 0,
              itemStyle: {
                color: "#d43030", // 标注点颜色
                label: {
                  show: true,
                },
              },
            },

            label: {
              show: false,
              position: "top",
              formatter: "{c}%",
            },
          },
          {
            name: "CREITs产权类分派率 ",
            data: cQData,
            type: "line",

            itemStyle: {
              color: "#ff3300",
            },
            lineStyle: {
              width: this.ROOTFONTFIZE * (3 / 144),
            },
            symbol: "none",
            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: [
                {
                  name: "固定 x 像素位置",
                  yAxis: 1020,
                  x: "98%",
                  label: "sdas",
                },
              ],
              symbol: "circle", // 标记图形
              symbolSize: 18,
              // 标注点的样式
              itemStyle: {
                color: "#d43030", // 标注点颜色
                borderColor: "#fff",
                borderWidth: 2,
              },
              label: {
                show: true,
              },
            },
            label: {
              show: false,
              position: "top",
            },
          },
          {
            name: "10年期国债收益率",
            data: cNData,
            type: "line",

            itemStyle: {
              // color: "#b1b9ec",
              // color: "#5ae8ed",
              color: "#2b7eb3",
            },
            lineStyle: {
              width: 3,
            },
            symbol: "none",
            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: [
                {
                  name: "固定 x 像素位置",
                  yAxis: 1020,
                  x: "98%",
                  label: "sdas",
                },
              ],
              symbol: "circle", // 标记图形
              symbolSize: 18,
              // 标注点的样式
              itemStyle: {
                color: "#d43030", // 标注点颜色
                borderColor: "#fff",
                borderWidth: 2,
              },
              label: {
                show: true,
              },
            },
            label: {
              show: false,
              position: "top",
            },
          },
          {
            name: "CREITs 1yr Avg. of",
            data: oneYrData,
            type: "line",
            itemStyle: {
              show: false,
              width: 0,
              color: "rgba(0, 0, 0, 0)",
            },
            symbol: "none",

            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  type: "average",
                  name: "平均值",
                },
              ],
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.markLine.lineStyle.color,
              },
              label: {
                show: true,
                position: "start",
                padding: [-10, -200, 15, -45],
                fontSize: 16,
                formatter: function (args) {
                  let val = args.value * 100;
                  val = val.toFixed(0);
                  val = val + "bps";
                  return `{title|CREITs 1yr Avg. of}  {value|${val}} `;
                },
                rich: {
                  title: {
                    // color: "#b6b6b6",
                    color: OPTIONDATA.label.color,
                    fontSize: 14,
                  },
                  value: {
                    color: "#e0360c",
                    fontSize: 16,
                    fontWeight: "bolder",
                  },
                },
              },
            },
          },
        ],
      };

      this.chartColumn3.setOption(option3);
      this.chartColumn3.resize();
    },
    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      } else {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4_dialog);
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      // this.chartColumn4_dialog = this.$echarts.init(this.$refs.chartColumn4_dialog);

      let data = this.dDailyReturnVolatility_yield;
      let dDailyReturnCompare = this.dDailyReturnCompare;
      let dDataTmp1 = this.dDataTmp1;
      let colors = this.$store.state.BenchMarkcolors;

      let Name = data.map((node) => {
        if (node[0] == "公募REITs") {
          return "REITs";
        } else {
          return node[0];
        }
      });

      Name = [...new Set(Name)];

      let colorArr = [];
      Name.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = data.map((v, index) => {
        return {
          name: v[0] == "公募REITs" ? "REITs" : v[0],
          // name: v[0],
          data: [[v[1], v[2]]],
          type: "scatter",
          symbolSize: this.ROOTFONTFIZE * (13 / 144),

          cursor: "pointer",

          label: {
            show: true,
            position: "top",
            fontSize: this.ROOTFONTFIZE * (13 / 144),
            textStyle: {
              color: OPTIONDATA.title.textStyle.color,
            },
            formatter: function (val) {
              let val1 = val.seriesIndex;
              return dDataTmp1[val1].ben_name;
            },
          },
        };
      });

      let option4 = {
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },

        title: {
          // 标题设置
          text: "主要全球大类资产年化波动率与分红/股息率对比", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize:
              this.ROOTFONTFIZEWIDTH > 1700
                ? this.ROOTFONTFIZE * (16 / 144)
                : this.ROOTFONTFIZE * (14 / 144),
          },
          left: 0,
          top: this.ROOTFONTFIZE * (20 / 144),
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str +=
              data[args[0].seriesIndex][data[args[0].seriesIndex].length - 1];
            str += "<br>";
            let name = `分红\股息率`;
            let value = Number(args[0].value[1]);
            let num = (parseInt(Math.abs(value)) + "").length >= 2 ? 1 : 2;
            value = Number(value).toFixed(num) + "%";
            str += `${args[0].marker}${name}：${value}`;

            str += "<br>";
            let name2 = `年化波动率`;
            let value2 = Number(args[0].value[0]);
            let num2 = (parseInt(Math.abs(value2)) + "").length >= 2 ? 1 : 2;
            value2 = Number(value2).toFixed(num2) + "%";
            str += `${args[0].marker}${name2}：${value2}`;

            return str;
          },
        },
        grid: {
          //方法 2
          top: this.ROOTFONTFIZE * (80 / 144),
          left: this.ROOTFONTFIZE * (0 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          containLabel: true,
        },
        color: colorArr,
        legend: {
          orient: "horizontal",
          show: false,
          top: this.ROOTFONTFIZE * (60 / 144),
          textStyle: {
            color: "#fff",
            fontSize: this.ROOTFONTFIZE * (12 / 144),
          },

          formatter: function (val) {
            if (val == "cn_equity") {
              return "境内股权";
            }
            if (val == "global_equity") {
              return "全球权益";
            }
            if (val == "REITs" || val == "公募REITs") {
              return "REITs";
            }
            if (val == "bond") {
              return "债权类";
            }
            if (val == "commodity") {
              return "大宗商品";
            }
            // if (val == "公募REITs") {
            //   return "公募REITs";
            // }
          },
        },
        xAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            margin: 20,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            // formatter: "{value}%", //y轴百分比
            formatter: function (val) {
              return val.toFixed(fnGetMuchDecimals(val)) + "%";
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            formatter: function (val) {
              let num = fnGetMuchDecimals(val);
              if (val == 0) {
                return val;
              } else {
                return val.toFixed(num) + "%";
              }
            },
            // formatter: "{value}%", //y轴百分比
            // color: "#c8c8c8",
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: true,
            lineStyle: {
              // color: "#48494c",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        series: seriesArr,
      };
      this.chartColumn4_option = option4;

      //  this.chartColumn4_dialog.setOption(option4);
      //  this.chartColumn4_dialog.resize();

      this.chartColumn4.setOption(option4);
      this.chartColumn4.resize();
    },
    initChart5(val) {
      if (!val) {
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      } else {
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5_dialog);
      }

      // this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);

      var data = this.dDailyReturnVolatility_return;

      let dDataTmp1 = this.dDataTmp2;

      let colors = this.$store.state.BenchMarkcolors;

      let Name = data.map((node) => {
        if (node[0] == "公募REITs") {
          return "REITs";
        } else {
          return node[0];
        }
      });

      Name = [...new Set(Name)];

      let colorArr = [];
      Name.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let seriesArr = data.map((v, index) => {
        return {
          name: v[0] == "公募REITs" ? "REITs" : v[0],
          // name: v[0],
          data: [[v[1], v[2]]],
          type: "scatter",
          symbolSize: this.ROOTFONTFIZE * (13 / 144),
          // label: {
          //   show: true,
          //   position: "left",
          //   color: "#333",
          //   formatter: function (param) {
          //     return v[0];
          //   },
          // },
          cursor: "pointer",

          label: {
            show: true,
            position: "top",
            fontSize: this.ROOTFONTFIZE * (13 / 144),
            textStyle: {
              color: OPTIONDATA.title.textStyle.color,
            },
            formatter: function (val) {
              let val1 = val.seriesIndex;
              return dDataTmp1[val1].ben_name;
            },
          },
        };
      });

      let option5 = {
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },

        title: {
          // 标题设置
          text: "主要全球大类资产年化波动率与YTD总收益率对比", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize:
              this.ROOTFONTFIZEWIDTH > 1700
                ? this.ROOTFONTFIZE * (16 / 144)
                : this.ROOTFONTFIZE * (14 / 144),
          },
          left: 0,
          top: this.ROOTFONTFIZE * (20 / 144),
        },
        color: colorArr,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str +=
              data[args[0].seriesIndex][data[args[0].seriesIndex].length - 1];
            //               str += `YTD总收益率`;
            str += "<br>";
            let name = "YTD总收益率";
            let value = Number(args[0].value[1]);
            let num = fnGetMuchDecimals(value);
            value = Number(value).toFixed(num) + "%";
            // str += `${args[0].marker}${name}：${value}`;
            str += `<span style=" display: flex;justify-content: space-between;align-items: center;"><span>${args[0].marker}${name}：</span><span>${value}</span></span>`;

            // str += "<br>";
            let name2 = `年化波动率`;
            let value2 = Number(args[0].value[0]);
            let num2 = fnGetMuchDecimals(value2);
            value2 = Number(value2).toFixed(num2) + "%";
            str += `<span style=" display: flex;justify-content: space-between;align-items: center;"><span>${args[0].marker}${name2}：</span><span>${value2}</span></span>`;
            return str;
          },
        },
        grid: {
          //方法 2
          top: this.ROOTFONTFIZE * (80 / 144),
          left: this.ROOTFONTFIZE * (15 / 144),
          bottom: this.ROOTFONTFIZE * (60 / 144),
          containLabel: true,
        },
        legend: {
          orient: "horizontal",
          show: false,
          top: this.ROOTFONTFIZE * (60 / 144),
          textStyle: {
            color: "#fff",
            fontSize: this.ROOTFONTFIZE * (12 / 144),
          },

          // align:'center',

          formatter: function (val) {
            if (val == "cn_equity") {
              return "境内股权";
            }
            if (val == "global_equity") {
              return "全球权益";
            }
            if (val == "REITs" || val == "公募REITs") {
              return "REITs";
            }
            if (val == "bond") {
              return "债权类";
            }
            if (val == "commodity") {
              return "大宗商品";
            }
          },
        },
        xAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            margin: 20,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (val) {
              return val.toFixed(fnGetMuchDecimals(val)) + "%";
            }, //y轴百分比
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#48494c",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            // formatter: "{value}%", //y轴百分比
            formatter: function (val) {
              let num = fnGetMuchDecimals(val);
              if (val == 0) {
                return val;
              } else {
                return val.toFixed(num) + "%";
              }
            },
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        series: seriesArr,
      };

      this.chartColumn5.setOption(option5);
      this.chartColumn5.resize();
    },
    funResize() {
      this.$nextTick(() => {
        this.initChart();
        this.initChart2();
        this.initChart3();
        this.initChart4();
        // this.chartColumn.resize();
        // this.chartColumn2.resize();
        // this.chartColumn3.resize();
        // this.chartColumn4.resize();
        this.chartColumn5&&this.chartColumn5.resize();
      });
    },

    fnCbtns_sep(val) {
      this.defaultSeparrate = val;
      if (val == "All") {
        this.dTop = this.dTopTmp;
      }

      if (val == "Up") {
        this.dTop = this.dTopTmp.filter((node) => {
          return node.isUp > 0;
        });
      }

      if (val == "Down") {
        this.dTop = this.dTopTmp.filter((node) => {
          return node.isUp < 0;
        });
      }
    },
  },
  components: {
    CButtonsSeparate,
    CDialog,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--HEIGHTDIALOG": this.height_dialog,
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.$nextTick(() => {
        this.initChart();
        this.initChart2();
        this.initChart3();
        this.initChart4();
        this.initChart5();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  padding-right: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

.content_row {
  display: flex;
  padding: 0 !important;
}

.content_row_item1 {
  flex: 8;
  margin-right: 20px;
}

.content_row_item2 {
  flex: 16;
}

.row2_item2 {
  display: flex;
  justify-content: space-between;
}

// .charts5{
//   flex: 1;
//   width: 100%;
//   background-color: pink !important;
//   // margin-right: 20px;
// }

// .charts6{
//   flex: 1;
// }

.content-row {
  padding: 0 !important;
}

.el-row {
  padding: 0 !important;
  margin: 0 !important;
}

.el-col:nth-child(1) {
  padding-left: 0 !important;
}

.content {
  min-height: 70vh !important;
  height: calc(var(--ROOTFONTFIZE) * (300 / 144)) !important;
  background-color: none !important;
}

.one {
  background-color: pink;
}

.charts1,
.charts2,
.charts3,
.charts4,
.charts5,
.charts6 {
  background-color: #1d1f25;
  background-color: var(--charts_bgc);
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  height: calc(var(--ROOTFONTFIZE) * (420 / 144));
  min-height: calc(var(--ROOTFONTFIZE) * (420 / 144));
  max-height: calc(var(--ROOTFONTFIZE) * (420 / 144));
  box-sizing: border-box;
  // height: auto;
}

.charts1 {
  // height: 183px;
  height: calc(var(--ROOTFONTFIZE) * (383 / 144));
}

.charts2 {
  // height: 183px;
  height: calc(var(--ROOTFONTFIZE) * (383 / 144));
}

.charts3 {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (24 / 144));
}

.chart_title {
  color: var(--chart_title);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  // border-left: 5px solid #1573fe;
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  position: relative;
}

.legndArea {
  display: flex;
  justify-content: center;
}

.legndAreaItem {
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  color: var(--legndAreaItem_color);
  display: flex;
  align-items: center;
}

.legndAreaIcon {
  display: inline-block;
  width: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (14 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (5 / 144));
  border-radius: 50%;
}

.updownArea {
  display: flex;
  justify-content: end;
}

.chart_title span {
  color: var(--primary-color);
}

canvas {
  padding: 0px;
}

.charts4 {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.charts5,
.charts6 {
  margin-top: calc(var(--ROOTFONTFIZE) * (12 / 144));
  height: calc(var(--ROOTFONTFIZE) * (174 / 144));
}

.charts5,
.charts6 {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // height: 395px;
  height: calc(var(--ROOTFONTFIZE) * (430 / 144));
}

.charts3 .chart_title,
.charts4 .chart_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.charts4_dialog {
}

.plain.el-button {
  background-color: #1d1f25;
  opacity: 1;
  color: #9e9e9e;
  border: 1px solid #31353f;
}

.stock_area {
  color: #fff;
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.stock_area_item {
  padding: calc(var(--ROOTFONTFIZE) * (5 / 144)) 0;
  margin: calc(var(--ROOTFONTFIZE) * (2 / 144)) 0;
  padding-left: calc(var(--ROOTFONTFIZE) * (29 / 144));
  display: flex;
  align-items: center;
}

.stock_area_item:hover {
  cursor: pointer;
}

.stock_area_item span {
  flex: 3;
  margin-right: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.stock_area_item span:nth-child(1) {
  width: calc(var(--ROOTFONTFIZE) * (71 / 144));
  display: inline-block;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;

  color: var(--stock_area_item_color1);
  // color: var(--stock_area_item_color1);
}

.stock_area_item span:nth-child(2) {
  width: calc(var(--ROOTFONTFIZE) * (71 / 144));
  display: inline-block;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
}

.stock_area_item span:nth-child(2) {
  width: calc(var(--ROOTFONTFIZE) * (64 / 144));
  display: inline-block;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  letter-spacing: 0.4px;
  // color: var(--stock_area_item_color2);
  color: var(--stock_area_item_color1);
  // text-align: center;
}

.stock_area_item span:nth-child(3) {
  display: inline-block;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: var(--stock_area_item_color1);
  text-align: center;
  vertical-align: middle;
}

.stock_area_item span:nth-child(4) {
  flex: 2;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  text-align: center;
}

.fallState {
  background: var(--stock_area_item_fall_bgc);
  color: rgba(49, 208, 104, 1);
  padding: calc(var(--ROOTFONTFIZE) * (4 / 144))
    calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (70 / 144));
  letter-spacing: 0.41px;
}

.upState {
  // background-color: #453945;
  background: rgba(250, 92, 179, 0.1);
  // color: #e46e55;
  color: rgba(243, 95, 64, 1);
  padding: calc(var(--ROOTFONTFIZE) * (4 / 144))
    calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (70 / 144));
  letter-spacing: 0.41px;
}

.flatState {
  // background-color: #474747;
  background: var(--stock_area_item_flat_bgc);
  // background-color: #F5F5F5;
  // color: #b9b9b9;
  color: var(--stock_area_item_flat_color);
  padding: calc(var(--ROOTFONTFIZE) * (4 / 144))
    calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (70 / 144));
  letter-spacing: 0.41px;
}

.canvasArea {
  width: 100%;
  padding: 0;
}

.charts1,
.charts2 {
  position: relative;
}
.data_deadline {
  position: absolute;
  bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  left: 5%;
  font-weight: normal;
}

.data_deadline2 {
  position: absolute;
  bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.data_deadline3 {
  position: absolute;
  bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  left: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

/* ----------------- */
.charts5,
.charts6 {
  display: flex;
  padding: 0 !important;
}

.charts5 .chartTitle,
.charts6 .chartTitle {
  writing-mode: vertical-rl;
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (12 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: calc(var(--ROOTFONTFIZE) * (5 / 144));
  color: var(--legndAreaItem_color);
  text-align: center;
}

.charts5 .chart5Content,
.charts6 .chart6Content {
  width: 100%;
  margin: 0 !important;
  position: relative;
  padding-left: 0 !important;
  position: relative;
}

.chart5Info,
.chart6Info {
  position: absolute;
  left: 50%;
  transform: translateX(-90%);
  bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  color: var(--legndAreaItem_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
}
</style>
