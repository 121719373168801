<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  created(){
      let val = localStorage.getItem("Theme");
      this.$store.commit("setTheme", val);


      // if(val == 'light'){
      //     document.body.classList.add('conghua-light')
      //   }else{
      //     document.body.classList.remove('conghua-light')
      //   }

  },
  //监听vuex中的主题
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  mounted() {
  window.addEventListener('popstate', this.handleBackButton);
},
  beforeDestroy() {
  window.removeEventListener('popstate', this.handleBackButton);
},
  watch: {
    currentTheme(newVal, oldVal) {
         if(newVal == 'light'){
          document.body.classList.add('conghua-light')
        }else{
          document.body.classList.remove('conghua-light')
        }
    },
  },
  methods:{
    handleBackButton(val){
      // console.log(val,'肥肥肥肥肥肥');
    }
  }
 
}
</script>

<style>

*{
  padding: 0;
  margin: 0;
}

#app{
  /* zoom:'0.8'; */
  /* transform: scale(0.8); */
  /* width: 125%; */
  /* height: 125%; */ 
}

</style>
