import Vue from 'vue'
import { Button } from 'element-ui'
import { Form,FormItem } from 'element-ui'
import { Input } from 'element-ui'
import { Message , 
   Container,
   Header,
   Aside,
   Main,
   Menu,
   Submenu,
   MenuItemGroup,
   MenuItem,
   Breadcrumb,
   BreadcrumbItem,
   Card,
   Row,
   Col,
   Table,
   TableColumn,
   Switch,
   Tooltip,
   Pagination,
   Dialog,
   MessageBox,
   Popconfirm,
   Tag,
   Dropdown,
   DropdownMenu,
   DropdownItem,
   Drawer,
   Notification,
   DatePicker,
   Tabs,
   TabPane,
   Tree,
   Popover,
   Radio,
   RadioGroup,
   Cascader,
   Checkbox,
   CheckboxGroup,
   CheckboxButton,
   Transfer,
   Divider ,
   Select ,
   Option ,
   InputNumber,
   Collapse,
   CollapseItem ,
   Descriptions,
   DescriptionsItem,
   Empty,
   Steps,
   Step,
   Result,
   Loading ,
   Timeline,
   TimelineItem,
   Image,
   Alert,
   Skeleton,
   Autocomplete,
   SkeletonItem,
   Carousel,
   CarouselItem,
   RadioButton
} from 'element-ui'



Vue.prototype.$message=Message;
Vue.prototype.$confirm=MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$alert = Alert;
Vue.prototype.$loading = Loading;


Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Drawer)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tree)
Vue.use(Popconfirm)
Vue.use(Popover)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Cascader)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Transfer)
Vue.use(Divider)
Vue.use(Select)
Vue.use(Option)
Vue.use(InputNumber)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(CheckboxButton)
Vue.use(Empty)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Result)
// Vue.use(Loading.directive)
Vue.use(Loading)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Image)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Autocomplete)
Vue.use(RadioButton)
Vue.use(Carousel)
Vue.use(CarouselItem)











































