<template>
  <div class="pb20" id="rootchart" :style="cssVars">
    <div class="chooseArea">
      <span>
        <el-button
          v-for="(item, index) in dButtons1"
          :key="index"
          @click="fnButtons1(item)"
          :class="item == dButtons1_current ? 'checkedBtnStyle' : ''"
          class="noActive"
          size="mini"
        >
          {{ item }}
        </el-button>
      </span>
    </div>

    <div class="main CTABLE" v-show="bOverview">
      <div class="title">
        成交额和换手率概览

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads1"
            @fnButtondownload="fnButtonDL4_1"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel4_1"
              :data="dMvSnapshotTable"
              :fields="json_fields4_1"
              header="成交额和换手率概览"
              name="成交额和换手率概览.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <download-excel
          id="downloadExcel1"
          :data="dMvSnapshotTable"
          :fields="json_fields"
          :header="title"
          name="成交额和换手率概览.xls"
        >
          <i class="el-icon-download"></i>
        </download-excel> -->
      </div>

      <div>
        <div class="content_choose_area">
          <CCascader
            :default="category_cascader_val_table1"
            :checkStrictly="true"
            :collapsetags="true"
            @CCascader_select_val="fnCCascader_select_val_table1"
            :options="category_cascader_options"
            >选择类别</CCascader
          >

          <span class="summary_style">
            汇总
            <el-checkbox
              @change="fnGetMvSnapshotTable"
              v-model="Checked_table1"
            ></el-checkbox>
          </span>
        </div>
        <!-- :height="OFFSETHEIGHT - 390" -->

        <!-- :height="OFFSETHEIGHT - 362" -->

        <div class="content_area">
          <el-table
            class="table1"
            :header-cell-style="styleBindFun"
            :cell-style="fnCell"
            @cell-click="fnClick"
            :height="OFFSETHEIGHT - 362"
            :data="dMvSnapshotTable"
            style="width: 100%"
          >
            <el-table-column
              prop="代码"
              label="代码"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="名称"
              label="名称"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="大类"
              align="center"
              width="100"
              label="大类"
            >
            </el-table-column>
            <el-table-column
              prop="类型"
              align="center"
              width="200"
              label="类型"
            >
            </el-table-column>

            <el-table-column
              align="left"
              label="总市值（亿元）"
              sortable
              prop="总市值(亿元)"
            >
              <template slot-scope="scope">
                <!-- style="width: 100%; height: 371px" -->
                <div class="value_area">
                  <div :style="{ width: scope.row.node_ZSZ }" class="a"></div>
                  {{ scope.row["总市值(亿元)"].toFixed(1) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="left"
              label="流通市值（亿元）"
              sortable
              prop="自由流通市值（亿元）"
            >
              <template slot-scope="scope">
                <div class="value_area">
                  <div
                    :style="{ width: scope.row.node_ZYLTSZ }"
                    class="b"
                  ></div>
                  {{ scope.row["自由流通市值（亿元）"].toFixed(1) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="left"
              label="日成交额（万元）"
              sortable
              prop="日成交额（万元）"
            >
              <template slot-scope="scope">
                <div class="value_area">
                  <div :style="{ width: scope.row.node_RCJE }" class="c"></div>
                  <!-- {{ Number(scope.row["日成交额（万元）"].toLocaleString()).toFixed(0) }} -->
                  {{
                    Number(
                      scope.row["日成交额（万元）"].toFixed(0)
                    ).toLocaleString()
                  }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="left"
              prop="换手率(%)"
              label="换手率（%）"
              sortable
            >
              <template slot-scope="scope">
                <div class="value_area">
                  <div :style="{ width: scope.row.node_HSL }" class="d"></div>
                  {{ Number(scope.row["换手率(%)"]).toFixed(2) }}
                  <!-- {{ (Number(scope.row["换手率(%)"]) * 100).toFixed(0) + "%" }} -->
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="产品" align="center" width="390">
                  <template slot-scope="scope">
                                    {{scope.row.product_name}} <span v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                                </template>                
                  </el-table-column> -->
          </el-table>
        </div>

        <div ref="content_table" class="content_area downloadTable">
          <el-table
            class="table1"
            :header-cell-style="styleBindFun"
            :cell-style="fnCell"
            @cell-click="fnClick"
            :data="dMvSnapshotTable"
            style="width: 100%"
          >
            <el-table-column
              prop="代码"
              label="代码"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="名称"
              label="名称"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="大类"
              align="center"
              width="100"
              label="大类"
            >
            </el-table-column>
            <el-table-column
              prop="类型"
              align="center"
              width="200"
              label="类型"
            >
            </el-table-column>

            <el-table-column
              align="left"
              label="总市值（亿元）"
              sortable
              prop="总市值(亿元)"
            >
              <template slot-scope="scope">
                <!-- style="width: 100%; height: 371px" -->
                <div class="value_area">
                  <div :style="{ width: scope.row.node_ZSZ }" class="a"></div>
                  {{ scope.row["总市值(亿元)"].toFixed(1) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="left"
              label="流通市值（亿元）"
              sortable
              prop="自由流通市值（亿元）"
            >
              <template slot-scope="scope">
                <div class="value_area">
                  <div
                    :style="{ width: scope.row.node_ZYLTSZ }"
                    class="b"
                  ></div>
                  {{ scope.row["自由流通市值（亿元）"].toFixed(1) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="left"
              label="日成交额（万元）"
              sortable
              prop="日成交额（万元）"
            >
              <template slot-scope="scope">
                <div class="value_area">
                  <div :style="{ width: scope.row.node_RCJE }" class="c"></div>
                  <!-- {{ Number(scope.row["日成交额（万元）"].toLocaleString()).toFixed(0) }} -->
                  {{
                    Number(
                      scope.row["日成交额（万元）"].toFixed(0)
                    ).toLocaleString()
                  }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="left"
              prop="换手率(%)"
              label="换手率（%）"
              sortable
            >
              <template slot-scope="scope">
                <div class="value_area">
                  <div :style="{ width: scope.row.node_HSL }" class="d"></div>
                  {{ Number(scope.row["换手率(%)"]).toFixed(2) }}
                  <!-- {{ (Number(scope.row["换手率(%)"]) * 100).toFixed(0) + "%" }} -->
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column label="产品" align="center" width="390">
                  <template slot-scope="scope">
                                    {{scope.row.product_name}} <span v-show="scope.row.spec">（{{scope.row.spec}}）</span>
                                </template>                
                  </el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>

    <div class="main" v-show="bMarketValue">
      <div class="title">
        公募REITs市值及流通市值总额构成

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads2"
            @fnButtondownload="fnButtonDL4_2"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel4_2"
              :data="json_fields4_2_data"
              :fields="json_fields4_2"
              header="公募REITs市值及流通市值总额构成"
              name="公募REITs市值及流通市值总额构成.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose">
        <span>
          颜色分类
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_color"
            :dButtons1="dButtons_separrate_color"
            :dButtons1_current="dButtons_separrate_current_color"
          />
        </span>

        <span v-if="dButtons_separrate_current_color == '大类'">
          <CCascader
            ref="CCascader_chart1"
            :default="category_cascader_val"
            :checkStrictly="true"
            :multiple="true"
            :collapsetags="true"
            @CCascader_select_val="fnCCascader_select_val_one"
            :options="category_cascader_options_LONE"
            >选择类别</CCascader
          >
        </span>

        <span v-else>
          <CCascader
            ref="CCascader_chart1"
            :default="category_cascader_val"
            :checkStrictly="true"
            takeOut="takeOut2"
            :multiple="true"
            :collapsetags="true"
            @CCascader_select_val="fnCCascader_select_val_one"
            :options="category_cascader_options"
            >选择类别</CCascader
          >
        </span>

        <span>
          指标类型
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_target"
            :dButtons1="dButtons_separrate_target"
            :dButtons1_current="dButtons_separrate_current_target"
          />
        </span>

        <span>
          汇总
          <el-checkbox
            @change="fnGetMvArea"
            v-model="Checked_one"
          ></el-checkbox>
        </span>
      </div>

      <div class="main_choose main_choose_center">
        <span>
          <CTimeButtons
            :default="start_date_chart1"
            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart1"
          />
        </span>
      </div>

      <div class="content">
        <span
          @click="fnOpenDialog2"
          class="iconfont icon-fangda icon-fangda2"
        ></span>

        <div
          class="canvasArea1"
          ref="chartColumn1"
          style="width: 100%; height: 404px"
        ></div>
        <div class="deadline deadline_style">数据截止日期：{{ mvAreaDDL }}</div>
      </div>
    </div>

    <div class="main roseCard" v-show="bMarketValue">
      <div class="title">
        公募REITs市值及流通市值
        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads2"
            @fnButtondownload="fnButtonDL4_3"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel4_3"
              :data="json_fields4_3_data"
              :fields="json_fields4_3"
              header="公募REITs市值及流通市值"
              name="公募REITs市值及流通市值.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload2()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose">
        <span>
          分类类型
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_category_chart6"
            :dButtons1="dButtons_separrate_category"
            :dButtons1_current="dButtons_separrate_current_category_chart6"
          />
        </span>

        <span>
          指标类型
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_chart6"
            :dButtons1="dButtons_separrate_target"
            :dButtons1_current="dButtons_separrate_current_chart6"
          />
        </span>

        <span>
          <CTimeButtons
            :default="start_date_chart2"
            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart2"
          />
        </span>
      </div>

      <div ref="content2">
        <div class="content_choose_area">
          <div class="content_choose_area_item content">
            <span
              @click="fnOpenDialog3"
              class="iconfont icon-fangda icon-fangda2"
            ></span>
            <div ref="chartColumn6" style="width: 100%; height: 550px"></div>
            <div class="deadline deadline_style deadline2">
              数据截止日期：{{ mvAreaDDL }}
            </div>

            <!-- <div class="deadline deadline_style">
            数据截止日期：{{ dMvAreaData_X[dMvAreaData_X.length - 1] }}
          </div> -->
          </div>
          <div class="content_choose_area_item content">
            <span
              @click="fnOpenDialog4"
              class="iconfont icon-fangda icon-fangda2"
            ></span>
            <div ref="chartColumn7" style="width: 100%; height: 550px"></div>
            <div
              class="color_pier"
              v-show="
                this.dButtons_separrate_current_category_chart6 == '单支REITs'
              "
            >
              <div class="color_pier_tit">单个REITs市值范围</div>
              <div class="color_pier_main"></div>
            </div>
            <!-- <div class="deadline deadline_style">
            数据截止日期：{{ dMvAreaData_X[dMvAreaData_X.length - 1] }}
          </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="main" v-show="bMobility">
      <div class="title">
        换手及成交额

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads3"
            @fnButtondownload="fnButtonDL4_4"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel4_4"
              :data="json_fields4_4_data"
              :fields="json_fields4_4"
              header="换手及成交额"
              name="换手及成交额.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload3()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose">
        <span>
          <CSelect
            @CSelect_select_val="fnCSelect_chart4"
            :multiple="false"
            :clearable="false"
            :auto="true"
            :default="select_val_target"
            :options="options_target"
            >选择对比指标
          </CSelect>
        </span>

        <span>
          显示平均线
          <el-checkbox
            @change="fnGetDailyAmtTurnover"
            v-model="Checked_two"
          ></el-checkbox>
        </span>

        <span class="CTimeButtons">
          <CTimeButtons
            :spc1="true"
            :FD="true"
            ref="chart5Time"
            :default="start_date_chart4"
            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart4"
          />
        </span>
      </div>

      <!-- <div class="main_choose main_choose_center">
       

      </div> -->

      <!-- <div class="main_choose">
        <span>
          颜色分类
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_color2"
            :dButtons1="dButtons_separrate_color"
            :dButtons1_current="dButtons_separrate_current_color"
          />
        </span>
      </div> -->

      <!-- element-loading-background="rgba(0, 0, 0, 0.8)" -->
      <div
        v-loading="loading"
        element-loading-text="数据量巨大，正在计算中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(1, 1, 1, 0.8)"
        class="content"
      >
        <span
          @click="fnOpenDialog"
          class="iconfont icon-fangda icon-fangd3"
        ></span>
        <div
          class="canvasArea1"
          ref="chartColumn5"
          style="width: 100%; height: 450px"
        ></div>

        <!-- <div
          class="canvasArea1"
          ref="chartColumn5_plotly"
          style="width: 100%; height: 450px"
        ></div> -->

        <div class="deadline deadline_style">
          数据截止日期：{{ amtTurnoverDDL }}
        </div>
      </div>
    </div>

    <div class="main" v-show="bMobility">
      <div class="title">
        各部分成交额汇总对比

        <div class="downloadBtns">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads3"
            @fnButtondownload="fnButtonDL4_5"
          />

          <span style="display: none">
            <download-excel
              id="downloadExcel4_5"
              :data="json_fields4_5_data"
              :fields="json_fields4_5"
              header="各部分成交额汇总对比"
              name="各部分成交额汇总对比.xls"
            >
            </download-excel>
          </span>
        </div>

        <!-- <span @click="FnDownload4()">
          <i class="el-icon-download"></i>
        </span> -->
      </div>

      <div class="main_choose">
        <span>
          颜色分类
          <CButtonsSeparate
            @Cbtns_sep="fnGetCbtnsSep_color5"
            :dButtons1="dButtons_separrate_color"
            :dButtons1_current="dButtons_separrate_current_color_five"
          />
        </span>

        <span v-if="dButtons_separrate_current_color_five == '大类'">
          <CCascader
            ref="CCascader_chart2"
            :default="category_cascader_val_five"
            :checkStrictly="true"
            :multiple="true"
            :collapsetags="true"
            :isDelete="false"
            @CCascader_select_val="fnCCascader_select_val_five"
            :options="category_cascader_options_LONE"
            >选择类别</CCascader
          >
        </span>

        <span v-else>
          <CCascader
            ref="CCascader_chart2"
            :default="category_cascader_val_five"
            :checkStrictly="true"
            :multiple="true"
            takeOut="takeOut2"
            :collapsetags="true"
            :isDelete="false"
            @CCascader_select_val="fnCCascader_select_val_five"
            :options="category_cascader_options"
            >选择类别</CCascader
          >
        </span>

        <span>
          汇总
          <el-checkbox
            @change="fnGetAmtArea"
            v-model="Checked_five"
          ></el-checkbox>
        </span>
      </div>

      <div class="main_choose main_choose_center">
        <span>
          <CTimeButtons
            :default="start_date_chart5"
            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart5"
          />
        </span>
      </div>

      <div class="content">
        <span
          @click="fnOpenDialog5"
          class="iconfont icon-fangda icon-fangda2"
        ></span>
        <div
          class="canvasArea1"
          ref="chartColumn4"
          style="width: 100%; height: 450px"
        ></div>

        <div class="deadline_style deadline">
          数据截止日期：{{ amtAreaDDL }}
        </div>
      </div>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div class="huanshou">
          <div class="elDropDown">
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown2"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              class="canvasArea1"
              ref="chartColumn1_dialog"
              style="width: 100%; height: 95%"
            ></div>
            <div class="deadline deadline_style">
              数据截止日期：{{ mvAreaDDL }}
            </div>
          </div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible3"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible3"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown3"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            ref="chartColumn6_dialog"
            style="width: 100%; height: 100%"
          ></div>
          <div class="deadline deadline_style deadline2">
            数据截止日期：{{ mvAreaDDL }}
          </div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible4"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible4"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown4"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            ref="chartColumn7_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible5"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible5"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown5"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            class="canvasArea1"
            ref="chartColumn4_dialog"
            style="width: 100%; height: 95%"
          ></div>

          <div class="deadline_style deadline">
            数据截止日期：{{ amtAreaDDL }}
          </div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div ref="chartColumn5_dialog" style="width: 100%; height: 95%"></div>

          <div class="deadline deadline_style">
            数据截止日期：{{ amtTurnoverDDL }}
          </div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelects from "@/components/Basic/CSelects";
import CSelect from "@/components/Basic/CSelect";
import CCategoryButton from "@/components/Basic/CCategoryButton";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";

import CTimeButtons from "@/components/Basic/CTimeButtons";
import CCascader from "@/components/Basic/CCascader";

import html2canvas from "html2canvas";

import CDialog from "@/components/Basic/CDialog";

import {
  fnComputeTime,
  fnReturnTableStyle,
  fnGetMuchDecimals,
  fnDownloadBgc,
} from "@/utils/util";

import case1JSON from "@/assets/json/case1.json";

export default {
  data() {
    return {
      tooltipWidth: 380,
      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],
      activeBtn1: "3M",

      lCategory_value: "",
      lCategory_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      category_value: "",
      category_options: [
        {
          name: "下拉菜单",
          value: "0",
        },
      ],

      colorCate: ["大类", "细分"],
      targetCate: ["总市值", "流通市值"],
      category: ["资产大类", "细分资产", "TBD"],

      summaryChecked: "",

      // 从头开始第一个---------------------------------
      bOverview: true,
      bMarketValue: false,
      bMobility: false,
      OFFSETHEIGHT: 0,

      start_date_chart1: "YTD",
      category_cascader_val: ["公募REITs"],
      category_cascader_options: JSON.parse(
        localStorage.getItem("FINALLYCATEGORY")
      ),
      category_cascader_options_LONE: JSON.parse(
        localStorage.getItem("FINALLYCATEGORYL1")
      ),
      category_cascader_options_L1: [
        {
          value: "产权类",
          label: "产权类",
        },
        {
          value: "经营权类",
          label: "经营权类",
        },
        {
          value: "公募REITs",
          label: "公募REITs",
        },
      ],
      dButtons_separrate_color: ["大类", "细分"],
      dButtons_separrate_current_color: "大类",

      dButtons_separrate_target: ["市值", "流通市值"],
      dButtons_separrate_current_target: "流通市值",

      Checked_one: false,

      dMvSnapshotTable: [],
      dButtons1: ["概览", "市值相关", "流动性相关"],
      dButtons1_current: "概览",

      start_date_chart2: "YTD",
      dButtons_separrate_current_chart6: "市值",
      dButtons_separrate_category: ["大类", "细分", "单支REITs"],
      dButtons_separrate_current_category_chart6: "单支REITs",

      start_date_chart4: "YTD",
      select_val_target: "成交额",
      options_target: [
        {
          label: "成交额",
          value: "成交额",
        },
        {
          label: "换手率",
          value: "换手率",
        },
      ],
      Checked_two: true,
      dButtons_separrate_current_color_four: "大类",

      start_date_chart5: "3M",
      category_cascader_val_five: ["公募REITs"],
      dButtons_separrate_current_color_five: "大类",
      Checked_five: false,

      category_cascader_val_table1: ["公募REITs"],
      Checked_table1: false,

      chartColumn1: null,
      chartColumn1_dialog: null,
      chart1_summarize: [],
      chart1_cap_type: [],

      dMvAreaData: [],
      dMvAreaData_X: [],
      dMvAreaData_CQ: [],
      dMvAreaData_HZ: [],
      dMvAreaData_JY: [],
      mvAreaDDL: "",
      bHZ: false,
      category_cascader_options2: this.$store.state.category_cascader_options,

      chartColumn4: null,
      chartColumn4_dialog: null,

      chart4_data: [],
      dAmtAreaData: [],
      dAmtAreaData_X: [],
      dAmtAreaData_CYY: [],
      dAmtAreaData_BZXZF: [],
      dAmtAreaData_SZST: [],
      dAmtAreaData_SFGL: [],
      dAmtAreaData_HZ: [],
      dAmtAreaData_WLCC: [],
      dAmtAreaData_NY: [],
      amtAreaDDL: "",

      dDailyAmtTurnover_avg: [],
      dDailyAmtTurnover_X: [],
      dDailyAmtTurnover_ZXT: [],

      dMvRose: [],
      dMvRose_X: [],
      dMvRose_Y: [],

      nestedPies_L1: [],
      nestedPies_L2: [],
      nestedPies_L3: [],

      chartColumn5: null,
      chartColumn5_dialog: null,
      chartColumn5_plotly: null,

      chartColumn5_DATA: [],
      chartColumn5_AVG: [],

      chartColumn6: null,
      chartColumn6_dialog: null,

      chartColumn7: null,
      chartColumn7_dialog: null,

      loading: false,

      valueqqqqqq: "30px",

      amtTurnoverDDL: "",

      // 表格下载
      title: "成交额和换手率概览",
      json_fields4_1: {
        代码: "代码",
        名称: "名称",
        大类: "大类",
        类型: "类型",
        "总市值(亿元)": "总市值(亿元)",
        "自由流通市值（亿元）": "自由流通市值（亿元）",
        "日成交额（万元）": "日成交额（万元）",
        "换手率(%)": "换手率(%)",
      },
      json_fields4_2: {},
      json_fields4_2_data: [],
      json_fields4_3: {},
      json_fields4_3_data: [],

      json_fields4_4: {},
      json_fields4_4_data: [],

      json_fields4_5: {},
      json_fields4_5_data: [],

      dButtonsDownloads1: [
        {
          id: "241009",
          name: "下载图片",
        },
        {
          id: "241009",
          name: "下载表格",
        },
      ],

      dButtonsDownloads2: [
        {
          id: "241011",
          name: "下载图片",
        },
        {
          id: "241012",
          name: "下载表格",
        },
      ],

      dButtonsDownloads3: [
        {
          id: "241086",
          name: "下载图片",
        },
        {
          id: "241087",
          name: "下载表格",
        },
      ],

      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,

      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      height_chart2: "60vh",

      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      initChart67dataTmp: null,

      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,
    };
  },
  created() {
    // this.fnGeRiskAnalysisData()
    // this.fnButtons1("概览");
    this.fnGetL1();
  },
  // beforeDestroy() {
  //   this.fnDispose();
  // },
  mounted() {
    window.addEventListener("resize", this.funResize);
    this.fnGetWidth();
  },
   beforeDestroy(){
     window.removeEventListener("resize", this.funResize);
     this.chartColumn1&&this.chartColumn1.dispose()
    this.chartColumn4&&this.chartColumn4.dispose()
    this.chartColumn6&&this.chartColumn6.dispose()
    this.chartColumn7&&this.chartColumn7.dispose()
    this.chartColumn5&&this.chartColumn5.dispose()
  },
  methods: {
    fnDispose() {
      if (this.chartColumn5) {
        this.chartColumn5.dispose();
      }
    },

    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1440 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      // this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      this.OFFSETHEIGHT = OFFSETHEIGHT;
      // this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144) + "px";

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144) + "px";

      // var chartColumn4 = document.getElementById("chartColumn4");
      // chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn5 = document.getElementById("chartColumn5");
      // chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn3 = document.getElementById("chartColumn3");
      // chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144) + "px";

      return;
    },

    // 下载 start
    fnButtonDL4_1(val) {
      if (val == "下载图片") {
        this.FnDownload5();
      } else {
        document.getElementById("downloadExcel4_1").click();
      }
    },

    fnButtonDL4_2(val) {
      if (val == "下载图片") {
        this.FnDownload();
      } else {
        document.getElementById("downloadExcel4_2").click();
      }
    },

    fnButtonDL4_3(val) {
      if (val == "下载图片") {
        this.FnDownload2();
      } else {
        document.getElementById("downloadExcel4_3").click();
      }
    },

    fnButtonDL4_4(val) {
      if (val == "下载图片") {
        this.FnDownload3();
      } else {
        document.getElementById("downloadExcel4_4").click();
      }
    },

    fnButtonDL4_5(val) {
      if (val == "下载图片") {
        this.FnDownload4();
      } else {
        document.getElementById("downloadExcel4_5").click();
      }
    },

    // 下载 end

    // 下载
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },
    FnDownload() {
      let h = this.$refs.chartColumn1.scrollHeight;
      let w = this.$refs.chartColumn1.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn1, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "公募REITs市值及流通市值总额构成"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload2() {
      let h = this.$refs.content2.scrollHeight;
      let w = this.$refs.content2.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.content2, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "公募REITs市值及流通市值"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload3() {
      let h = this.$refs.chartColumn5.scrollHeight;
      let w = this.$refs.chartColumn5.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn5, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "成交额及换手率对比"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload4() {
      let h = this.$refs.chartColumn4.scrollHeight;
      let w = this.$refs.chartColumn4.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn4, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "各部分成交额汇总对比"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload5() {
      let h = this.$refs.content_table.scrollHeight;
      let w = this.$refs.content_table.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.content_table, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "成交额和换手率概览"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    funResize() {
      this.fnGetWidth();

      if (this.chartColumn1) {
        this.$nextTick(() => {
          this.chartColumn1.resize();
        });
      }

      if (this.chartColumn2) {
        this.$nextTick(() => {
          this.chartColumn2.resize();
        });
      }

      if (this.chartColumn3) {
        this.$nextTick(() => {
          this.chartColumn3.resize();
        });
      }

      if (this.chartColumn4) {
        this.$nextTick(() => {
          this.chartColumn4.resize();
        });
      }

      if (this.chartColumn5) {
        this.$nextTick(() => {
          this.chartColumn5.resize();
        });
      }

      if (this.chartColumn6) {
        this.$nextTick(() => {
          this.chartColumn6.resize();
        });
      }

      if (this.chartColumn7) {
        this.$nextTick(() => {
          this.chartColumn7.resize();
        });
      }
    },

    // 获取L1
    fnGetL1() {
      //      let L1NAME = JSON.parse(localStorage.getItem('L1NAME'))
      //      let a = L1NAME.shift();
      //      L1NAME.push(a)
      //      this.category_cascader_options = L1NAME.map(node=>{
      //       return {
      //         label:node,
      //         value:node,
      //       }
      //      })
    },

    // ===================================
    fnChangeBtn1(val) {
      this.activeBtn1 = val;
    },

    //  放大 start
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart5(true);
      });
      // this.fnGetDailyAmtTurnover();
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;
      // this.height_chart = "68vh";
      this.$nextTick(() => {
        this.initChart1(true);
      });
    },
    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        if (
          this.dButtons_separrate_current_category_chart6 == "大类" ||
          this.dButtons_separrate_current_category_chart6 == "细分"
        ) {
          this.initChart6_1(true);
        } else {
          this.initChart6(true);
        }
      });
    },
    fnOpenDialog4() {
      this.dialogVisible4 = true;

      this.$nextTick(() => {
        if (this.dButtons_separrate_current_category_chart6 == "单支REITs") {
          this.initChart7(true);
        } else {
          this.initChart7_1(true);
        }
      });
    },

    fnOpenDialog5() {
      this.dialogVisible5 = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });
    },

    //  放大 end

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart5(true);
        });
      }
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;

        this.$nextTick(() => {
          this.initChart1(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart1(true);
        });
      }
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          if (
            this.dButtons_separrate_current_category_chart6 == "大类" ||
            this.dButtons_separrate_current_category_chart6 == "细分"
          ) {
            this.initChart6_1(true);
          } else {
            this.initChart6(true);
          }
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          if (
            this.dButtons_separrate_current_category_chart6 == "大类" ||
            this.dButtons_separrate_current_category_chart6 == "细分"
          ) {
            this.initChart6_1(true);
          } else {
            this.initChart6(true);
          }
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          if (
            this.dButtons_separrate_current_category_chart6 == "大类" ||
            this.dButtons_separrate_current_category_chart6 == "细分"
          ) {
            this.initChart6_1(true);
          } else {
            this.initChart6(true);
          }
        });
      }
    },

    fnDropdown4(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          if (this.dButtons_separrate_current_category_chart6 == "单支REITs") {
            this.initChart7(true);
          } else {
            this.initChart7_1(true);
          }
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          if (this.dButtons_separrate_current_category_chart6 == "单支REITs") {
            this.initChart7(true);
          } else {
            this.initChart7_1(true);
          }
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          if (this.dButtons_separrate_current_category_chart6 == "单支REITs") {
            this.initChart7(true);
          } else {
            this.initChart7_1(true);
          }
        });
      }
    },

    fnDropdown5(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      }
    },

    // 从头开始第一个---------------------------------
    // 0. 绑定表格头部样式方法
    styleBindFun() {
      let val = this.$store.state.theme == "dark" ? true : false;
      return fnReturnTableStyle(val);

      // let styleBind =
      //   "background:#343742;fontSize:16px;color:#dadada;fontWeight: 580; border-bottom: 3px solid #4c4d5d; border-top: 2px solid #4c4d5f;";

      // let styleBind_light =
      //   "background:#f7f7f7;fontSize:16px;color:#707070;fontWeight: 580; border-bottom: 3px solid #bfa3a3; border-top: 2px solid #bda0a0;";

      // return this.$store.state.theme == "dark" ? styleBind : styleBind_light;
    },

    // 1. 获取表格数据
    fnCCascader_select_val_table1(val) {
      this.category_cascader_val_table1 = val;
      this.Checked_table1 = false;
      this.fnGetMvSnapshotTable();
    },
    async fnGetMvSnapshotTable() {
      let r_type = this.category_cascader_val_table1;
      // let str = r_type.join("&r_type=");

      let summarize = this.Checked_table1 ? 1 : 0;

      const { data } = await this.$https.get(
        "/api/mv_snapshot_table?r_type=" + r_type + "&summarize=" + summarize
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // // 排序

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = data.data.filter((item) => item["类型"] == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      let obj2 = data.data.filter((item) => item["类型"] == "");
      if (obj2.length > 0) {
        obj2.forEach((item) => {
          TMP.push(item);
        });
      }
      this.dMvSnapshotTable = TMP;
      // this.dMvSnapshotTable = data.data;
      if (summarize && r_type == "公募REITs") {
        // 1. 找出一类，总体
        let arr1 = this.dMvSnapshotTable.filter((node) => {
          return node["代码"] == "一类汇总" || node["代码"] == "总体汇总";
        });

        arr1.forEach((node) => {
          this.dMvSnapshotTable.splice(this.dMvSnapshotTable.indexOf(node), 1);
        });

        // 2. 插入到数组合适位置
        let idx = this.dMvSnapshotTable.findIndex((node) => {
          return node["大类"] == "经营权类";
        });

        let idx2 = null;
        let idx3 = null;

        arr1.forEach((node, index) => {
          if (node["大类"] == "产权类") {
            this.dMvSnapshotTable.splice(idx, 0, node);
          }

          if (node["大类"] == "经营权类") {
            idx2 = index;
          }

          if (node["大类"] == "总体汇总") {
            idx3 = index;
          }
        });

        this.dMvSnapshotTable.push(arr1[idx2]);
        this.dMvSnapshotTable.push(arr1[idx3]);
      }

      if (summarize && r_type !== "公募REITs") {
        let arr1 = this.dMvSnapshotTable.filter((node) => {
          return node["代码"] == "一类汇总" || node["代码"] == "总体汇总";
        });

        // 先删除一类汇总
        arr1.forEach((node) => {
          this.dMvSnapshotTable.splice(this.dMvSnapshotTable.indexOf(node), 1);
        });

        arr1.forEach((node) => {
          this.dMvSnapshotTable.push(node);
        });
      }

      // --------------------------------------------------

      // 计算矩形宽度  start

      let CATEGORY = this.dMvSnapshotTable.map((node) => {
        return node["类型"];
      });

      CATEGORY = [...new Set(CATEGORY)];

      let DATA = [];
      CATEGORY.forEach((e) => {
        let obj = {
          name: e,
          max_ZSZ: 0,
          max_RJCE: 0,
          max_ZYLTSZ: 0,
          max_HSL: 0,
        };

        let data = this.dMvSnapshotTable.filter((item) => {
          if (!item["代码"].includes("汇总")) {
            return item;
          }
        });

        let data2 = data.map((node) => {
          return node["总市值(亿元)"];
        });

        let data3 = data.map((node) => {
          return node["日成交额（万元）"];
        });

        let data4 = data.map((node) => {
          return node["自由流通市值（亿元）"];
        });

        // 换手率整体长短
        let data5 = this.dMvSnapshotTable.map((node) => {
          return node["换手率(%)"];
        });

        // 二类汇总 start
        let second = this.dMvSnapshotTable.filter((node) => {
          return node["代码"] == "二类汇总";
        });

        let second_data2 = second.map((node) => {
          return node["总市值(亿元)"];
        });

        let second_data3 = second.map((node) => {
          return node["日成交额（万元）"];
        });

        let second_data4 = second.map((node) => {
          return node["自由流通市值（亿元）"];
        });

        // 二类汇总 end

        // 一类汇总 start
        let first = this.dMvSnapshotTable.filter((node) => {
          return node["代码"] == "一类汇总";
        });

        let first_data2 = first.map((node) => {
          return node["总市值(亿元)"];
        });

        let first_data3 = first.map((node) => {
          return node["日成交额（万元）"];
        });

        let first_data4 = first.map((node) => {
          return node["自由流通市值（亿元）"];
        });

        // 一类汇总 end

        obj.max_ZSZ = Math.max(...data2);
        obj.max_RJCE = Math.max(...data3);
        obj.max_ZYLTSZ = Math.max(...data4);
        obj.max_HSL = Math.max(...data5);

        if (second.length !== 0) {
          obj.max_second_ZSZ = Math.max(...second_data2);
          obj.max_second_RJCE = Math.max(...second_data3);
          obj.max_second_ZYLTSZ = Math.max(...second_data4);
        }

        if (first.length !== 0) {
          obj.max_first_ZSZ = Math.max(...first_data2);
          obj.max_first_RJCE = Math.max(...first_data3);
          obj.max_first_ZYLTSZ = Math.max(...first_data4);
        }

        DATA.push(obj);
      });

      this.dMvSnapshotTable = this.dMvSnapshotTable.filter((node) => {
        return node !== undefined;
      });

      DATA.forEach((node) => {
        this.dMvSnapshotTable.map((e) => {
          if (node.name == e["类型"]) {
            let node_ZSZ = "";
            let val = Number(e["总市值(亿元)"]) / node.max_ZSZ;
            val = (Number(val) * 100).toFixed(0);
            node_ZSZ = val + "px";
            e.node_ZSZ = node_ZSZ;

            let node_RCJE = "";
            let val2 = Number(e["日成交额（万元）"]) / Number(node.max_RJCE);
            val2 = (Number(val2) * 100).toFixed(0);
            node_RCJE = val2 + "px";
            e.node_RCJE = node_RCJE;

            let node_ZYLTSZ = "";
            let val3 = Number(e["自由流通市值（亿元）"]) / node.max_ZYLTSZ;
            val3 = (Number(val3) * 100).toFixed(0);
            node_ZYLTSZ = val3 + "px";
            e.node_ZYLTSZ = node_ZYLTSZ;

            let node_HSL = "";
            let val4 = Number(e["换手率(%)"]) / node.max_HSL;
            val4 = (Number(val4) * 100).toFixed(0);
            node_HSL = val4 + "px";
            e.node_HSL = node_HSL;

            // 二类汇总
            if (e["代码"] == "二类汇总") {
              let node_ZSZ = "";
              let val = Number(e["总市值(亿元)"]) / node.max_second_ZSZ;
              val = (Number(val) * 100).toFixed(0);
              node_ZSZ = val + "px";
              e.node_ZSZ = node_ZSZ;

              let node_RCJE = "";
              let val2 =
                Number(e["日成交额（万元）"]) / Number(node.max_second_RJCE);
              val2 = (Number(val2) * 100).toFixed(0);
              node_RCJE = val2 + "px";
              e.node_RCJE = node_RCJE;

              let node_ZYLTSZ = "";
              let val3 =
                Number(e["自由流通市值（亿元）"]) / node.max_second_ZYLTSZ;
              val3 = (Number(val3) * 100).toFixed(0);
              node_ZYLTSZ = val3 + "px";
              e.node_ZYLTSZ = node_ZYLTSZ;
            }

            // 一类汇总
            if (e["代码"] == "一类汇总") {
              let node_ZSZ = "";
              let val = Number(e["总市值(亿元)"]) / node.max_first_ZSZ;
              val = (Number(val) * 100).toFixed(0);
              node_ZSZ = val + "px";
              e.node_ZSZ = node_ZSZ;

              let node_RCJE = "";
              let val2 =
                Number(e["日成交额（万元）"]) / Number(node.max_first_RJCE);
              val2 = (Number(val2) * 100).toFixed(0);
              node_RCJE = val2 + "px";
              e.node_RCJE = node_RCJE;

              let node_ZYLTSZ = "";
              let val3 =
                Number(e["自由流通市值（亿元）"]) / node.max_first_ZYLTSZ;
              val3 = (Number(val3) * 100).toFixed(0);
              node_ZYLTSZ = val3 + "px";
              e.node_ZYLTSZ = node_ZYLTSZ;
            }

            if (e["代码"] == "总体汇总") {
              e.node_ZSZ = "100px";
              e.node_RCJE = "100px";
              e.node_ZYLTSZ = "100px";
            }
          }
        });
      });

      // 计算矩形宽度  end

      // 一类汇总 插入到指定位置 start
      if (!summarize) {
        this.fnSortRICHENGJIAOE();
      }

      if (!summarize) return;

      let DALEI = this.dMvSnapshotTable.map((node) => {
        return node["大类"];
      });

      DALEI = [...new Set(DALEI)];

      let YILEIARR = this.dMvSnapshotTable.filter((node) => {
        return node["代码"] == "一类汇总" && node["大类"] == DALEI[0];
      });

      if (YILEIARR.length > 0) {
        this.dMvSnapshotTable = this.dMvSnapshotTable.filter((node) => {
          return node !== YILEIARR[0];
        });

        let idx = this.dMvSnapshotTable.findIndex((node) => {
          let val = DALEI.length > 0 ? "经营权类" : DALEI[0];
          return node["大类"] == val;
          // return node["大类"] == DALEI[1];
        });

        if (idx <= 0) {
          this.dMvSnapshotTable.push(YILEIARR[0]);
        } else {
          this.dMvSnapshotTable.splice(idx, 0, YILEIARR[0]);
        }
      }

      // 一类汇总 插入到指定位置 end

      // ok!!!

      this.fnSortRICHENGJIAOE();
    },

    // 日成交额 排序
    fnSortRICHENGJIAOE() {
      // 日成交额 排序 start
      // 分类
      let arr1_last = [];
      let arr2_last = [];

      arr1_last = this.dMvSnapshotTable.filter((node) => {
        return node["代码"] !== "二类汇总" && node["代码"] !== "一类汇总";
      });

      arr2_last = this.dMvSnapshotTable.filter((node) => {
        return node["代码"] == "二类汇总" || node["代码"] == "一类汇总";
      });

      let type = arr1_last.map((node) => {
        return node["类型"];
      });

      type = [...new Set(type)];
      let tmp = [];
      type.forEach((node) => {
        let arr = arr1_last.filter((item) => item["类型"] == node);
        function compare(val1, val2) {
          return val2["日成交额（万元）"] - val1["日成交额（万元）"];
        }
        arr.sort(compare);
        tmp = [...tmp, ...arr];
      });

      let arr2Idx = [];
      arr2_last.forEach((node) => {
        let idx = this.dMvSnapshotTable.findIndex((item) => {
          return item == node;
        });

        tmp.splice(idx, 0, node);
        arr2Idx.push(idx);
      });

      this.dMvSnapshotTable = tmp;

      // 日成交额 排序 end
    },
    // 2. cell
    fnCell({ row, column, rowIndex, columnIndex }) {
      if (row !== undefined && this.$store.state.theme == "dark") {
        if (row.代码 == "二类汇总") {
          return "background: #343742;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
        if (row.代码 == "一类汇总" || row.代码 == "总体汇总") {
          return "background: #4c4f5e;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
      } else {
        if (row.代码 == "二类汇总") {
          return "background: #fbfbfb;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
        if (row.代码 == "一类汇总" || row.代码 == "总体汇总") {
          return "background: #f5f5f5;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
      }
    },

    fnClick(row, column, cell) {
      let arr = [row["名称"], row["代码"]];
      this.$eventBus.$emit("skipPage", arr);
    },

    // 4.fnButtons1
    fnButtons1(val) {
      this.dButtons1_current = val;

      if (this.dButtons1_current == "概览") {
        this.fnDispose();
        this.bOverview = true;
        this.bMarketValue = false;
        this.bMobility = false;
        this.fnGetMvSnapshotTable();
      }

      if (this.dButtons1_current == "市值相关") {
        this.fnDispose();
        this.bOverview = false;
        this.bMarketValue = true;
        this.bMobility = false;
        this.fnGetMvArea();
        this.fnGetMvRose();

        // this.initChart1()
        // this.initChart6()
      }

      if (this.dButtons1_current == "流动性相关") {
        this.bOverview = false;
        this.bMarketValue = false;
        this.bMobility = true;

        this.fnGetAmtArea();
        this.fnGetDailyAmtTurnover();

        // this.initChart5()
        // this.initChart4()
      }

      // this.funResize();
    },

    // 5. chartColumn1 方法
    fnCTimeButtons_select_val_chart1(val) {
      this.start_date_chart1 = val;
      this.fnGetMvArea();
    },
    fnCCascader_select_val_one(val) {
      if (this.dButtons_separrate_current_color == "细分") {
        val = val.filter((node) => {
          return (
            node !== "公募REITs" && node !== "产权类" && node !== "经营权类"
          );
        });
      }
      this.category_cascader_val = val;

      this.fnGetMvArea();
    },
    fnGetCbtnsSep_color(val) {
      this.dButtons_separrate_current_color = val;

      this.fnGetMvArea();
    },
    fnGetCbtnsSep_target(val) {
      this.dButtons_separrate_current_target = val;
      this.fnGetMvArea();
    },

    async fnGetMvArea() {
      let cap_type = this.dButtons_separrate_current_target;
      let color_ = this.dButtons_separrate_current_color;
      let start_date = this.start_date_chart1;

      let r_type = this.category_cascader_val;
      let str = r_type.join("&r_type=");
      let summarize = this.Checked_one ? 1 : 0;

      const { data } = await this.$https.get(
        "/api/mv_area?cap_type=" +
          cap_type +
          "&color_=" +
          color_ +
          "&start_date=" +
          start_date +
          "&r_type=" +
          str +
          "&summarize=" +
          summarize
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dMvAreaData = data.data;

      // 下载 start
      let levelName = window.sessionStorage.getItem("levelName");
      let download = Object.keys(this.dMvAreaData[0]);
      let obj = {};
      download.forEach((node) => {
        if (node == "tr_date") {
          obj["日期"] = "tr_date";
        } else {
          obj[node] = node;
        }
      });
      this.json_fields4_2 = obj;
      //

      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.json_fields4_2_data = this.dMvAreaData.filter((node) => {
          return node["tr_date"] >= result;
        });
      } else {
        let result = fnComputeTime("6M");
        this.json_fields4_2_data = this.dMvAreaData.filter((node) => {
          return node["tr_date"] >= result;
        });
      }

      // 下载 end

      this.dMvAreaData_X = [];
      this.dMvAreaData_CQ = [];
      this.dMvAreaData_HZ = [];
      this.dMvAreaData_JY = [];

      let dataTmp = data.data;

      for (let index = 0; index < dataTmp.length; index++) {
        this.dMvAreaData_X.push(dataTmp[index].tr_date);
        this.dMvAreaData_CQ.push(dataTmp[index].产权类);
        this.dMvAreaData_HZ.push(dataTmp[index].汇总);
        this.dMvAreaData_JY.push(dataTmp[index].经营权类);
      }

      this.mvAreaDDL = this.dMvAreaData_X[this.dMvAreaData_X.length - 1];
      let arr = this.mvAreaDDL.split("-");
      this.mvAreaDDL =
        arr[0] + "年" + arr[1] + "月" + arr[2].substring(0, 2) + "日";

      this.chart1_summarize = summarize;
      this.chart1_cap_type = cap_type;

      this.$nextTick(() => {
        this.initChart1();
      });
    },

    initChart1(val) {
      if (!val) {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      } else {
        this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1_dialog);
      }

      // this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let summarize = this.chart1_summarize;
      let cap_type = this.chart1_cap_type;

      let dataTmp = this.dMvAreaData;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      //图例点击事件
      // this.chartColumn1.on('legendselectchanged', params => {
      //   console.log(params,'paramsaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
      //   this.Checked_one = false
      //   // this.fnGetMvArea()
      //   return
      //   // this.bHZ = false
      //   // this.initChart1()
      // })

      let X = dataTmp.map((node) => {
        return node.tr_date;
      });

      let DATATMP1 = Object.keys(dataTmp[0]);
      DATATMP1 = DATATMP1.filter((node) => {
        return node !== "tr_date";
      });

      let JYQL = [];
      let CQL = [];

      DATATMP1.forEach((node) => {
        this.category_cascader_options2.forEach((e, idx) => {
          if (e.name == node || e.data.includes(node)) {
            if (!idx) {
              CQL.push(node);
            } else {
              JYQL.push(node);
            }
          }
        });
      });

      DATATMP1 = [...CQL, ...JYQL];

      if (summarize) {
        DATATMP1.push("汇总");
      }

      let DATAFINAL = [];
      let bHZ = false;
      DATATMP1.forEach((node) => {
        if (node == "汇总") {
          bHZ = true;
        }
        let obj = {
          name: node,
          data: [],
        };
        dataTmp.forEach((e) => {
          obj.data.push(e[node]);
        });
        DATAFINAL.push(obj);
      });

      let NAME = DATATMP1;

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      orderData.push("汇总");
      let TMP = [];
      orderData.forEach((node) => {
        let obj = NAME.filter((item) => item == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      // NAME = TMP;

      let colors = this.$store.state.colors;
      colors.push({
        name: "汇总",
        value: "#d43030",
      });
      let colorArr = [];
      NAME.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let pointerVal = "";
      let HZ_data = [];

      let marketValue = cap_type;

      if (bHZ) {
        let obj = DATAFINAL.find((node) => {
          return node.name == "汇总";
        });

        HZ_data = obj.data;

        pointerVal = HZ_data[HZ_data.length - 1];
        pointerVal = Number(pointerVal) / 100000000;
        pointerVal = pointerVal.toFixed(1);

        let markPoint = {
          label: {
            show: true,
            fontSize: 14,
            position: "top",
          },
          // 标记的数据，可以是最大值最小值也可以是自定义的坐标
          data: [
            {
              name: "最后日期的数据",
              yAxis: HZ_data[HZ_data.length - 1],
              xAxis: X[X.length - 1],
              label: {
                normal: {
                  show: true,
                  formatter: `{d|${pointerVal}} \n   {b|最新全部REITs总${marketValue}}`,
                  rich: {
                    d: {
                      color: "#d43030",
                      fontSize: 24,
                      align: "right",
                      fontWeight: "bolder",
                    },
                    b: {
                      color: OPTIONDATA.title.textStyle.color,
                      fontSize: 14,
                      align: "left",
                    },
                  },
                },
              },
            },
          ],
          symbol: "circle", // 标记图形
          symbolSize: 18,
          // 标注点的样式
          itemStyle: {
            color: "#d43030", // 标注点颜色
            borderColor: "#fff",
            borderWidth: 2,
            label: {
              show: true,
            },
          },
        };

        DATAFINAL.map((node) => {
          if (node.name == "汇总") {
            node.markPoint = markPoint;
          }
        });
      }

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let seriesArr = DATAFINAL.map((node) => {
        return {
          name: node.name,
          data: node.data,
          type: "line",
          stack: node.markPoint ? "" : "all",
          smooth: false,
          lineStyle: {
            width: node.markPoint ? 6 : "",
          },
          symbol: "none",
          areaStyle: {
            opacity: node.markPoint ? "" : theme == "dark" ? 0.5 : lightOpacity,
          },
          label: {
            show: false,
            position: "top",
          },
          markPoint: node.markPoint ? node.markPoint : "",
        };
      });

      let option = {
        title: {
          // 标题设置
          text: "公募REITs全部REITs累计总" + marketValue, // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 12,
        },
        grid: {
          top: 110,
          bottom: 20,
          right: 80,
          left: 20,
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          // left:'right',
          left: 20,
          align: "left",
          top: 50,
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: 15,
          itemWidth: 40,
          itemHeight: 8,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            // color: "#fff",
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: NAME,
        },
        color: colorArr,
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = Number(node.value) / 100000000;
              val = val.toFixed(1);
              str += "<br>";
              str += `${node.marker}${node.seriesName}：${val}`;

              return str;
            });
            return str;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          data: X,
        },
        yAxis: {
          name: "单位（亿元）",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args) / 100000000;
              args = args.toFixed(fnGetMuchDecimals(args));
              return args;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesArr,
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize();
    },

    // 6. chartColumn4 方法
    fnCTimeButtons_select_val_chart5(val) {
      this.start_date_chart5 = val;
      this.fnGetAmtArea();
    },
    fnCCascader_select_val_five(val) {
      if (this.dButtons_separrate_current_color_five == "细分") {
        val = val.filter((node) => {
          return (
            node !== "公募REITs" && node !== "产权类" && node !== "经营权类"
          );
        });
      }

      this.category_cascader_val_five = val;
      this.fnGetAmtArea();
    },
    fnGetCbtnsSep_color5(val) {
      this.dButtons_separrate_current_color_five = val;
      this.fnGetAmtArea();
    },

    async fnGetAmtArea() {
      // let color_ = "细分";
      // let start_date = "3M";
      // let r_type = "公募REITs";
      // let summarize = 1;

      let color_ = this.dButtons_separrate_current_color_five;
      let start_date = this.start_date_chart5;
      let r_type = this.category_cascader_val_five;
      let str = r_type.join("&r_type=");
      let summarize = this.Checked_five ? 1 : 0;

      const { data } = await this.$https.get(
        "/api/amt_area?r_type=" +
          str +
          "&color_=" +
          color_ +
          "&start_date=" +
          start_date +
          "&summarize=" +
          summarize
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // this.dAmtAreaData = data.data;
      // this.dAmtAreaData_X = [];
      // this.dAmtAreaData_CYY = [];
      // this.dAmtAreaData_BZXZF = [];
      // this.dAmtAreaData_SZST = [];
      // this.dAmtAreaData_SFGL = [];
      // this.dAmtAreaData_HZ = [];
      // this.dAmtAreaData_WLCC = [];
      // this.dAmtAreaData_NY = [];

      this.json_fields4_5 = {};
      this.json_fields4_5_data = [];

      let dataTmp = data.data;
      this.json_fields4_5_data = data.data;
      // 判断VIP 普通 start
      let levelName = window.sessionStorage.getItem("levelName");
      // 判断VIP6个月 普通3个月 end
      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.json_fields4_5_data = this.json_fields4_5_data.filter((node) => {
          return node["tr_date"] >= result;
        });
      } else {
        let result = fnComputeTime("6M");
        this.json_fields4_5_data = this.json_fields4_5_data.filter((node) => {
          return node["tr_date"] >= result;
        });
      }
      // 判断VIP 普通 end

      // 下载 start
      let arr = Object.keys(dataTmp[0]);
      arr.forEach((node) => {
        this.json_fields4_5[node] = node;
      });

      // 下载 end

      // 下载 start

      // 下载 end

      // for (let index = 0; index < dataTmp.length; index++) {
      //   this.dAmtAreaData_X.push(dataTmp[index].tr_date);
      //   this.dAmtAreaData_CYY.push(dataTmp[index].产业园);
      //   this.dAmtAreaData_BZXZF.push(dataTmp[index].保障性住房);
      //   this.dAmtAreaData_SZST.push(dataTmp[index].市政生态);
      //   this.dAmtAreaData_SFGL.push(dataTmp[index].收费公路);
      //   this.dAmtAreaData_HZ.push(dataTmp[index].汇总);
      //   this.dAmtAreaData_WLCC.push(dataTmp[index].物流仓储);
      //   this.dAmtAreaData_NY.push(dataTmp[index].能源);
      // }
      this.chart4_data = dataTmp;
      this.$nextTick(() => {
        this.initChart4();
      });
    },

    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      } else {
        this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4_dialog);
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();

      let dataTmp = this.chart4_data;

      this.amtAreaDDL = dataTmp[dataTmp.length - 1].tr_date;
      let arr = this.amtAreaDDL.split("-");
      this.amtAreaDDL =
        arr[0] + "年" + arr[1] + "月" + arr[2].substring(0, 2) + "日";

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      // let dAmtAreaData_X = this.dAmtAreaData_X;
      // let dAmtAreaData_CYY = this.dAmtAreaData_CYY;
      // let dAmtAreaData_BZXZF = this.dAmtAreaData_BZXZF;
      // let dAmtAreaData_SZST = this.dAmtAreaData_SZST;
      // let dAmtAreaData_SFGL = this.dAmtAreaData_SFGL;
      // let dAmtAreaData_HZ = this.dAmtAreaData_HZ;
      // let dAmtAreaData_WLCC = this.dAmtAreaData_WLCC;
      // let dAmtAreaData_NY = this.dAmtAreaData_NY;

      let dAmtAreaData_X = dataTmp.map((node) => {
        return node.tr_date;
      });

      let data1 = Object.keys(dataTmp[0]);
      data1.shift();

      let JYQL = [];
      let CQL = [];

      data1.forEach((node) => {
        this.category_cascader_options2.forEach((e, idx) => {
          if (e.name == node || e.data.includes(node)) {
            if (!idx) {
              CQL.push(node);
            } else {
              JYQL.push(node);
            }
          }
        });
      });

      data1 = [...CQL, ...JYQL];

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = data1.filter((item) => item == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      data1 = TMP;

      if (this.Checked_five) {
        data1.push("汇总");
      }

      let colors = this.$store.state.colors;
      colors.push({
        name: "汇总",
        value: "#9d2a2b",
      });

      let colorArr = [];
      data1.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let Y = [];
      data1.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        dataTmp.forEach((e) => {
          obj.data.push(e[node]);
        });

        Y.push(obj);
      });

      // 判断是否又汇总
      let bHZ = Y.some((node) => {
        return node.name == "汇总";
      });

      let pointerVal = "";
      let HZ_data = [];
      if (bHZ) {
        let obj = Y.find((node) => {
          return node.name == "汇总";
        });

        HZ_data = obj.data;

        pointerVal = HZ_data[HZ_data.length - 1];
        pointerVal = Number(pointerVal) / 100000000;
        pointerVal = pointerVal.toFixed(2);
      }

      let markPoint = {
        label: {
          show: true,
          fontSize: 14,
          position: "top",
          align: "center",
        },
        // 标记的数据，可以是最大值最小值也可以是自定义的坐标
        data: [
          {
            name: "最后日期的数据",
            yAxis: HZ_data[HZ_data.length - 1],
            xAxis: dAmtAreaData_X[dAmtAreaData_X.length - 1],

            formatter: `最新全部REITs加\n总成交额${pointerVal}亿元`,
            label: {
              normal: {
                show: true,
                formatter: `{d|${pointerVal}} \n   {b|最新全部REITs加总成交额}`,
                rich: {
                  d: {
                    color: "#d43030",
                    fontSize: 24,
                    align: "right",
                    fontWeight: "bolder",
                  },
                  b: {
                    color: OPTIONDATA.title.textStyle.color,
                    fontSize: 14,
                    align: "left",
                  },
                },
              },
            },
          },
        ],
        symbol: "circle", // 标记图形
        symbolSize: 18,
        // 标注点的样式
        itemStyle: {
          color: "#d43030", // 标注点颜色
          borderColor: "#fff",
          borderWidth: 2,
          label: {
            show: true,
          },
        },
      };

      Y.map((node) => {
        if (node.name == "汇总") {
          node.markPoint = markPoint;
        }
      });

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let seriesArr = Y.map((node) => {
        return {
          name: node.name,
          data: node.data,
          type: "line",
          stack: node.markPoint ? "" : "all",
          smooth: false,
          symbol: "none",
          lineStyle: {
            width: node.markPoint ? 6 : "",
          },
          areaStyle: {
            opacity: node.markPoint ? "" : theme == "dark" ? 0.5 : lightOpacity,
          },

          label: {
            show: false,
            position: "top",
          },
          markPoint: node.markPoint ? node.markPoint : "",
        };
      });

      let option = {
        title: {
          // 标题设置
          text: "公募REITs成交额", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 12,
        },
        color: colorArr,
        grid: {
          top: 110,
          bottom: 30,
          right: 90,
          left: 30,
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          // left:'right',
          left: 65,
          align: "left",
          top: 50,
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: 15,
          itemWidth: 35,
          itemHeight: 10,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: data1,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (args) {
            let str = "";

            str += `${args[0].axisValueLabel}`;
            str += "<br>";

            // args = Number(args) / 100000000
            // args = args.toFixed(2)+'G';
            args.forEach((node) => {
              let val = (Number(node.value) / 100000000).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
          // formatter: function (args) {
          //   args = args.map(node=>{

          //     return node.value.toFixed(2)
          //   })
          //   return args;
          // },
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dAmtAreaData_X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          name: "单位(亿元)",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,

            formatter: function (args) {
              // M 百万 / 6
              // 十亿 / 9
              args = Number(args) / 100000000;
              let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              args = args.toFixed(num);
              return args;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesArr,
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      // this.funResize();
    },

    // 7. chartColumn5 方法
    fnCTimeButtons_select_val_chart4(val) {
      this.start_date_chart4 = val;
      this.fnGetDailyAmtTurnover();
    },

    fnCSelect_chart4(val) {
      this.select_val_target = val;
      this.fnGetDailyAmtTurnover();
    },
    fnGetCbtnsSep_color2(val) {
      this.dButtons_separrate_current_color_four = val;
      this.fnGetDailyAmtTurnover();
    },

    fnUpdateSwitch(val) {
      this.$eventBus.$emit("updateSwitch", val);
    },

    async fnGetDailyAmtTurnover() {
      // this.fullscreenLoading = true
      this.fnDispose();
      this.fnUpdateSwitch(true);
      this.loading = true;

      let factor = this.select_val_target;
      let avg_line = this.Checked_two ? 1 : 0;
      let start_date = this.start_date_chart4;

      const { data } = await this.$https.get(
        "/api/daily_amt_turnover?factor=" +
          factor +
          "&avg_line=" +
          avg_line +
          "&start_date=" +
          start_date
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let DATA = null;
      let AVG = null;
      if (avg_line) {
        DATA = data.data.main_data;
        AVG = data.data.avg;
      } else {
        DATA = data.data;
      }

      // 下载 start
      this.json_fields4_4_data = DATA;
      // 判断VIP 普通 start
      let levelName = window.sessionStorage.getItem("levelName");
      // 判断VIP6个月 普通3个月 end
      if (levelName == "普通客户") {
        let result = fnComputeTime("3M");
        this.json_fields4_4_data = this.json_fields4_4_data.filter((node) => {
          return node["tr_date"] >= result;
        });
      } else {
        let result = fnComputeTime("6M");
        this.json_fields4_4_data = this.json_fields4_4_data.filter((node) => {
          return node["tr_date"] >= result;
        });
      }
      // 判断VIP 普通 end

      if (factor == "成交额") {
        this.json_fields4_4 = {
          名称: "REITs_name",
          类型: "color",
          成交额: "y",
          size: "size",
          日期: "tr_date",
        };
      } else {
        // this.json_fields4_4_data.forEach(node=>{
        //   node['y'] = (node['y'] * 100).toFixed(1) + '%'
        // })
        this.json_fields4_4 = {
          名称: "REITs_name",
          类型: "color",
          换手率: "y",
          size: "size",
          日期: "tr_date",
        };
      }

      // 下载 end

      this.amtTurnoverDDL = DATA[DATA.length - 1].tr_date;
      let arr = this.amtTurnoverDDL.split("-");
      this.amtTurnoverDDL =
        arr[0] + "年" + arr[1] + "月" + arr[2].substring(0, 2) + "日";

      // 1. L2 分类

      // 2. 提取散点图x,y数据

      // let color = DATA.map(node=>{
      //   return node['color']
      // })
      //  color = [...new Set(color)];
      //  let tmpArr = []
      //   color.forEach(node=>{
      //   let arr = DATA.filter(item=>{
      //     return item['color']  == node
      //   })

      //   console.log(arr,'12312312');
      //   arr.forEach(ele=>{
      //     tmpArr.push(ele)
      //   })
      // })
      // console.log(color,'感谢');

      // console.log(tmpArr,'艾玛沃特森');
      this.chartColumn5_DATA = DATA;
      this.chartColumn5_AVG = AVG;

      this.$nextTick(() => {
        this.initChart5();
        // this.initChart5_plotly();
      });

      // this.loading = true;
    },

    initChart5(val) {
      this.loading = true;

      if (!val) {
        if (this.chartColumn5) {
          this.chartColumn5.dispose();
        }
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5, null, {
          renderer: "canvas",
          devicePixelRatio: 2,
        });
      } else {
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5_dialog);
      }

      // this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      this.chartColumn5.clear();
      let DATA = null;

      DATA = this.chartColumn5_DATA;

      let AVG = this.chartColumn5_AVG;

      // 1. 排序 start
      function fnSort(a, b) {
        return b.y - a.y;
      }
      DATA.sort(fnSort);
      //  1. 排序 end (跟排序无关)

      // 2. X轴 start
      let X = null;

      X = DATA.map((node) => {
        return node.tr_date;
      });

      X = [...new Set(X)];
      // 2. X轴 end

      // 3. 判断是否有平均线 start
      let AVG_Y = [];
      if (AVG) {
        let AVG_X = Object.keys(AVG[0]);
        AVG_X.shift();

        AVG_X.forEach((e) => {
          let obj = {
            name: e,
            data: [],
          };
          AVG.forEach((node) => {
            let arr = [];
            arr.push(node.tr_date);
            arr.push(node[e]);
            obj.data.push(arr);
            // obj.data.push(node[e])
          });
          AVG_Y.push(obj);
        });
      }

      // 3。 判断是否有平均线 end

      // 4. 图例 颜色 start
      let Name = DATA.map((node) => {
        return node.color;
      });

      let name2 = [];
      if (AVG) {
        name2 = AVG_Y.map((node) => {
          return node.name;
        });

        // Name = [...Name, ...name2];
      }
      Name = [...new Set(Name)];
      // Name =  ['产业园', '保障性住房', '消费', '物流仓储', '市政生态', '收费公路', '能源'];

      // console.log(Name,'波姬小丝2');

      // let orderData = JSON.parse(localStorage.getItem("ORDER"));
      //     let TMP = []
      //     orderData.forEach(node=>{
      //       let obj =  Name.filter(item=>item==node)
      //       if(obj.length>0){
      //         obj.forEach(item=>{
      //           TMP.push(item)
      //         })
      //       }
      //     })
      //  Name =  TMP

      // console.log(Name,'波姬小丝');

      let colors = this.$store.state.colors;

      let colorArr = [];
      Name.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let colorArr2 = [];
      name2.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr2.push(node.value);
          }
        });
      });

      let colorArrs = [...colorArr, ...colorArr2];

      // 4. 图例 颜色 end

      // 5. 系列数据 start
      let start_date_chart4 = this.start_date_chart4;
      let seriesArr = [];
      let DATA2 = [];
      let DATA3 = [];
      // DATA.forEach(node=>{
      //   let arr = []
      //   arr.push(node['tr_date'])
      //   arr.push(node['y'])
      //   DATA2.push(arr)
      // })

      Name.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        obj.data = DATA.filter((item) => {
          return item["color"] == node;
        });
        DATA3.push(obj);
      });


      DATA3.forEach((node) => {
        let obj = {
          name: node.name,
          data: [],
        };

        node.data.forEach((item) => {
          let obj2 = {
            value: [],
            symbolSize:
              start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
                ? 3 + item["size"] / 100000000 / 20
                : 10 + item["size"] / 100000000 / 20,
          };
          // let arr = [];
          obj2.value.push(item["tr_date"]);
          obj2.value.push(item["y"]);
          obj.data.push(obj2);
        });

        DATA2.push(obj);
      });

      DATA2.forEach((node) => {
        let obj = {
          name: node.name,
          type: "scatter",
          large: true,
          largeThreshold: 1000,
          cursor: "pointer",
          // data: case1JSON,
          data: node.data,

          //  data: [
          //   {
          //     value:['2024-01-12', 8.04],
          //     symbol:'diamond'
          //   }
          //   ],
        };

        seriesArr.push(obj);
      });

      //  seriesArr = [
      //  {
      //         type: "scatter",
      //         // large: true,
      //         // largeThreshold: 1,
      //         symbolSize:10,
      //       //   symbolSize: function(val,val2){
      //       //       return 10
      //       // //    let size =  DATA[val2.dataIndex].size
      //       // //    let value =  start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
      //       // //  ? 3 + size / 100000000 / 20
      //       // // : 10 + size / 100000000 / 20
      //       // //     return  value
      //       //   },
      //        cursor: "pointer",
      //         // data: case1JSON,
      //         // data:node.data
      //         data:DATA2
      //   },

      //  ]

      //    DATA.forEach(v=>{
      //     let obj =  {
      //         name: v["color"],
      //         type: "scatter",
      //         large: true,
      //         largeThreshold: 2000,
      //         symbolSize:
      //          start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
      //         ? 3 + v["size"] / 100000000 / 20
      //         : 10 + v["size"] / 100000000 / 20,
      //         cursor: "pointer",
      //         // data: case1JSON,
      //         data:[[v["tr_date"], v["y"]]]
      //     }
      //       seriesArr.push(obj)
      // })

      // DATA.forEach((v, index) => {
      //   let obj = {
      //     name: v["color"],
      //     data: [[v["tr_date"], v["y"]]],
      //     type: "scatter",
      //     large: true,
      //     largeThreshold: 2000,
      //     symbolSize: v["size"] / 100000000 / 3,
      //     symbolSize:
      //       start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
      //         ? 3 + v["size"] / 100000000 / 20
      //         : 10 + v["size"] / 100000000 / 20,

      //     cursor: "pointer",
      //   };

      //   seriesArr.push(obj);
      // });

      // seriesArr = DATA.map((v, index) => {
      //   return {
      //     name: v["color"],
      //     data: [[v["tr_date"], v["y"]]],
      //     type: "scatter",
      //     large: true,
      //     // symbolSize: v["size"] / 100000000 / 3,
      //     symbolSize:
      //       start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
      //         ? 3 + v["size"] / 100000000 / 20
      //         : 10 + v["size"] / 100000000 / 20,

      //     cursor: "pointer",
      //   };
      // });

      // 6. 系列数据 end

      // 7. 有平均线 start
      if (AVG) {
        let data = [
          {
            name: AVG_Y[0].name,
            data: AVG_Y[0].data,
            type: "line",
            symbol: "none",
            // sampling: "lttb",
            smooth: true,
            // animation: false,

            tooltip: {
              show: true,
            },
          },
          {
            name: AVG_Y[1].name,
            data: AVG_Y[1].data,
            type: "line",
            symbol: "none",
            // sampling: "lttb",
            smooth: true,
            // animation: false,

            tooltip: {
              show: true,
            },
          },
        ];

        seriesArr.push(data[0]);
        seriesArr.push(data[1]);

        // AVG_Y.forEach((node) => {
        //   let obj = {
        //     name: node.name,
        //     data: node.data,
        //     type: "line",
        //     symbol: "none",
        //     sampling: "lttb",
        //     smooth: true,
        //     animation: false,

        //     tooltip: {
        //       show: true,
        //     },
        //   };
        //   seriesArr.push(obj);
        // });
      }
      // 7. 有平均线 end

      //-------------------------- 没关系-------------------------------

      let target = this.select_val_target;
      // let text = target == "成交额" ? "（万元）" : "（%）";
      let text = target == "成交额" ? "万元" : "%";

      let texta = this.start_date_chart4;
      let textb = this.select_val_target;

      let name2X = 38 + Name.length * 85;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = null;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      Name = Name.map((node, idx) => {
        let obj = {
          name: node,
          itemStyle: {
            color: colorArrs[idx],
          },
        };
        return obj;
      });

      option = {
        animation: false,
        title: {
          // 标题设置
          text: texta + "公募REITs按资产类别" + textb + "对比",
          textStyle: {
            // 标题文字样式设置
            // color: "#fff",
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 20,
        },
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        tooltip: {
          show: true,
          trigger: "axis",
          // confine: true,
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          // display: flex; flex-wrap: wrap; justify-content: space-between;
          // extraCssText: `column-count: 2;`,
          confine: true,
          triggerOn: "mousemove|click",
          formatter: function (args) {
            // 1. 排序 start
            function fnSort(a, b) {
              if(a['componentSubType'] == 'scatter' && b['componentSubType'] == 'scatter'){
                return b['value'][1] - a['value'][1];
              }
            }
            args.sort(fnSort);
            //  1. 排序 end (跟排序无关)


            let date = args[0].value[0];
            let str = `<div style="width: 100%;">${date}</div>`;
            str += `<div style="column-count: 2;">`;
            // str += "<br>";
            args.forEach((node) => {
              if (node.seriesType !== "line") {
                let index = node.dataIndex;
                let seriesName = node.seriesName;
                let seriesObj = DATA3.find((node) => node.name == seriesName);
                // let name = DATA[index].REITs_name;
                let name = seriesObj.data[index].REITs_name;
                let num = target == "成交额" ? 1 : 2;
                let val = (
                  target == "换手率"
                    ? Number(node.value[1]) * 100
                    : Number(node.value[1]) / 10000
                ).toFixed(num);
                if (target == "换手率") {
                  val = val + "%";
                }
                // str += `${node.marker}${name}：${val}`;

                str += `<div style="display: flex;justify-content: space-between;"><span>${node.marker}${name}：</span> <span>${val}</span></div>`;

                // str += "<br>";

                return str;
              } else {
                let num = target == "成交额" ? 1 : 2;
                let val = (
                  target == "换手率"
                    ? Number(node.value[1]) * 100
                    : Number(node.value[1]) / 10000
                ).toFixed(num);
                if (target == "换手率") {
                  val = val + "%";
                }

                let color = colors.find(
                  (ele) => ele.name == node.seriesName
                ).value;

                let marker = `<span style="display:inline-block;margin-right:4px;border-radius:2px;width:20px;height:5px;background-color:${color};"></span>`;
                str += `<div style="display: flex;justify-content: space-between;"><span>${marker}${node.seriesName}：</span><span>${val}</span></div>`;

                // str += `${marker}${node.seriesName}：${val}`;
                // str += "<br>";
                return str;
              }
            });

            str += `</div>`;
            return str;
          },
        },

        grid: {
          //方法 2
          // top: 118,
          // left: 15,
          left: 20,
          top: 120,
          right: 120,
          bottom: 10,
          containLabel: true,
        },

        legend: [
          {
            orient: "horizontal",
            left: 38,
            top: 55,
            icon: "circle",
            right: 10,
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            align: "left",
            data: Name,
          },

          {
            orient: "horizontal",
            left: name2X,
            top: 55,
            icon: "roundRect",
            itemHeight: 5,
            itemWidth: 35,
            right: 10,
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            align: "left",
            data: name2,
          },
        ],

        color: colorArrs,
        xAxis: {
          // type:'category'
          type: "time",
          // data: X,
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (val) {
              let date = new Date(val);
              var y = date.getFullYear();
              var m = date.getMonth() + 1;
              var d = date.getDate();
              var h = date.getHours();
              var m1 = date.getMinutes();
              var s = date.getSeconds();
              m = m < 10 ? "0" + m : m;
              d = d < 10 ? "0" + d : d;
              h = h < 10 ? "0" + h : h;
              m1 = m1 < 10 ? "0" + m1 : m1;
              s = s < 10 ? "0" + s : s;

              return y + "-" + m + "-" + d;
              // return y + "-" + m + "-" + d;
            },
          },
        },
        yAxis: {
          name: "单位(" + text + ")",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: "value",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              if (target == "成交额") {
                return Number(val / 10000).toLocaleString();
              } else {
                let value = Number(val) * 100;
                let num = (parseInt(Math.abs(value)) + "").length >= 2 ? 1 : 2;
                value = value.toFixed(num);
                return Number(value) + "%";
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 20,
        //     end: 100,
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 20,
        //     end: 100,
        //   },
        // ],

        series: seriesArr,
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();

     

      this.loading = false;
      setTimeout(() => {
        this.$refs.chart5Time.isOpen = true;
      }, 500);

      this.fnUpdateSwitch(false);
    },

    initChart5_1332(val) {
      this.loading = true;

      if (!val) {
        if (this.chartColumn5) {
          this.chartColumn5.dispose();
        }
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5, null, {
          renderer: "canvas",
          devicePixelRatio: 2,
        });
      } else {
        this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5_dialog);
      }

      // this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      this.chartColumn5.clear();
      let DATA = null;

      DATA = this.chartColumn5_DATA;

      let AVG = this.chartColumn5_AVG;

      // 1. 排序 start
      function fnSort(a, b) {
        return b.y - a.y;
      }
      DATA.sort(fnSort);
      //  1. 排序 end (跟排序无关)

      // 2. X轴 start
      let X = null;

      X = DATA.map((node) => {
        return node.tr_date;
      });

      X = [...new Set(X)];

      // 2. X轴 end

      // 3. 判断是否有平均线 start
      let AVG_Y = [];
      if (AVG) {
        let AVG_X = Object.keys(AVG[0]);
        AVG_X.shift();

        AVG_X.forEach((e) => {
          let obj = {
            name: e,
            data: [],
          };
          AVG.forEach((node) => {
            // let arr = [];
            // arr.push(node.tr_date);

            // arr.push(new Date(node.tr_date).getTime());
            // arr.push(node[e]);
            // obj.data.push(arr);
            obj.data.push(node[e]);
            // obj.data.push(node[e])
          });
          AVG_Y.push(obj);
        });
      }


      // 3。 判断是否有平均线 end

      // 4. 图例 颜色 start
      let Name = DATA.map((node) => {
        return node.color;
      });

      let name2 = [];
      if (AVG) {
        name2 = AVG_Y.map((node) => {
          return node.name;
        });

        // Name = [...Name, ...name2];
      }
      Name = [...new Set(Name)];
      // Name =  ['产业园', '保障性住房', '消费', '物流仓储', '市政生态', '收费公路', '能源'];

      // console.log(Name,'波姬小丝2');

      // let orderData = JSON.parse(localStorage.getItem("ORDER"));
      //     let TMP = []
      //     orderData.forEach(node=>{
      //       let obj =  Name.filter(item=>item==node)
      //       if(obj.length>0){
      //         obj.forEach(item=>{
      //           TMP.push(item)
      //         })
      //       }
      //     })
      //  Name =  TMP

      // console.log(Name,'波姬小丝');

      let colors = this.$store.state.colors;

      let colorArr = [];
      Name.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let colorArr2 = [];
      name2.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr2.push(node.value);
          }
        });
      });

      let colorArrs = [...colorArr, ...colorArr2];

      // 4. 图例 颜色 end

      // 5. 系列数据 start
      let start_date_chart4 = this.start_date_chart4;

      let seriesArr = [];

      // DATA.forEach((v, index) => {
      //   if(index < 3600){

      //   let obj = {
      //     name: v["color"],
      //     data: [[v["tr_date"], v["y"]]],
      //     // data: [[new Date(v["tr_date"]).getTime(), v["y"]]],
      //     type: "scatter",
      //     large: true,
      //     largeThreshold:1000,
      //     // symbolSize: v["size"] / 100000000 / 3,
      //     symbolSize:
      //       start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
      //         ? 3 + v["size"] / 100000000 / 20
      //         : 10 + v["size"] / 100000000 / 20,

      //     cursor: "pointer",
      //   };

      //   seriesArr.push(obj);
      //   }
      // });

      X.forEach((v, index) => {
        let obj = {
          // name: v["color"],
          data: 1316750000,
          // data: [[new Date(v["tr_date"]).getTime(), v["y"]]],
          type: "bar",
          itemStyle: {
            color: "pink",
          },

          // symbolSize: v["size"] / 100000000 / 3,

          cursor: "pointer",
        };

        seriesArr.push(obj);
      });


      // seriesArr = DATA.map((v, index) => {
      //   return {
      //     name: v["color"],
      //     data: [[v["tr_date"], v["y"]]],
      //     type: "scatter",
      //     large: true,
      //     // symbolSize: v["size"] / 100000000 / 3,
      //     symbolSize:
      //       start_date_chart4 == "1Y" || start_date_chart4 == "MAX"
      //         ? 3 + v["size"] / 100000000 / 20
      //         : 10 + v["size"] / 100000000 / 20,

      //     cursor: "pointer",
      //   };
      // });

      // 6. 系列数据 end

      // 7. 有平均线 start
      if (AVG) {
        AVG_Y.forEach((node) => {
          let obj = {
            name: node.name,
            data: node.data,
            type: "line",
            symbol: "none",
            sampling: "lttb",
            smooth: true,
            animation: false,

            tooltip: {
              show: true,
            },
          };
          seriesArr.push(obj);
        });
      }
      // 7. 有平均线 end

      //-------------------------- 没关系-------------------------------

      let target = this.select_val_target;
      // let text = target == "成交额" ? "（万元）" : "（%）";
      let text = target == "成交额" ? "万元" : "%";

      let texta = this.start_date_chart4;
      let textb = this.select_val_target;

      let name2X = 38 + Name.length * 85;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = null;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      Name = Name.map((node, idx) => {
        let obj = {
          name: node,
          itemStyle: {
            color: colorArrs[idx],
          },
        };
        return obj;
      });

      option = {
        animation: false,
        title: {
          // 标题设置
          text: texta + "公募REITs按资产类别" + textb + "对比",
          textStyle: {
            // 标题文字样式设置
            // color: "#fff",
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 20,
        },
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },
        tooltip: {
          show: true,
          trigger: "axis",
          // confine: true,
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          // display: flex; flex-wrap: wrap; justify-content: space-between;
          // extraCssText: `column-count: 2;`,
          confine: true,
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let date = args[0].value[0];
            let str = `<div style="width: 100%;">${date}</div>`;
            str += `<div style="column-count: 2;">`;
            // str += "<br>";
            args.forEach((node) => {
              if (node.seriesType !== "line") {
                let index = node.seriesIndex;
                let name = DATA[index].REITs_name;

                let num = target == "成交额" ? 1 : 2;
                let val = (
                  target == "换手率"
                    ? Number(node.value[1]) * 100
                    : Number(node.value[1]) / 10000
                ).toFixed(num);
                if (target == "换手率") {
                  val = val + "%";
                }
                // str += `${node.marker}${name}：${val}`;

                str += `<div style="display: flex;justify-content: space-between;"><span>${node.marker}${name}：</span> <span>${val}</span></div>`;

                // str += "<br>";

                return str;
              } else {
                let num = target == "成交额" ? 1 : 2;
                let val = (
                  target == "换手率"
                    ? Number(node.value[1]) * 100
                    : Number(node.value[1]) / 10000
                ).toFixed(num);
                if (target == "换手率") {
                  val = val + "%";
                }

                let color = colors.find(
                  (ele) => ele.name == node.seriesName
                ).value;

                let marker = `<span style="display:inline-block;margin-right:4px;border-radius:2px;width:20px;height:5px;background-color:${color};"></span>`;
                str += `<div style="display: flex;justify-content: space-between;"><span>${marker}${node.seriesName}：</span><span>${val}</span></div>`;

                // str += `${marker}${node.seriesName}：${val}`;
                // str += "<br>";
                return str;
              }
            });

            str += `</div>`;
            return str;
          },
        },

        grid: {
          //方法 2
          // top: 118,
          // left: 15,
          left: 20,
          top: 120,
          right: 120,
          bottom: 10,
          containLabel: true,
        },

        legend: [
          {
            orient: "horizontal",
            left: 38,
            top: 55,
            icon: "circle",
            right: 10,
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            align: "left",
            data: Name,
          },

          {
            orient: "horizontal",
            left: name2X,
            top: 55,
            icon: "roundRect",
            itemHeight: 5,
            itemWidth: 35,
            right: 10,
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            align: "left",
            data: name2,
          },
        ],

        color: colorArrs,
        xAxis: {
          // type: "time",
          type: "category",
          data: X,
          interval: 200,
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (val) {
              let date = new Date(val);
              var y = date.getFullYear();
              var m = date.getMonth() + 1;
              var d = date.getDate();
              var h = date.getHours();
              var m1 = date.getMinutes();
              var s = date.getSeconds();
              m = m < 10 ? "0" + m : m;
              d = d < 10 ? "0" + d : d;
              h = h < 10 ? "0" + h : h;
              m1 = m1 < 10 ? "0" + m1 : m1;
              s = s < 10 ? "0" + s : s;

              return y + "-" + m + "-" + d;
              // return y + "-" + m + "-" + d;
            },
          },
        },
        yAxis: {
          name: "单位(" + text + ")",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: "value",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              if (target == "成交额") {
                return Number(val / 10000).toLocaleString();
              } else {
                let value = Number(val) * 100;
                let num = (parseInt(Math.abs(value)) + "").length >= 2 ? 1 : 2;
                value = value.toFixed(num);
                return Number(value) + "%";
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 20,
            end: 100,
          },
          {
            type: "inside",
            realtime: true,
            start: 20,
            end: 100,
          },
        ],

        series: seriesArr,
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();

      this.loading = false;
      setTimeout(() => {
        this.$refs.chart5Time.isOpen = true;
      }, 500);

      this.fnUpdateSwitch(false);
    },

    initChart5_plotly() {
      return;
      this.chartColumn5_plotly = this.$echarts.init(
        this.$refs.chartColumn5_plotly,
        null,
        {
          renderer: "canvas",
        }
      );

      console.log(case1JSON, "case1JSON");

      let option = {
        title: {
          text: "Dispersion of house price based on the area",
          left: "center",
          top: 0,
        },
        visualMap: {
          min: 15202,
          max: 159980,
          dimension: 1,
          orient: "vertical",
          right: 10,
          top: "center",
          text: ["HIGH", "LOW"],
          calculable: true,
          inRange: {
            color: ["#f2c31a", "#24b7f2"],
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              show: true,
            },
          },
        },
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "price-area",
            type: "scatter",
            symbolSize: 5,
            data: case1JSON,
          },
        ],
      };

      this.chartColumn5_plotly.setOption(option);
      this.chartColumn5_plotly.resize();
    },

    initChart5ChangeColor() {
      this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5, null, {
        renderer: "canvas",
      });
      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = null;

      option = {
        title: {
          // 标题设置
          textStyle: {
            // 标题文字样式设置
            // color: "#fff",
            color: OPTIONDATA.title.textStyle.color,
          },
        },

        legend: [
          {
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
            },
          },

          {
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
            },
          },
        ],

        xAxis: {
          axisLabel: {
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,

          axisLabel: {
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            lineStyle: {
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
      };

      this.chartColumn5.setOption(option);
      this.loading = false;
      this.fnUpdateSwitch(false);
    },

    // 公募REITs市值及流通市值 玫瑰图 方法
    fnCTimeButtons_select_val_chart2(val) {
      this.start_date_chart2 = val;
      this.fnGetMvRose();
    },
    fnGetCbtnsSep_chart6(val) {
      this.dButtons_separrate_current_chart6 = val;
      this.fnGetMvRose();
    },
    fnGetCbtnsSep_category_chart6(val) {
      this.dButtons_separrate_current_category_chart6 = val;
      this.fnGetMvRose();
    },

    async fnGetMvRose() {
      let cap_type = this.dButtons_separrate_current_chart6;
      let freq_type =
        this.start_date_chart2 == "MAX" ? "max" : this.start_date_chart2;
      let color_ = this.dButtons_separrate_current_category_chart6;

      const { data } = await this.$https.get(
        "/api/mv_rose?cap_type=" +
          cap_type +
          "&freq_type=" +
          freq_type +
          "&color_=" +
          color_
      );

      if (data.code !== 200) return this.$message.error(data.msg);
      let ARRAY_1 = data.data;
      // this.dMvRose = data.data
      let dataTmp = data.data;

      // 下载 start

      if (color_ !== "单支REITs") {
        this.json_fields4_3_data.push(data.data);
        let arr = Object.keys(dataTmp);
        arr.forEach((node) => {
          this.json_fields4_3[node] = node;
        });
      } else {
        this.json_fields4_3_data = data.data;
        this.json_fields4_3 = {
          代码: "reit_code",
          名称: "REITs_name",
          大类: "REITs_type_L1",
          类型: "REITs_type_L2",
          市值: "data",
        };
      }

      // 下载 end

      this.initChart67dataTmp = dataTmp;
      if (color_ == "大类" || color_ == "细分") {
        this.$nextTick(() => {
          this.initChart6_1();
          this.initChart7_1();
        });
        return;
      }

      this.dMvRose = [];
      this.dMvRose_X = [];
      this.dMvRose_Y = [];

      this.dMvRose = this.fnSort(dataTmp);
      // 按照葱花的排序 在排一遍 start

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = this.dMvRose.filter((item) => item["REITs_type_L2"] == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      this.dMvRose = TMP;
      // 按照葱花的排序 在排一遍 end
      this.dMvRose.forEach((node) => {
        this.dMvRose_X.push(node["REITs_name"]);
        this.dMvRose_Y.push(node["data"]);
      });

      // 完成 漂亮

      // 计算饼图需要的数据
      // 1. 计算L1
      let arr_L1 = ARRAY_1.map((node) => {
        return node.REITs_type_L1;
      });

      let arr_L1_AFTER = [...new Set(arr_L1)];
      let arr_L1_FINAL = [];
      arr_L1_AFTER.forEach((node) => {
        let val = this.fnCount(ARRAY_1, node);
        let obj = {
          value: val,
          name: node,
        };
        arr_L1_FINAL.push(obj);
      });
      // L1 正确

      // 2. 计算L2
      let arr_L2 = ARRAY_1.map((node) => {
        return node.REITs_type_L2;
      });

      let arr_L2_AFTER = [...new Set(arr_L2)];
      let arr_L2_FINAL = [];
      arr_L2_AFTER.forEach((node) => {
        let val = this.fnCount2(ARRAY_1, node);
        let obj = {
          value: val,
          name: node,
        };
        arr_L2_FINAL.push(obj);
      });

      arr_L2_FINAL = this.fnSortCate_L2(arr_L2_FINAL);

      let l2_Name = arr_L2_FINAL.map((node) => {
        return node.name;
      });

      // L2 正确

      let arr_L3_FINAL = [];
      ARRAY_1.forEach((node) => {
        let obj = {
          name: node.REITs_name,
          value: node.data,
        };
        arr_L3_FINAL.push(obj);
      });

      arr_L3_FINAL = this.fnSort_L3(arr_L3_FINAL, l2_Name);

      // L3 正确

      this.nestedPies_L1 = arr_L1_FINAL;
      this.nestedPies_L2 = arr_L2_FINAL;
      this.nestedPies_L3 = arr_L3_FINAL;
      this.$nextTick(() => {
        this.initChart6();
      });
      // this.initChart7();
    },
    initChart6(val) {
      // this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      if (!val) {
        this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      } else {
        this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6_dialog);
      }

      this.chartColumn6.clear();

      let dMvRose_X = this.dMvRose_X;
      let dMvRose_Y = this.dMvRose_Y;

      let maxVAl = Math.max(...dMvRose_Y);

      let colors = this.$store.state.colors;

      var that = this;

      let texta = this.start_date_chart2;
      let textb = this.dButtons_separrate_current_chart6;

      let CODELIST = this.$store.state.CODELIST;
      let CODENAME = CODELIST.map((node) => {
        let obj = {
          code: node.reitCode,
          name: node.reitsName,
        };
        return obj;
      });

      let MAP_L2 = CODELIST.map((node) => {
        let obj = {
          code: node.reitCode,
          name: node.reitsTypeL2,
        };
        return obj;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let option = {
        title: {
          text: "公募REITs-" + texta + "期间平均" + textb + "对比",
          left: "center",
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          top: 10,
        },
        grid: {
          top: 100,
          bottom: 30,
        },
        polar: {
          radius: "70%",
        },
        radiusAxis: {
          max: maxVAl,
          axisLabel: {
            show: true,
            formatter: function (val) {
              return (Number(val) / 100000000).toFixed(0) + "亿";
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(72, 92, 117, 0.62)",
              type: "solid",
            },
          },
        },
        angleAxis: {
          type: "category",
          data: dMvRose_X,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(72, 92, 117, 0.62)",
              type: "solid",
            },
          },
          axisLabel: {
            fontSize: 12,
            // color: "rgba(255, 255, 255, 1)",
            color: OPTIONDATA.xAxis.axisLabel.color,
            margin: 10,
          },
        },

        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            args.forEach((node) => {
              let val = (Number(node.value) / 100000000).toFixed(0);
              str += `${node.marker}${node.name}：${val}亿`;

              return str;
            });
            return str;
          },
        },
        series: {
          type: "bar",
          data: dMvRose_Y,
          coordinateSystem: "polar",
          label: {
            show: false,
            position: "middle",
          },
          itemStyle: {
            color: function (params) {
              let name = params.name;
              // 计算出所属MAP
              var val1 = "";
              // for (let i in that.MAP) {
              //   if (name == that.MAP[i]) {
              //     val1 = i;
              //   }
              // }
              let mapobj = CODENAME.filter((node) => {
                return node.name == name;
              });
              val1 = mapobj[0].code;

              // 计算出所属MAP_l2
              let val2 = "";
              // for (let i in that.MAP_L2) {
              //   if (val1 == i) {
              //     val2 = that.MAP_L2[i];
              //   }
              // }
              let mapl2obj = MAP_L2.filter((node) => {
                return node.code == val1;
              });
              val2 = mapl2obj[0].name;

              let obj2 = colors.find((node) => {
                return node.name == val2;
              });

              return obj2.value;
            },
            opacity: theme == "dark" ? 0.5 : lightOpacity,
          },
        },
      };

      this.chartColumn6.setOption(option);
      this.chartColumn6.resize();

      this.$nextTick(() => {
        this.initChart7();
      });
    },
    initChart6_1(val) {
      if (!val) {
        this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      } else {
        this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6_dialog);
      }

      // this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      this.chartColumn6.clear();
      let dataTmp = this.initChart67dataTmp;

      let dMvRose_X = Object.keys(dataTmp);
      let dMvRose_Y = [];

      let JYQL = [];
      let CQL = [];

      dMvRose_X.forEach((node) => {
        this.category_cascader_options2.forEach((e, idx) => {
          if (e.name == node || e.data.includes(node)) {
            if (!idx) {
              CQL.push(node);
            } else {
              JYQL.push(node);
            }
          }
        });
      });

      dMvRose_X = [...CQL, ...JYQL];
      dMvRose_X.forEach((node) => {
        dMvRose_Y.push(dataTmp[node]);
      });

      let maxVAl = Math.max(...dMvRose_Y);

      let colors = this.$store.state.colors;

      var that = this;

      let texta = this.start_date_chart2;
      let textb = this.dButtons_separrate_current_chart6;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let option = {
        title: {
          text: "公募REITs-" + texta + "以来期间平均" + textb + "对比",
          left: "center",
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          top: 10,
        },
        grid: {
          top: 100,
          bottom: 30,
        },
        polar: {
          radius: "70%",
        },
        radiusAxis: {
          max: maxVAl,
          axisLabel: {
            show: true,
            formatter: function (val) {
              return (Number(val) / 100000000).toFixed(0) + "亿";
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(72, 92, 117, 0.62)",
              type: "solid",
            },
          },
        },
        angleAxis: {
          type: "category",
          data: dMvRose_X,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(72, 92, 117, 0.62)",
              type: "solid",
            },
          },
          axisLabel: {
            fontSize: 12,
            color: OPTIONDATA.xAxis.axisLabel.color,
            margin: 10,
          },
        },

        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            args.forEach((node) => {
              let val = (Number(node.value) / 100000000).toFixed(0);
              str += `${node.marker}${node.name}：${val}亿`;

              return str;
            });
            return str;
          },
        },
        series: {
          type: "bar",
          data: dMvRose_Y,
          coordinateSystem: "polar",
          label: {
            show: false,
            position: "middle",
          },
          itemStyle: {
            color: function (params) {
              let name = params.name;

              let obj2 = colors.find((node) => {
                return node.name == name;
              });

              return obj2.value;
            },
            opacity: theme == "dark" ? 0.5 : lightOpacity,
          },
        },
      };

      this.chartColumn6.setOption(option);
      this.chartColumn6.resize();
    },
    initChart7(val) {
      if (!val) {
        this.chartColumn7 = this.$echarts.init(this.$refs.chartColumn7);
      } else {
        this.chartColumn7 = this.$echarts.init(this.$refs.chartColumn7_dialog);
      }

      // this.chartColumn7 = this.$echarts.init(this.$refs.chartColumn7);
      this.chartColumn7.clear();

      let nestedPies_L1 = this.nestedPies_L1;
      let nestedPies_L2 = this.nestedPies_L2;
      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = nestedPies_L2.filter((item) => item["name"] == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });
      nestedPies_L2 = TMP;

      let nestedPies_L3 = this.nestedPies_L3;

      // 按照葱花排序 start
      let TMP2 = [];
      orderData.forEach((node) => {
        let obj = nestedPies_L3.filter((item) => item["L2"] == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP2.push(item);
          });
        }
      });
      nestedPies_L3 = TMP2;
      // 按照葱花排序 end

      function compare(val1, val2) {
        return val1.value - val2.value;
      }
      nestedPies_L1.sort(compare);

      let arr1 = nestedPies_L1.map((node) => {
        return node.value;
      });

      let arr2 = nestedPies_L2.map((node) => {
        return node.value;
      });

      let arr3 = nestedPies_L3.map((node) => {
        return node.value;
      });

      // let max = Math.max(...arr1);
      let max = Math.max(...arr1);
      let min = Math.min(...arr2);

      // l2的分类占比

      let ProportionArray = this.fnSortCate_L2_Proportion(nestedPies_L2);

      let colorARR = [
        "#f5fbfc",
        "#e9f7fa",
        "#c4e9e2",
        "#99d8c9",
        "#7bcbb3",
        "#51b78a",
        "#248c46",
        "#006328",
      ];

      let texta = this.start_date_chart2;
      let textb = this.dButtons_separrate_current_chart6;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      switch (texta) {
        case "3M":
          texta = "3个月以来";
          break;
        case "YTD":
          texta = "今年以来";
          break;

        case "6M":
          texta = "6个月以来";
          break;

        case "1Y":
          texta = "1年以来";

          break;
        case "3Y":
          texta = "3年以来";
          break;

        case "MAX":
          texta = "上市以来";
          break;
      }

      let option = {
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{a} <br/>{b}: {c} ({d}%)'
        // },

        title: {
          // 标题设置
          text:
            "公募REITs-各支REITs及分类-" +
            texta +
            "期间平均" +
            textb +
            "占比分解", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 10,
        },
        grid: {
          top: 100,
          left: 50,
          right: 100,
          bottom: 30,
          // bottom:80,
          containLabel: true,
        },

        // visualMap: {
        //   seriesIndex:[0,1],
        //   min: min,
        //   max: max,
        //   text:['单个REITs市值范围'],
        //   textStyle:{
        //     color:'rgba(158, 158, 158, 1)',
        //     fontSize: 14,
        //     // margin:10
        //   },
        //   calculable: false,
        //   orient: 'vertical',
        //   inRange: {
        //     color: colorARR
        //   },
        //   itemHeight :450,
        //   right: 30,
        //   top:'middle',
        //   bottom: '15%'
        // },

        series: [
          {
            name: "Access From1",
            type: "pie",
            // selectedMode: 'single', //表示是否支持多个选中
            radius: [0, "25%"],
            itemStyle: {
              // borderRadius: 10,
              // borderColor: 'rgba(0, 0, 0, 0)',
              // borderWidth: 2
              normal: {
                borderColor: "#fff",
                borderWidth: 1,
                color: function (val) {
                  let idx = val.dataIndex;
                  return colorARR[6 + idx];
                },
              },
            },
            label: {
              position: "inside",
              formatter: "{b}：{d}%",
              textStyle: {
                fontSize: 12,
                color: OPTIONDATA.title.textStyle.color,
              },
            },
            labelLine: {
              show: false,
            },
            data: nestedPies_L1,
          },
          {
            name: "Access From2",
            type: "pie",
            // selectedMode: 'single', //表示是否支持多个选中
            // radius: [0, '30%'],
            radius: ["25%", "38%"],
            itemStyle: {
              normal: {
                //  borderRadius: 3,
                borderColor: "#fff",
                borderWidth: 1,
                color: function (val) {
                  let idx = val.dataIndex;
                  return colorARR[idx];
                },
              },
            },
            label: {
              position: "inside",
              formatter: "{b}：{d}%",
              fontSize: 12,
              textStyle: {
                color: "#333",
              },
            },
            labelLine: {
              show: false,
            },
            data: nestedPies_L2,
          },
          {
            name: "Access From3",
            type: "pie",

            // selectedMode: 'single', //表示是否支持多个选中
            // radius: [0, '30%'],
            radius: ["38%", "70%"],
            itemStyle: {
              // borderRadius: 10,
              // borderColor: 'rgba(0, 0, 0, 0)',
              // borderWidth: 2
              normal: {
                // borderRadius: 3,
                borderColor: "#fff",
                borderWidth: 1,
                color: function (val) {
                  let l2 = val.data.L2;
                  let idx = 0;

                  for (let i = 0; i < nestedPies_L2.length; i++) {
                    if (nestedPies_L2[i].name == l2) {
                      idx = i;
                    }
                  }

                  return colorARR[idx];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}：{d}%",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
                fontSize: 12,
              },
            },
            labelLine: {
              show: true,
              normal: {
                length: 15, // 指示线宽度
                lineStyle: {
                  color: "#fff", // 指示线颜色
                },
              },
            },

            data: nestedPies_L3,
          },
        ],
      };

      this.chartColumn7.setOption(option);
      this.chartColumn7.resize();
    },
    initChart7_1(val) {
      if (!val) {
        this.chartColumn7 = this.$echarts.init(this.$refs.chartColumn7);
      } else {
        this.chartColumn7 = this.$echarts.init(this.$refs.chartColumn7_dialog);
      }

      // this.chartColumn7 = this.$echarts.init(this.$refs.chartColumn7);
      this.chartColumn7.clear();

      let dataTmp = this.initChart67dataTmp;

      let ARR1 = Object.keys(dataTmp);
      let ARR2 = Object.values(dataTmp);

      let FINALLY = [];
      ARR1.forEach((node, idx) => {
        let obj = {
          name: node,
          value: ARR2[idx],
        };

        FINALLY.push(obj);
      });

      let colors = this.$store.state.colors;

      let texta = this.start_date_chart2;
      let textb = this.dButtons_separrate_current_chart6;

      let order = JSON.parse(localStorage.getItem("ORDER"));

      // 排序
      let TMP = [];
      order.forEach((item) => {
        let obj = FINALLY.filter((node) => {
          return node.name == item;
        });
        if (obj[0]) {
          TMP.push(obj[0]);
        }
      });

      FINALLY = TMP;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let option = {
        title: {
          // 标题设置
          text:
            "公募REITs-各支REITs及分类-" +
            texta +
            "以来期间平均" +
            textb +
            "占比分解", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          left: "center",
          top: 10,
        },
        grid: {
          top: 100,
          left: 50,
          right: 100,
          bottom: 30,
          // bottom:80,
          containLabel: true,
        },

        series: [
          {
            name: "Access From1",
            type: "pie",
            // selectedMode: 'single', //表示是否支持多个选中
            radius: [0, "70%"],
            itemStyle: {
              opacity: theme == "dark" ? 0.62 : lightOpacity,
              borderColor: "#fff",
              borderWidth: 1,

              color: function (val) {
                let name = val.name;

                let obj = colors.find((node) => {
                  return node.name == name;
                });

                return obj.value;

                return;
                let idx = val.dataIndex;
                return colorARR[6 + idx];
              },
            },
            label: {
              position: "outside",
              formatter: "{b}：{d}%",
              textStyle: {
                fontSize: 12,
                color: OPTIONDATA.title.textStyle.color,
              },
            },
            labelLine: {
              show: true,
              normal: {
                length: 15, // 指示线宽度
                lineStyle: {
                  color: "#fff", // 指示线颜色
                },
              },
            },
            data: FINALLY,
          },
          // {
          //   name: "Access From2",
          //   type: "pie",
          //   // selectedMode: 'single', //表示是否支持多个选中
          //   // radius: [0, '30%'],
          //   radius: ["25%", "38%"],
          //   itemStyle: {
          //     normal: {
          //       //  borderRadius: 3,
          //       borderColor: "#fff",
          //       borderWidth: 1,
          //       color: function (val) {
          //         let idx = val.dataIndex;
          //         return colorARR[idx];
          //       },
          //     },
          //   },
          //   label: {
          //     position: "inside",
          //     formatter: "{b}：{d}%",
          //     fontSize: 12,
          //     textStyle: {
          //       color: "#333",
          //     },
          //   },
          //   labelLine: {
          //     show: false,
          //   },
          //   data: nestedPies_L2,
          // },
          // {
          //   name: "Access From3",
          //   type: "pie",

          //   // selectedMode: 'single', //表示是否支持多个选中
          //   // radius: [0, '30%'],
          //   radius: ["38%", "65%"],
          //   itemStyle: {
          //     // borderRadius: 10,
          //     // borderColor: 'rgba(0, 0, 0, 0)',
          //     // borderWidth: 2
          //     normal: {
          //       // borderRadius: 3,
          //       borderColor: "#fff",
          //       borderWidth: 1,
          //       color: function (val) {
          //         let l2 = val.data.L2;
          //         let idx = 0;

          //         for (let i = 0; i < nestedPies_L2.length; i++) {
          //           if (nestedPies_L2[i].name == l2) {
          //             idx = i;
          //           }
          //         }

          //         return colorARR[idx];
          //       },
          //     },
          //   },
          //   label: {
          //     show: true,
          //     position: "outside",
          //     formatter: "{b}：{d}%",
          //     textStyle: {
          //       color: "#fff",
          //       fontSize: 12,
          //     },
          //   },
          //   labelLine: {
          //     show: true,
          //     normal: {
          //       length: 15, // 指示线宽度
          //       lineStyle: {
          //         color: "#fff", // 指示线颜色
          //       },
          //     },
          //   },

          //   data: nestedPies_L3,
          // },
        ],
      };

      this.chartColumn7.setOption(option);
      this.chartColumn7.resize();
    },

    //

    // 计算
    fnCount(arr, value) {
      let sum = 0;
      arr.forEach((node) => {
        if (node.REITs_type_L1 == value) {
          sum += Number(node.data);
        }
      });
      return sum;
    },
    fnCount2(arr, value) {
      let sum = 0;
      arr.forEach((node) => {
        if (node.REITs_type_L2 == value) {
          sum += Number(node.data);
        }
      });
      return sum;
    },

    // 排序算法
    fnSort(array) {
      // 进行L2分类
      let DATATMP = [];
      array.forEach((node) => {
        if (node.REITs_type_L1 == "产权类") {
          DATATMP.push(node);
        }
      });

      array.forEach((node) => {
        if (node.REITs_type_L1 == "经营权类") {
          DATATMP.push(node);
        }
      });

      array = DATATMP;

      let arr2 = array.map((node) => {
        return node.REITs_type_L2;
      });

      arr2 = [...new Set(arr2)];

      let arr3 = [];
      arr2.forEach((node) => {
        let array2 = [];
        array.forEach((e) => {
          if (e.REITs_type_L2 == node) {
            array2.push(e);
          }
        });

        function compare(val1, val2) {
          return val1.data - val2.data;
        }
        array2.sort(compare);

        arr3.push(array2);
      });

      arr3 = arr3.flat();

      return arr3;
    },

    // 排序算法 L3
    fnSort_L3(array, name) {
      let MAP_L2DATA = JSON.parse(localStorage.getItem("MAP_L2"));
      let MAP_L2 = {};
      MAP_L2DATA.map((node) => {
        MAP_L2[node.name] = node.value;
      });

      let CODELIST = JSON.parse(localStorage.getItem("CODELIST"));
      let MAP = {};
      CODELIST.map((node) => {
        MAP[node.reitCode] = node.reitsName;
      });

      //  1. 给每项添加L2分类
      let arr = array.map((node) => {
        for (let i in MAP) {
          if (MAP[i] == node.name) {
            node.L2 = MAP_L2[i];
          }
        }
        return node;
      });

      // 完成，漂亮！！！
      // 2. 进行分类
      let NEWARR = [];
      name.forEach((node) => {
        let arr2 = [];
        arr.forEach((e) => {
          if (e.L2 == node) {
            arr2.push(e);
          }
        });

        // 3. 排序
        function compare(val1, val2) {
          return val1.value - val2.value;
        }
        arr2.sort(compare);

        NEWARR.push(arr2);
      });

      NEWARR = NEWARR.flat();

      // 漂亮 ！

      // 3. 排序
      //  function compare(val1,val2){
      //            return val1.value-val2.value;
      //     };
      // NEWARR.sort(compare)

      return NEWARR;
    },

    // 分类排序算法 L2
    fnSortCate_L2(array) {
      let obj1 = {
        name: "产权类",
        data: ["物流仓储", "产业园", "保障性住房", "消费"],
      };

      let obj2 = {
        name: "经营权类",
        data: ["市政生态", "能源", "收费公路"],
      };

      let NEWARR = [];
      let CQ = [];
      let JYQ = [];

      obj1.data.forEach((node) => {
        array.forEach((e) => {
          if (node == e.name) {
            CQ.push(e);
          }
        });
      });

      obj2.data.forEach((node) => {
        array.forEach((e) => {
          if (node == e.name) {
            JYQ.push(e);
          }
        });
      });

      function compare(val1, val2) {
        return val1.value - val2.value;
      }
      CQ.sort(compare);

      function compare(val1, val2) {
        return val1.value - val2.value;
      }
      JYQ.sort(compare);

      NEWARR = [...CQ, ...JYQ];

      // function compare(val1,val2){
      //            return val1.value-val2.value;
      //  };
      //  NEWARR.sort(compare)
      return NEWARR;
    },

    // 分类排序占比 L2
    fnSortCate_L2_Proportion(array) {
      let obj1 = {
        name: "产权类",
        data: ["物流仓储", "产业园", "保障性住房", "消费"],
      };

      let obj2 = {
        name: "经营权类",
        data: ["市政生态", "能源", "收费公路"],
      };

      let NEWARR = [];
      let CQ = [];
      let JYQ = [];

      obj1.data.forEach((node) => {
        array.forEach((e) => {
          if (node == e.name) {
            CQ.push(e);
          }
        });
      });

      obj2.data.forEach((node) => {
        array.forEach((e) => {
          if (node == e.name) {
            JYQ.push(e);
          }
        });
      });

      let CQ_sum = 0;
      let JYQ_sum = 0;
      let TOTAL_sum = 0;

      CQ.forEach((node) => {
        CQ_sum += node.value;
      });

      JYQ.forEach((node) => {
        JYQ_sum += node.value;
      });

      array.forEach((node) => {
        TOTAL_sum += node.value;
      });

      let val1 = Number(CQ_sum) / Number(TOTAL_sum);
      val1 = Number(val1).toFixed(2);

      let val2 = Number(JYQ_sum) / Number(TOTAL_sum);
      val2 = Number(val2).toFixed(2);

      let ProportionArray = [val1, val2];

      return ProportionArray;
    },
  },
  components: {
    CSelects,
    CSelect,
    CButtonsSeparate,
    CTimeButtons,
    CCascader,
    CCategoryButton,
    CButtonsDownload,
    CDialog,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetTheme();
      fnDownloadBgc();

      this.$nextTick(() => {
        if (this.bMarketValue) {
          this.initChart1();
          // this.initChart6();
          // this.initChart7();
          if (
            this.dButtons_separrate_current_category_chart6 == "大类" ||
            this.dButtons_separrate_current_category_chart6 == "细分"
          ) {
            this.initChart6_1();
          } else {
            this.initChart6();
          }

          if (this.dButtons_separrate_current_category_chart6 == "单支REITs") {
            this.initChart7();
          } else {
            this.initChart7_1();
          }
        }

        if (this.bMobility) {
          this.initChart4();
          this.fnUpdateSwitch(true);
          this.loading = true;
          setTimeout(() => {
            this.$nextTick(() => {
              this.initChart5ChangeColor();
            });
          }, 500);
          // this.initChart5();
        }

        // this.initChart6();
        // this.initChart5();
        // this.initChart();
        // this.initChart2();
        // this.initChart3();
        // this.initChart4();
        // this.initChart5();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  // padding-bottom: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.main {
  background-color: var(--charts_bgc);
  padding-top: 15px;
  border-radius: 20px;
  margin-top: 30px;
  height: 654px;
  height: auto;
  padding-bottom: 20px;
}

.CTABLE {
  height: auto;
}

// 切换按钮  start --------------------------------------------
.chooseArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chooseArea .el-button {
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  // margin-left: 10px;
}

.chooseArea .noActive {
  // background-color: var(--Select-bgc);
  // background-color: red;
  // color: var(--Select-color2);
  // border: 0.98px solid rgba(255, 255, 255, 0.2);

  background-color: var(--Btn-bgc2);
  color: var(--stock_area_item_color1);
  border: 1px solid var(--Btn-borderColor);
}

.checkedBtnStyle {
  // background-color: #1573fe !important;
  background-color: var(--primary-color) !important;
  // color: #ffffff !important;
  color: var(--Btn-color) !important;
  // border: 0.98px solid #1573fe !important;
  border: 1px solid var(--primary-color) !important;
}

.chooseArea .el-button:hover {
  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2);
  border: 1px solid var(--primary-color) !important;
}

// 切换按钮  end --------------------------------------------

.title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  color: var(--chart_title);
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: 5px solid var(--primary-color);
  padding-left: 13px;
  padding-right: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 15px;
}

.main_choose {
  padding-left: 18px;
  margin-top: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.CTimeButtons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.main_choose_center {
  display: flex;
  justify-content: center;
}

.main_choose span {
  margin-right: 50px;
  display: flex;
  align-items: center;
  color: var(--chart_title);
  font-size: 14px;
}

.btnArea {
  margin-right: 40px;
}

.colorCategory {
  margin-left: 39px;
}

.summary {
  margin-left: 120px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  width: 52px;
  display: flex;
  justify-content: space-between;
}

.average {
  margin-left: 120px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  width: 92px;
  display: flex;
  justify-content: space-between;
}

// .width20{
//   width: 27%;
// }

// .mobility span:nth-child(1){
//   margin-left: 0px ;
// }

.category {
  margin-left: 50px;
}

.content_choose_area {
  display: flex;
  align-items: center;
  padding: 0px 28px;
  margin-top: 20px;
}

.summary_style {
  color: var(--cascader-color);
  font-size: 14px;
  margin-left: 50px;
}

.content_choose_area_item {
  flex: 1;
  position: relative;
  padding-top: 20px;
}

.content_choose_area_item:nth-child(1) {
  margin-right: 20px;
}

.color_pier {
  position: absolute;
  top: 10%;
  right: 2%;
}

.color_pier_tit {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.51px;
  line-height: 20.58px;
  color: rgba(158, 158, 158, 1);
}

.color_pier_main {
  width: 24px;
  height: 350px;
  float: right;
  margin-top: 20px;
  // margin: 20px auto;
  background: linear-gradient(
    to top,
    #f5fbfc,
    #e9f7fa,
    #c4e9e2,
    #99d8c9,
    #7bcbb3,
    #51b78a,
    #248c46,
    #006328
  );
  border-radius: 5px;
}

.roseCard {
  // padding-bottom: 30px !important;
}

.content_area {
  padding: 20px 20px 0px;
}

.CLASSB_STYLE {
  margin-left: 17px;
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: #1d1f25;
  background-color: var(--table_bgc);
  padding-bottom: 70px !important;
}

::v-deep .el-table::before {
  // background-color: #1d1f25;
  background-color: var(--table_bgc);
}

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body-wrapper {
  height: auto !important;
  max-height: 100% !important;
  // padding-bottom: 10px !important;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  border-bottom: 1px solid var(--table_brc);
  height: 60px;
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

// el-table end

.content {
  margin: 0 20px;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: 10px;
  position: relative;
  // margin-top: 30px;
}

.icon-fangda {
  // right: 6% !important;
}

.icon-fangd3 {
  right: 90px !important;
}

// echarts

.info {
  margin-left: 26px;
  margin-bottom: 14px;
}

.info div {
}

.DDL_info {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.51px;
  line-height: 20.58px;
  color: rgba(158, 158, 158, 1);
  padding-bottom: 15px;
  padding-left: 10px;
}

.value_area {
  display: flex;
  align-items: center;
  justify-content: start;
}

.a {
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
  background-color: rgba(90, 232, 237, 1);
}

.b {
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
  background-color: rgba(90, 232, 237, 1);
}

.c {
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
  background-color: rgba(59, 79, 207, 1);
}

.d {
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
  background-color: rgba(255, 51, 0, 1);
}

// 数据截止日期
.deadline {
  padding-left: 20px;
  padding-bottom: 20px;
}

.deadline2 {
  position: absolute;
  bottom: 0;
  color: #f8b077;

  // left: 20px;
}

.plotly .legend {
  margin-top: -20px; /* 调整图例距离顶部的距离 */
}
</style>  
