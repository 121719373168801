<template>
  <div class="container">
    <slot></slot>
    <el-select
      :collapse-tags="collapse"
      :popper-append-to-body="false"
      :multiple-limit="limit"
      popper-class="last-check"
      :style="cssVars"
      :disabled="disabled"
      :class="{ last: isLast }"
      @change="handleChange"
      @focus="fnFocus"
      @remove-tag="fnRemove"
      v-model="value"
      :multiple="multiple"
      :clearable="clearable"
      style="width:100%"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>

import {fnJudgePeriodRational,fnJudgePeriodOrder} from "@/utils/util";


export default {
  // 从父组件传值的
  props: {
    options: {
      type: Array,
      default: [],
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: Boolean,
      default: false,
    },
    middle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    default: {
      type: [String, Array],
      default: "",
    },
    limit: {
      type: Number,
      default: 3,
    }
  },
  methods: {
    handleChange(val) {

      if (val instanceof Array) {
        val.unshift(val.pop());
      }
      if (this.isDelete && val.length == 1) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }

      this.$emit("CSelect_select_val", val);
    },
    fnRemove(e) {},
    fnFocus(e) {},
  },
  components: {},
  computed: {
    cssVars() {
      return {
        "--val3": this.val3,
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
  },
  created() {
    // this.handleChange(this.value)
    this.ROOTFONTFIZE = window.sessionStorage.getItem("ROOTFONTFIZE");

    // console.log(this.default,'AAAAAAAAAAAAAAAAAA');
    // this.value = this.default.reverse()
  },
  data() {
    return {
      value: this.default,
      // value: this.default instanceof Array?this.default.reverse():this.default,
      isLast: false,
      val3: this.wide ? "380px" : this.mini ? "130px" : this.auto ? "auto": this.middle ? "240px":"300px",
      ROOTFONTFIZE: "",
    };
  },
};
</script>

<style scoped>
.container {
  /* font-size: calc(var(--ROOTFONTFIZE) * (14 / 144)); */
  /* width: 400px !important; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--chart_title) !important;
  padding-bottom: 0 !important;

}



.el-select {
  margin-left: 20px;
  height: 35px;
  /* line-height: 35px; */
  width: auto !important;
  width: var(--val3) !important;
  /* width: auto;
  height: auto; */
  /* width: auto !important; */
  /* display: inline-block !important; */
  border-radius: 3px;
  box-sizing: border-box !important;
  background-color: #29292c;
  background-color: var(--Btn-bgc);
  padding: 3px 10px;
  display: flex !important;
  align-items: center !important;
  /* margin-left: calc(var(--ROOTFONTFIZE) * (17 / 144));
  height: calc(var(--ROOTFONTFIZE) * (38 / 144));
  width: auto;
  height: auto;
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(49, 53, 63, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  box-sizing: border-box;
  background-color:  #29292c;
  padding: calc(var(--ROOTFONTFIZE) * (3 / 144)) calc(var(--ROOTFONTFIZE) * (10 / 144));
  display: flex !important;
  align-items: center !important; */
}

::v-deep .el-select__tags-text {
  display: flex !important;
  align-items: center !important;
}

::v-deep .el-select .el-input .el-select__caret {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transform:rotate(180deg) !important;

}

::v-deep .el-select .el-input .el-select__caret.is-reverse{
  transform: rotate(0deg) !important;
}



::v-deep .el-select .el-input__inner {
  /* width: 100px !important; */
  /* width: var(--val3) !important; */
  /* width: auto !important; */
  /* width: 100% !important; */
  /* width: var(--val3) !important; */
  background-color: rgba(0, 0, 255, 0) !important;
  border: none;
  /* padding-right: calc(var(--ROOTFONTFIZE) * (10 / 144))  !important; */
  margin: 0 !important;
  /* height: 32px; */
  /* line-height: 32px; */
  /* padding: calc(var(--ROOTFONTFIZE) * (5 / 144)) calc(var(--ROOTFONTFIZE) * (10 / 144)); */
  padding: 5px 0px;
  /* font-size: calc(var(--ROOTFONTFIZE) * (14 / 144)); */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--Select-color2);
  display: flex !important;
  align-items: center !important;
}

::v-deep .el-tag.el-tag--info {
  background-color: var(--Select-bgc) !important;
  background-color: #fff;
  color: var(--Btn-color2) !important;
  border: none !important;
  font-weight: normal !important;
  width: auto !important;
}

::v-deep .el-tag.el-tag--info:nth-child(1) {
  margin-left: 0 !important;
}

::v-deep .el-select-dropdown {
  background-color: var(--Select-bgc2) !important;
  border: none !important;
}



::v-deep .el-select-dropdown__item {
  background-color: var(--Select-bgc2) !important;
  color: var(--Select-color) !important;
}

::v-deep .el-select-dropdown__item.selected {
  /* background-color: #373739 !important; */
  color: var(--primary-color) !important;
}


::v-deep .el-select .el-tag__close.el-icon-close {
  background-color: #232324 !important;
  color: #fff !important;
}

::v-deep .last.el-select .el-tag__close.el-icon-close {
  display: none;
}


/* ::v-deep .selectMenuOption.el-popper[x-placement^=bottom] .popper__arrow::after  {
  border-bottom-color: #373739 !important;
  border-style: none !important;
} */
</style>
