<template>
  <div class="container" id="rootchart" :style="cssVars">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="概览" name="first">
        <div class="card">
          <div class="card_tit2">
            <!-- 产业园 2023Q1公募REITS各部分yield传递规律构成 -->

            <div v-show="bJYQLCARD">
              <el-button
                @click="fnFirstCategory()"
                class="backBtn_style"
                size="small"
                >返回上一级</el-button
              >
            </div>

            <div v-show="bCQLCARD || CQL_more">
              <el-button
                @click="fnFirstCategory()"
                class="backBtn_style"
                size="small"
                >返回上一级</el-button
              >
            </div>

            <!-- <div v-show="bCQLCARD">
              <el-button
               
                class="backBtn_style"
                size="small"
                >更多</el-button
              >
            </div> -->
          </div>
          <transition name="fade" mode="out-in">
            <div v-show="bMAINCARD" class="main">
              <div class="main_item">
                <div class="main_item_top">
                  <div>公募REITs</div>
                  <div>
                    分派率：<span>{{
                      Number(
                        dValuationSnapshot1_1_YR[
                          dValuationSnapshot1_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>
              <div class="main_item">
                <div class="main_item_top">
                  <div>产权类REITs</div>
                  <div>
                    分派率：<span class="CQL_color">{{
                      Number(
                        dValuationSnapshot2_1_YR[
                          dValuationSnapshot2_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn2_1"
                  style="width: 100%; height: 230px"
                ></div>
                <div
                  @click="fnSecondCategory('产权类')"
                  class="main_item_bottom"
                >
                  >> 二级类别
                </div>
              </div>
              <div class="main_item">
                <div class="main_item_top">
                  <div>经营权类REITs</div>
                  <div>
                    分派率：<span class="JYQL_color">{{
                      Number(
                        dValuationSnapshot3_1_YR[
                          dValuationSnapshot3_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn3_1"
                  style="width: 100%; height: 230px"
                ></div>
                <div
                  @click="fnSecondCategory('经营权类')"
                  class="main_item_bottom"
                >
                  >> 二级类别
                </div>
              </div>

              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>

              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade" mode="out-in">
            <div v-show="bCQLCARD" class="main hasMore">
              <div class="main_item">
                <div class="main_item_top">
                  <div>物流仓储</div>
                  <div>
                    分派率：<span class="WLCH_color">{{
                      Number(
                        dValuationSnapshot2_1_1_YR[
                          dValuationSnapshot2_1_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn2_1_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>
              <div class="main_item">
                <div class="main_item_top">
                  <div>产业园</div>
                  <div>
                    分派率：<span class="CYY_color">{{
                      Number(
                        dValuationSnapshot2_1_YR[
                          dValuationSnapshot2_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn2_2_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>
              <div class="main_item">
                <div class="main_item_top">
                  <div>保障性住房</div>
                  <div>
                    分派率：<span class="BZXZF_color">{{
                      Number(
                        dValuationSnapshot2_3_1_YR[
                          dValuationSnapshot2_3_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn2_3_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>

              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_1_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_1_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_2_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_2_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_3_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_3_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
              <div class="btnMo">
                <i
                  class="OVERVIEWICONSTYLE el-icon-arrow-right"
                  @click="fnMore()"
                ></i>
              </div>
            </div>
          </transition>

          <transition name="slide-fade">
            <div v-show="CQL_more" class="main specialMain hasMore">
              <div class="main_item">
                <div class="main_item_top">
                  <div>消费</div>
                  <div>
                    分派率：<span class="XF_color">{{
                      Number(
                        dValuationSnapshot2_4_1_YR[
                          dValuationSnapshot2_4_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn2_4_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>

              <div class="main_item h400">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_4_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn2_4_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>

              <div class="leftBtn">
                <i
                  class="OVERVIEWICONSTYLE el-icon-arrow-left"
                  @click="fnGoRight()"
                ></i>
              </div>
            </div>
          </transition>

          <!-- <div class="main">

              <div class="main_item ">
              <div class="main_item_top">
                <div>物流仓储</div>
                <div>
                  分派率：<span>{{
                    Number(
                      dValuationSnapshot2_1_1_YR[
                        dValuationSnapshot2_1_1_YR.length - 1
                      ]
                    ).toFixed(1) + "%"
                  }}</span>
                </div>
              </div>
              <div
                class="canvasArea"
                ref="chartColumn2_1_1"
                style="width: 100%; height: 230px"
              ></div>
              </div>

              <div class="main_item main_item_nothing"></div>

              <div class="main_item main_item_nothing"></div>
              

               <div class="main_item ">
              <div class="main_item_tit">估值</div>
              <div class="main_item_first">
                <div
                  class="canvasArea"
                  ref="chartColumn2_1_2"
                  style="width: 100%; height: 160px"
                ></div>
              </div>
              <div class="main_item_second">
                <div
                  class="canvasArea"
                  ref="chartColumn2_1_3"
                  style="width: 100%; height: 160px"
                ></div>
              </div>
               </div>

        
          </div> -->

          <transition name="fade" mode="out-in">
            <div v-show="bJYQLCARD" class="main">
              <div class="main_item">
                <div class="main_item_top">
                  <div>市政生态</div>
                  <div>
                    分派率：<span class="SZST_color">{{
                      Number(
                        dValuationSnapshot3_1_1_YR[
                          dValuationSnapshot3_1_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn3_1_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>
              <div class="main_item">
                <div class="main_item_top">
                  <div>能源</div>
                  <div>
                    分派率：<span class="NY_color">{{
                      Number(
                        dValuationSnapshot3_2_1_YR[
                          dValuationSnapshot3_2_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn3_2_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>
              <div class="main_item">
                <div class="main_item_top">
                  <div>收费公路</div>
                  <div>
                    分派率：<span class="SFGL_color">{{
                      Number(
                        dValuationSnapshot3_3_1_YR[
                          dValuationSnapshot3_3_1_YR.length - 1
                        ]
                      ).toFixed(1) + "%"
                    }}</span>
                  </div>
                </div>
                <div
                  class="canvasArea"
                  ref="chartColumn3_3_1"
                  style="width: 100%; height: 230px"
                ></div>
              </div>
              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_1_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_1_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_2_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_2_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
              <div class="main_item">
                <div class="main_item_tit">估值位数</div>
                <div class="main_item_first">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_3_2"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
                <div class="main_item_tit">百分位数</div>
                <div class="main_item_second">
                  <div
                    class="canvasArea"
                    ref="chartColumn3_3_3"
                    style="width: 100%; height: 160px"
                  ></div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </el-tab-pane>
      <el-tab-pane label="动态估值" name="second">
        <div class="card">
          <div class="card_top">
            <span>
              <CCascader
                ref="CCascader_ref_1"
                :default="category_cascader_valuationTable_val"
                :checkStrictly="true"
                class="mr20"
                :collapsetags="true"
                @CCascader_select_val="fnCCascader_select_val"
                :options="category_cascader_valuationTable"
                >选择类别</CCascader
              >

              <CSelect
                @CSelect_select_val="fnCSelect_select_val"
                :clearable="false"
                :multiple="true"
                :limit="7"
                :middle="true"
                class="mr20"
                :default="category_select_valuationTable_val"
                :options="category_options_valuationTable"
                >选择估值指标</CSelect
              >

              <span class="summary_style">
                汇总
                <el-checkbox
                  @change="fnGetValuationTable"
                  v-model="summaryChecked_DynamicValuation"
                ></el-checkbox>
              </span>
            </span>

            <span>
              <div class="downloadBtns">
                <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads1"
                  @fnButtondownload="fnButtonDL7_1"
                />

                <span style="display: none">
                  <download-excel
                    id="downloadExcel7_1"
                    :data="dValuationTable"
                    :fields="json_fields"
                    :header="title"
                    name="动态估值.xls"
                  >
                  </download-excel>
                </span>
              </div>
            </span>
          </div>
          <div class="card_bottom">
            <el-table
              :height="OFFSETHEIGHT - 255"
              class="table1"
              :header-cell-style="styleBindFun"
              :cell-style="fnCell"
              @cell-click="fnClick"
              :data="dValuationTable"
              style="width: 100%"
            >
              <el-table-column :width="eltableWidth3" prop="代码" label="代码">
              </el-table-column>
              <el-table-column :width="eltableWidth2" prop="名称" label="名称">
              </el-table-column>
              <el-table-column :width="eltableWidth3" prop="大类" label="大类">
              </el-table-column>
              <el-table-column :width="eltableWidth2" prop="类型" label="类型">
              </el-table-column>

              <el-table-column
                align="left"
                label="PB"
                sortable
                v-if="bPB"
                prop="PB_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PB !== 'NaNpx'
                            ? scope.row.node_PB
                            : '',
                      }"
                      class="a"
                    ></div>
                    {{
                      Number(scope.row.PB_ttm) > 10
                        ? Number(scope.row.PB_ttm).toFixed(1) + "x"
                        : Number(scope.row.PB_ttm).toFixed(2) + "x" | isZero
                    }}               
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="PNAV"
                sortable
                v-if="bPNAV"
                prop="PNAV_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PNAV !== 'NaNpx'
                            ? scope.row.node_PNAV
                            : '',
                      }"
                      class="b"
                    ></div>
                    {{
                      Number(scope.row.PNAV_ttm) > 10
                        ? Number(scope.row.PNAV_ttm).toFixed(1) + "x"
                        : (Number(scope.row.PNAV_ttm).toFixed(2) + "x") | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="PFFO"
                sortable
                v-if="bPFFO"
                prop="PFFO_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PFFO !== 'NaNpx'
                            ? scope.row.node_PFFO
                            : '',
                      }"
                      class="c"
                    ></div>
                    {{
                      Number(scope.row.PFFO_ttm) > 10
                        ? Number(scope.row.PFFO_ttm).toFixed(1) + "x"
                        : (Number(scope.row.PFFO_ttm).toFixed(2) + "x") | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="PCFO"
                sortable
                v-if="bPCFO"
                prop="PCFO_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PCFO !== 'NaNpx'
                            ? scope.row.node_PCFO
                            : '',
                      }"
                      class="d"
                    ></div>
                    {{
                      Number(scope.row.PCFO_ttm) > 10
                        ? Number(scope.row.PCFO_ttm).toFixed(1) + "x"
                        : (Number(scope.row.PCFO_ttm).toFixed(2) + "x") | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="EV/EBITDA"
                sortable
                v-if="bEVEBITDA"
                prop="EV/EBITDA_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_EVEBITDA !== 'NaNpx'
                            ? scope.row.node_EVEBITDA
                            : '',
                      }"
                      class="e"
                    ></div>
                    {{
                      Number(scope.row["EV/EBITDA_ttm"]) > 10
                        ? Number(scope.row["EV/EBITDA_ttm"]).toFixed(1) + "x"
                        : (Number(scope.row["EV/EBITDA_ttm"]).toFixed(2) + "x")
                          | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column align="left" label="Pdiv" v-if="bPDIV">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{ width: scope.row.node_PDIV }"
                      class="e"
                    ></div>
                    {{
                      Number(scope.row["pdiv_ttm"]) > 10
                        ? Number(scope.row["pdiv_ttm"]).toFixed(1) + "x"
                        : Number(scope.row["pdiv_ttm"]).toFixed(2) + "x" | isZero
                    }}
                  </div>
                </template>
              </el-table-column> -->

              <el-table-column
                align="left"
                label="分红回报率/（TTM口径）"
                :render-header="renderHeader"
                v-if="bDIVYIELD"
                sortable
                prop="div_yield_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_DIVYIELD !== 'NaNpx'
                            ? scope.row.node_DIVYIELD
                            : '',
                      }"
                      class="e"
                    ></div>
                    <!-- {{
                      Number(scope.row["div_yield_ttm"]) > 10
                        ? Number(scope.row["div_yield_ttm"]).toFixed(1) + "x"
                        : Number(scope.row["div_yield_ttm"]).toFixed(2) + "x"
                    }} -->
                    {{
                      ((Number(scope.row["div_yield_ttm"]) * 100).toFixed(2) +
                        "%")
                        | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column
                 align="center"
                  label="目前分派率">
                   <template slot-scope="scope">
                            {{(scope.row['分红回报率'] / 10000).toFixed(1)+"万"}} 
                    </template>  
                </el-table-column>  -->
            </el-table>
          </div>

          <div ref="table7_1" class="card_bottom downloadTable">
            <el-table
              class="table1"
              :header-cell-style="styleBindFun"
              :cell-style="fnCell"
              @cell-click="fnClick"
              :data="dValuationTable"
              style="width: 100%"
            >
              <el-table-column :width="eltableWidth3" prop="代码" label="代码">
              </el-table-column>
              <el-table-column :width="eltableWidth2" prop="名称" label="名称">
              </el-table-column>
              <el-table-column :width="eltableWidth3" prop="大类" label="大类">
              </el-table-column>
              <el-table-column :width="eltableWidth2" prop="类型" label="类型">
              </el-table-column>

              <el-table-column
                align="left"
                label="PB"
                sortable
                v-if="bPB"
                prop="PB_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PB !== 'NaNpx'
                            ? scope.row.node_PB
                            : '',
                      }"
                      class="a"
                    ></div>
                    {{
                      Number(scope.row.PB_ttm) > 10
                        ? Number(scope.row.PB_ttm).toFixed(1) + "x"
                        : Number(scope.row.PB_ttm).toFixed(2) + "x" | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="PNAV"
                sortable
                v-if="bPNAV"
                prop="PNAV_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PNAV !== 'NaNpx'
                            ? scope.row.node_PNAV
                            : '',
                      }"
                      class="b"
                    ></div>
                    {{
                      Number(scope.row.PNAV_ttm) > 10
                        ? Number(scope.row.PNAV_ttm).toFixed(1) + "x"
                        : (Number(scope.row.PNAV_ttm).toFixed(2) + "x") | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="PFFO"
                sortable
                v-if="bPFFO"
                prop="PFFO_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PFFO !== 'NaNpx'
                            ? scope.row.node_PFFO
                            : '',
                      }"
                      class="c"
                    ></div>
                    {{
                      Number(scope.row.PFFO_ttm) > 10
                        ? Number(scope.row.PFFO_ttm).toFixed(1) + "x"
                        : (Number(scope.row.PFFO_ttm).toFixed(2) + "x") | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="PCFO"
                sortable
                v-if="bPCFO"
                prop="PCFO_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_PCFO !== 'NaNpx'
                            ? scope.row.node_PCFO
                            : '',
                      }"
                      class="d"
                    ></div>
                    {{
                      Number(scope.row.PCFO_ttm) > 10
                        ? Number(scope.row.PCFO_ttm).toFixed(1) + "x"
                        : (Number(scope.row.PCFO_ttm).toFixed(2) + "x") | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="left"
                label="EV/EBITDA"
                sortable
                v-if="bEVEBITDA"
                prop="EV/EBITDA_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_EVEBITDA !== 'NaNpx'
                            ? scope.row.node_EVEBITDA
                            : '',
                      }"
                      class="e"
                    ></div>
                    {{
                      Number(scope.row["EV/EBITDA_ttm"]) > 10
                        ? Number(scope.row["EV/EBITDA_ttm"]).toFixed(1) + "x"
                        : (Number(scope.row["EV/EBITDA_ttm"]).toFixed(2) + "x")
                          | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column align="left" label="Pdiv" v-if="bPDIV">
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{ width: scope.row.node_PDIV }"
                      class="e"
                    ></div>
                    {{
                      Number(scope.row["pdiv_ttm"]) > 10
                        ? Number(scope.row["pdiv_ttm"]).toFixed(1) + "x"
                        : Number(scope.row["pdiv_ttm"]).toFixed(2) + "x" | isZero
                    }}
                  </div>
                </template>
              </el-table-column> -->

              <el-table-column
                align="left"
                label="分红回报率/（TTM口径）"
                :render-header="renderHeader"
                v-if="bDIVYIELD"
                sortable
                prop="div_yield_ttm"
              >
                <template slot-scope="scope">
                  <div class="value_area">
                    <div
                      :style="{
                        width:
                          scope.row.node_DIVYIELD !== 'NaNpx'
                            ? scope.row.node_DIVYIELD
                            : '',
                      }"
                      class="e"
                    ></div>
                    <!-- {{
                      Number(scope.row["div_yield_ttm"]) > 10
                        ? Number(scope.row["div_yield_ttm"]).toFixed(1) + "x"
                        : Number(scope.row["div_yield_ttm"]).toFixed(2) + "x"
                    }} -->
                    {{
                      ((Number(scope.row["div_yield_ttm"]) * 100).toFixed(2) +
                        "%")
                        | isZero
                    }}
                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column
                 align="center"
                  label="目前分派率">
                   <template slot-scope="scope">
                            {{(scope.row['分红回报率'] / 10000).toFixed(1)+"万"}} 
                    </template>  
                </el-table-column>  -->
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史估值" name="third">
        <div class="card">
          <div class="card_tit HistoricalValuation">
            <span>历史数据</span>

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads2"
                @fnButtondownload="fnButtonDL7_2"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_2"
                  :data="json_fields_data1_data"
                  :fields="json_fields_data1"
                  header="历史数据"
                  name="历史数据.xls"
                >
                </download-excel>
              </span>

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_3"
                  :data="json_fields_pic1_lines_data"
                  :fields="json_fields_pic1_lines"
                  header="历史数据_参考线"
                  name="历史数据_参考线.xls"
                >
                </download-excel>
              </span>

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_4"
                  :data="json_fields_ipo_date_data"
                  :fields="json_fields_ipo_date"
                  header="历史数据_IPO数据"
                  name="历史数据_IPO数据.xls"
                >
                </download-excel>
              </span>
            </div>

            <!-- <span @click="FnDownload()"> <i class="el-icon-download"></i></span> -->
          </div>

          <div class="card_history_top">
            <CCascader
              class="mr20"
              @CCascader_select_val="fnCCascader_select_val_two"
              :default="r_type_chart4"
              :checkStrictly="true"
              :options="category_cascader_valuationTable"
              >选择REITs</CCascader
            >

            <CSelect
              class="mr20"
              :default="factor_chart4"
              :clearable="false"
              :auto="true"
              @CSelect_select_val="fnCSelect_select_val_two"
              :options="category_options_valuationTable"
              >选择估值指标</CSelect
            >

            <span>
              <CCategoryButton
                :default="show_mode_chart4"
                @CCategoryButton_select_val="CCategoryButton_select_val"
              >
                参考线
              </CCategoryButton>
            </span>
          </div>

          <div class="card_history_top">
            <span class="btnArea btnArea_center">
              <CTimeButtons
                :default="start_date_chart4"
                @CTimeButtons_select_val="fnCTimeButtons_select_val"
              />
            </span>
          </div>

          <div class="main historymain HistoricalValuationMain">
            <span
              @click="fnOpenDialog3"
              class="iconfont icon-fangda icon-fangda2"
            ></span>
            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn4"
              ref="chartColumn4"
              style="width: 100%; height: 500px"
            ></div>
          </div>

          <!-- <div class="card_history_top">
            <span class="btnArea">
              <CTimeButtons
                :default="start_date_chart4"
                @CTimeButtons_select_val="fnCTimeButtons_select_val_three"
              />
            </span>
          </div> -->

          <!-- 测试 -->
          <!-- <div class="main  scatterArea">
                      <div
                          class="canvasArea"
                          ref="chartColumn5"
                          style="width: 100%; height: 390px">
                      </div>        

                       <div
                          class="canvasArea"
                          ref="chartColumn8"
                          style="width: 1000px; height: 390px">
                      </div>         
                  </div> -->
          <!-- 测试 -->

          <div class="other_chart1_style">
            <div>
              <span class="other_chart1">
                <CotherButtons
                  :default="other_chart1"
                  @CotherButtons_select_val="CotherButtons_select_val"
                />
              </span>
            </div>
            <div class=""></div>
          </div>

          <div class="main scatterArea">
            <div
              class="canvasArea"
              ref="chartColumn5"
              style="width: 100%; height: 390px"
            ></div>

            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              ref="chartColumn6"
              style="width: 100%; height: 390px"
            ></div>
          </div>

          <div class="HistoricalValuation_info">
            <div>说明</div>
            <div>
              1.市盈率=成分股当日总市值总计/成分股净利润(TTM)总计，当成分股净利润(TTM)为负时，市盈率为0
            </div>
            <div>
              2.加权指标是指按照指数加权方式计算的指标，部分指数没有权重数据则没有相关指标
            </div>
            <div>
              3.分位点为将某一个数值在其所在的数组中的分布点，对于估值分布来说就是“当前估值在历史中出于什么样的位置”
            </div>
            <div>
              4.机会值、中位数以及危险值分别对应了20%、50%、80%三个分位点
            </div>
            <div>
              5.历史估值对应未来1年收益率的选取了过去10年内120个估值点以及每个估值点对应的未来1年的指数涨跌幅。用以描述估值高低与未来涨跌幅是否存在相关关系`
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="估值Bands" name="fourth">
        <div class="card" ref="chartArea7">
          <div class="card_tit">
            估值Bands

            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads2"
                @fnButtondownload="fnButtonDL7_3"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_61"
                  :data="DATA_chart7ba"
                  :fields="json_fields_column7"
                  header="估值Bands"
                  name="估值Bands.xls"
                >
                </download-excel>
              </span>
            </div>
          </div>
          <div class="card_history_top">
            <CCascader
              class="mr20"
              :checkStrictly="true"
              @CCascader_select_val="fnCCascader_select_val_three"
              :default="r_type_chart7"
              :options="category_cascader_valuationTable"
              >选择大类</CCascader
            >

            <CSelect
              @CSelect_select_val="fnCSelect_select_val_three"
              :default="valuation_chart7"
              :clearable="false"
              :auto="true"
              :options="category_options_valuationTable"
              >选择估值指标</CSelect
            >

            <CTimeButtons
              class="ml20"
              :default="start_date_LISHISHUJU"
              @CTimeButtons_select_val="fnCTimeButtons_select_val_LISHISHUJU"
            />
          </div>
          <!-- <div class="card_history_top pl">
            <CButtonsSeparate
              @Cbtns_sep="fnGetCbtnsSep_chart5"
              :dButtons1="dButtons_separrate_chart5"
              :dButtons1_current="dButtons_separrate_current_chart5"
            />
          </div> -->

          <div class="main historymain">
            <span
              @click="fnOpenDialog2"
              class="iconfont icon-fangda icon-fangda2"
            ></span>
            <div
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn7"
              ref="chartColumn7"
              style="width: 100%; height: 530px"
            ></div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="估值财报分析" name="fifth">
        <div class="card">
          <div class="card_tit">
            <!-- 公募 REITs 内含报酬率走势对比 -->
            {{ category_cascader_val_chart8 }} 内含报酬率走势 对比
            <div class="downloadBtns">
              <CButtonsDownload
                :dButtonsDownloads="dButtonsDownloads2"
                @fnButtondownload="fnButtonDL7_4"
              />

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_7"
                  :data="dCalIrr_down"
                  :fields="json_fields_column8"
                  :header="column8Title"
                  :name="column8Title"
                >
                </download-excel>
              </span>

              <span style="display: none">
                <download-excel
                  id="downloadExcel7_8"
                  :data="data_column8"
                  :fields="json_fields_column9"
                  :header="column9Title"
                  :name="column9Title"
                >
                </download-excel>
              </span>
            </div>
          </div>

          <div class="card_history_top" v-show="isVip">
            <div>
              <CCascader
                :default="category_cascader_val_chart8"
                :checkStrictly="true"
                :multiple="false"
                :collapsetags="true"
                @CCascader_select_val="fnCCascader_select_val_chart8"
                :options="category_cascader_valuationTable"
                >选择类别</CCascader
              >
            </div>
            <div class="abslouteChecked">
              <span class="abslouteChecked_span">显示10年国债Yield Spread</span>
              <el-checkbox
                :disabled="disabled_chart8"
                @change="fnGetCalIrr"
                v-model="summaryChecked"
              ></el-checkbox>
            </div>
            <div>
              <CTimeButtons
                :default="start_date_chart8"
                @CTimeButtons_select_val="fnCTimeButtons_select_val_chart8"
              />
            </div>

            <!-- <div class="NPVStyle">
                 <span>NPV</span>
                 <span>IRR</span>
            </div> -->
          </div>

          <div
            v-show="isVip"
            class="main historymain echarts_area display_none"
          >
            <span
              @click="fnOpenDialog5"
              class="iconfont icon-fangda icon-fangda2"
            ></span>
            <div
              v-loading="loading_chart8"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn8"
              ref="chartColumn8"
              style="width: 100%; height: 500px"
            ></div>
          </div>

          <div v-show="!isVip" class="noVip">
            <i class="iconfont icon-taocan-suoxiao"></i>
            抱歉，当前内容只能VIP客户查看
            <el-button size="small" class="openBtn">联系管理员开通</el-button>
          </div>

          <div class="card_tit">
            {{ chart9_title + " 对应个股内含报酬率走势 对比" }}
          </div>

          <div v-show="isVip" class="main historymain echarts_area">
            <span
              @click="fnOpenDialog"
              class="iconfont icon-fangda icon-fangda5"
            ></span>
            <div
              v-loading="loading_chart9"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              ref="chartColumn9"
              style="width: 100%; height: 500px"
            ></div>
            <div class="DDLINFO">数据截止日期：{{ DDL_DATE }}</div>
          </div>

          <div v-show="!isVip" class="noVip">
            <i class="iconfont icon-taocan-suoxiao"></i>

            抱歉，当前内容只能VIP客户查看
            <el-button size="small" class="openBtn">联系管理员开通</el-button>
          </div>
        </div>
      </el-tab-pane>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible"
      >
        <el-dialog
          :title="chart9_title + '对应个股内含报酬率走势对比'"
          :visible.sync="dialogVisible"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown2"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="historymain2">
            <div :style="{ height: height_chart }">
              <div
                class="canvasArea"
                ref="chartColumn9_dialog"
                style="width: 100%; height: 100%"
              ></div>
              <div class="DDLINFO">数据截止日期：{{ DDL_DATE }}</div>
            </div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible2"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible2"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div :style="{ height: height_chart }">
            <div
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn7"
              ref="chartColumn7"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible3"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible3"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown3"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              v-loading="loading_chart4"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn4"
              ref="chartColumn4"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </el-dialog>
      </CDialog>

      <CDialog
        ref="CDialog"
        :DIALOGHEIGHT="height_dialog"
        :dialogVisible="dialogVisible5"
      >
        <el-dialog
          title="葱花投研"
          :visible.sync="dialogVisible5"
          :width="width_dialog"
          :top="top_dialog"
        >
          <div>
            <el-dropdown
              size="mini"
              trigger="click"
              type="primary"
              @command="fnDropdown4"
            >
              <span class="el-dropdown-link">
                {{ popUpWindowDatas[popUpWindowIndex]
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="idx"
                  v-for="(node, idx) in popUpWindowDatas"
                  :key="idx"
                >
                  {{ node }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div :style="{ height: height_chart }">
            <div
              v-loading="loading_chart8"
              element-loading-text="数据量巨大，正在计算中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="canvasArea"
              id="chartColumn8"
              ref="chartColumn8"
              style="width: 100%; height: 100%"
            ></div>
          </div>
        </el-dialog>
      </CDialog>

      <!-- <el-tab-pane label="绝对估值" name="sixth">
        <div class="card">
          <div class="card_tit">公募 REITs 实际按照 LO 分类内含率走势对比</div>
          <div class="card_history_top">
            <CSelect :options="category_options">选择大类</CSelect>
            <span class="btnArea">
              时间范围：
              <el-button
                v-for="(item, index) in dBtn1"
                :key="index"
                class="normalBtn"
                :class="item == activeBtn1 ? 'isActiveStyle' : ''"
                size="mini"
                @click="fnChangeBtn1(item)"
                >{{ item }}</el-button
              >
            </span>
            <span class="summary abslouteChecked">
              显示10年国债Yield Spread
              <el-checkbox v-model="summaryChecked"></el-checkbox>
            </span>
          </div>
          <div class="abslouteMainUp">
            <div class="main abslouteMain">1</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="IPO买方估值" name="seventh"> </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import CSelect from "@/components/Basic/CSelect";
import CCategoryButton from "@/components/Basic/CCategoryButton";
import CCascader from "@/components/Basic/CCascader";
import CTimeButtons from "@/components/Basic/CTimeButtons";
import CotherButtons from "@/components/Basic/CotherButtons";
import CButtonsSeparate from "@/components/Basic/CButtons_separate";
import CButtonsDownload from "@/components/Basic/CButtons_download";

import CDialog from "@/components/Basic/CDialog";

import html2canvas from "html2canvas";

import ecStat from "echarts-stat";

import {
  fnGetLevelName,
  fnReturnTableStyle,
  fnGetMuchDecimals,
  fnDownloadBgc,
  hexToRgba,
  fnComputeTime,
  exportToExcel,
} from "@/utils/util";

export default {
  components: {},
  computed: {},
  created() {
    this.fnGetL1();
    let name = localStorage.getItem("activeName");
    this.handleClick({ name: name });
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener("resize", this.funResize);
  },
   beforeDestroy(){
     window.removeEventListener("resize", this.funResize);
     this.chartColumn1&&this.chartColumn1.dispose()
      this.chartColumn2&&this.chartColumn2.dispose()
      this.chartColumn3&&this.chartColumn3.dispose()
      this.chartColumn2_1&&this.chartColumn2_1.dispose()
      this.chartColumn2_2&&this.chartColumn2_2.dispose()
      this.chartColumn2_3&&this.chartColumn2_3.dispose()
      this.chartColumn3_1&&this.chartColumn3_1.dispose()
      this.chartColumn3_2&&this.chartColumn3_2.dispose()
      this.chartColumn3_3&&this.chartColumn3_3.dispose()
      this.chartColumn2_1_1&&this.chartColumn2_1_1.dispose()
      this.chartColumn2_1_2&&this.chartColumn2_1_2.dispose()
      this.chartColumn2_1_3&&this.chartColumn2_1_3.dispose()
      this.chartColumn2_2_3&&this.chartColumn2_2_3.dispose()
      this.chartColumn2_2_2&&this.chartColumn2_2_2.dispose()
      this.chartColumn2_2_1&&this.chartColumn2_2_1.dispose()
      this.chartColumn2_3_1&&this.chartColumn2_3_1.dispose()
      this.chartColumn2_3_2&&this.chartColumn2_3_2.dispose()
      this.chartColumn2_3_3&&this.chartColumn2_3_3.dispose()
      this.chartColumn2_4_1&&this.chartColumn2_4_1.dispose()
      this.chartColumn2_4_2&&this.chartColumn2_4_2.dispose()
      this.chartColumn2_4_3&&this.chartColumn2_4_3.dispose()
      this.chartColumn3_1_3&&this.chartColumn3_1_3.dispose()
      this.chartColumn3_1_2&&this.chartColumn3_1_2.dispose()
      this.chartColumn3_1_1&&this.chartColumn3_1_1.dispose()
      this.chartColumn3_2_1&&this.chartColumn3_2_1.dispose()
      this.chartColumn3_2_2&&this.chartColumn3_2_2.dispose()
      this.chartColumn3_2_3&&this.chartColumn3_2_3.dispose()
      this.chartColumn3_3_3&&this.chartColumn3_3_3.dispose()
      this.chartColumn3_3_2&&this.chartColumn3_3_2.dispose()
      this.chartColumn3_3_1&&this.chartColumn3_3_1.dispose()
      this.chartColumn4&&this.chartColumn4.dispose()
      this.chartColumn5&&this.chartColumn5.dispose()
      this.chartColumn6&&this.chartColumn6.dispose()
      this.chartColumn7&&this.chartColumn7.dispose()
      this.chartColumn8&&this.chartColumn8.dispose()
      this.chartColumn9&&this.chartColumn9.dispose()
  },
  data() {
    return {
      OFFSETHEIGHT: 0,
      TABPANEHEIGHT: 0,

      loading: false,
      loading_chart4: false,
      loading_chart8: false,
      loading_chart9: false,

      isVip: false,

      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,

      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "71vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      ROOTFONTFIZE: 0,
      eltableWidth: 80,
      eltableWidth2: 150,
      eltableWidth3: 100,

      value: "",
      chartColumn1: null,
      chartColumn2: null,
      chartColumn3: null,
      chartColumn2_1: null,
      chartColumn2_2: null,
      chartColumn2_3: null,
      chartColumn3_1: null,
      chartColumn3_2: null,
      chartColumn3_3: null,
      // 产权类子1
      chartColumn2_1_1: null,
      chartColumn2_1_2: null,
      chartColumn2_1_3: null,
      dValuationSnapshot2_1_1: [],
      dValuationSnapshot2_1_1_X: [],
      dValuationSnapshot2_1_1_YL: [],
      dValuationSnapshot2_1_1_YR: [],
      dValuationSnapshot2_1_2: [],
      dValuationSnapshot2_1_3: [],

      // 产权类子2
      chartColumn2_2_1: null,
      chartColumn2_2_2: null,
      chartColumn2_2_3: null,
      dValuationSnapshot2_2_1: [],
      dValuationSnapshot2_2_1_X: [],
      dValuationSnapshot2_2_1_YL: [],
      dValuationSnapshot2_2_1_YR: [],
      dValuationSnapshot2_2_2: [],
      dValuationSnapshot2_2_3: [],

      // 产权类子3
      chartColumn2_3_1: null,
      chartColumn2_3_2: null,
      chartColumn2_3_3: null,
      dValuationSnapshot2_3_1: [],
      dValuationSnapshot2_3_1_X: [],
      dValuationSnapshot2_3_1_YL: [],
      dValuationSnapshot2_3_1_YR: [],
      dValuationSnapshot2_3_2: [],
      dValuationSnapshot2_3_3: [],

      // 产权类子4
      chartColumn2_4_1: null,
      chartColumn2_4_2: null,
      chartColumn2_4_3: null,
      dValuationSnapshot2_4_1: [],
      dValuationSnapshot2_4_1_X: [],
      dValuationSnapshot2_4_1_YL: [],
      dValuationSnapshot2_4_1_YR: [],
      dValuationSnapshot2_4_2: [],
      dValuationSnapshot2_4_3: [],

      // 经营权类子1
      chartColumn3_1_1: null,
      chartColumn3_1_2: null,
      chartColumn3_1_3: null,
      dValuationSnapshot3_1_1: [],
      dValuationSnapshot3_1_1_X: [],
      dValuationSnapshot3_1_1_YL: [],
      dValuationSnapshot3_1_1_YR: [],
      dValuationSnapshot3_1_2: [],
      dValuationSnapshot3_1_3: [],

      // 经营权类子2
      chartColumn3_2_1: null,
      chartColumn3_2_2: null,
      chartColumn3_2_3: null,
      dValuationSnapshot3_2_1: [],
      dValuationSnapshot3_2_1_X: [],
      dValuationSnapshot3_2_1_YL: [],
      dValuationSnapshot3_2_1_YR: [],
      dValuationSnapshot3_2_2: [],
      dValuationSnapshot3_2_3: [],

      // 经营权类子3
      chartColumn3_3_1: null,
      chartColumn3_3_2: null,
      chartColumn3_3_3: null,
      dValuationSnapshot3_3_1: [],
      dValuationSnapshot3_3_1_X: [],
      dValuationSnapshot3_3_1_YL: [],
      dValuationSnapshot3_3_1_YR: [],
      dValuationSnapshot3_3_2: [],
      dValuationSnapshot3_3_3: [],

      bMAINCARD: true,
      bCQLCARD: false,
      bJYQLCARD: false,
      CQL_more: false,

      chartColumn4: null,
      chartColumn5: null,
      chart5_arr2: null,
      chartColumn6: null,
      initChart8_1_arr1: null,
      initChart8_1_lastday: null,

      chartColumn7: null,
      chartColumn8: null,
      chartColumn9: null,
      chartColumn9_dialog: null,

      dLastAssetValue: [],
      dRpDateAssetValue: [],

      activeName: "first",

      category_options: [],

      category_options_valuationTable: this.$store.state.valuationTable,

      category_cascader_valuationTable: JSON.parse(
        localStorage.getItem("CATEGORYDATA")
      ),
      category_cascader_valuationTable_L1: [],

      dBtn1: ["3M", "6M", "1Y", "YTD", "MAX"],
      activeBtn1: "3M",

      dValuationSnapshot1_1: [],
      dValuationSnapshot1_1_X: [],
      dValuationSnapshot1_1_YL: [],
      dValuationSnapshot1_1_YR: [],
      dValuationSnapshot1_2: [],
      dValuationSnapshot1_3: [],

      dValuationSnapshot2_1: [],
      dValuationSnapshot2_1_X: [],
      dValuationSnapshot2_1_YL: [],
      dValuationSnapshot2_1_YR: [],
      dValuationSnapshot2_2: [],
      dValuationSnapshot2_3: [],

      dValuationSnapshot3_1: [],
      dValuationSnapshot3_1_X: [],
      dValuationSnapshot3_1_YL: [],
      dValuationSnapshot3_1_YR: [],
      dValuationSnapshot3_2: [],
      dValuationSnapshot3_3: [],

      dValuationBandByType: [],
      dValuationBandByType_X: [],
      dValuationBandByType_97x: [],
      dValuationBandByType_0x: [],
      dValuationBandByType_11x: [],
      dValuationBandByType_19x: [],
      dValuationBandByType_23x: [],
      dValuationBandByType_current: [],

      // 表格
      dValuationTable: [],

      summaryChecked: false,
      summaryChecked_DynamicValuation: true,

      ReLine: ["分位数", "标准差"],
      activeBtn1: "分位数",

      dValuationAnalysisSingle: [],
      dValuationAnalysisSingle_X: [],
      dValuationAnalysisSingle_YL: [],
      dValuationAnalysisSingle_YR: [],
      dValuationAnalysisSingle_IPO: [],

      dValuationAnalysisSingle_pic1_lines: null,
      dhistogramData_X: [],
      dhistogramData_Y: [],

      last_red_line: "",
      histogram_last_val: "",

      // 表格1
      category_cascader_valuationTable_val: "公募REITs",
      category_select_valuationTable_val: [
        "PFFO",
        "PCFO",
        "PNAV",
        "PB",
        "EV/EBITDA",
        // "pdiv_ttm",
        // "div_yield",
        "div_yield_ttm",
      ],
      bPB: true,
      bPNAV: true,
      bPFFO: true,
      bPCFO: true,
      bEVEBITDA: true,
      bPDIV: true,
      bDIVYIELD: true,

      // chart7
      r_type_chart7: ["公募REITs"],
      valuation_chart7: "PB",
      start_date_LISHISHUJU: "MAX",
      DATA_chart7: [],
      DATA_chart7ba: [],

      // chart4
      factor_chart4: "PB",
      start_date_chart4: "YTD",
      show_mode_chart4: "std",
      r_type_chart4: ["公募REITs"],
      other_chart1: "1M",

      // chart5
      start_date_chart5: "3M",
      dButtons_separrate_chart5: ["平滑", "阶梯"],
      dButtons_separrate_current_chart5: "平滑",

      // chart8
      category_cascader_val_chart8: "公募REITs",
      start_date_chart8: "3M",
      DDL_DATE: "",
      disabled_chart8: false,
      color_Y: "",
      dCalIrr: [],
      dCalIrr_down: [],


      // chart9
      chart9_title: "",
      dCalIrrSingle: [],

      // 表格下载
      title: "动态估值",
      json_fields: {},

      json_fields_data1: {},
      json_fields_data1_data: [],

      json_fields_pic1_lines: {},
      json_fields_pic1_lines_data: [],

      json_fields_ipo_date: {},
      json_fields_ipo_date_data: [],

      dButtonsDownloads1: [
        {
          id: "241047",
          name: "下载图片",
        },
        {
          id: "241047",
          name: "下载表格",
        },
      ],

      dButtonsDownloads2: [
        {
          id: "241053",
          name: "下载图片",
        },
        {
          id: "241049",
          name: "下载表格",
        },
      ],

      json_fields_column7: {},
      json_fields_column8: {},
      data_column8: null,
      column8Title: "",
      column9Title: "",
      json_fields_column9: {},

      dialogVisible: false,

      // 表格下载 end

      // L1
      L1MAX: 0,
      L1MAX_percen: 0,
      // L2 CQ
      CQMAX: 0,
      CQMAX_percen: 0,

      // L2 JYQ
      JYQMAX: 0,
      JYQMAX_percen: 0,

      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,

      GMREITs: "",
      CQLREITs: "",
      JYQLREITs: "",
      WLCC: "",
      CYY: "",
      BZXZF: "",
      XF: "",
      SZST: "",
      NY: "",
      SFGL: "",
    };
  },
  methods: {
    funResize() {
      this.$nextTick(() => {
        if (this.chartColumn4) {
          this.chartColumn4.resize();
        }
        if (this.chartColumn5) {
          this.$nextTick(() => {
            this.initChart5();
          });
        }
        if (this.chartColumn6) {
          this.chartColumn6.resize();
        }
        if (this.chartColumn3) {
          this.chartColumn3.resize();
        }
        if (this.chartColumn7) {
          this.chartColumn7.resize();
        }
        if (this.chartColumn8) {
          this.chartColumn8.resize();
        }
        if (this.chartColumn9) {
          this.chartColumn9.resize();
        }
      });
    },

    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE =
        ROOTFONTFIZE < 1440 ? Number(ROOTFONTFIZE) - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      // (this.eltableWidth = this.ROOTFONTFIZE * (120 / 144)),
      window.sessionStorage.setItem("ROOTFONTFIZE", this.ROOTFONTFIZE);

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;
      this.OFFSETHEIGHT = OFFSETHEIGHT;

      //  var chartColumn1 = document.getElementById("chartColumn1");
      //       chartColumn1.style.height= this.ROOTFONTFIZE * (425 / 144)+'px';

      //       var chartColumn2 = document.getElementById("chartColumn2");
      //       chartColumn2.style.height= this.ROOTFONTFIZE * (425 / 144)+'px';

      //     var chartColumn3 = document.getElementById("chartColumn3");
      //       chartColumn3.style.height= this.ROOTFONTFIZE * (720 / 144)+'px';

      return;
    },

    // 下载 start
    fnButtonDL7_1(val) {
      if (val == "下载图片") {
        this.FnDownload7_1();
      } else {
        document.getElementById("downloadExcel7_1").click();
      }
    },

    FnDownload7_1() {
      let h = this.$refs.table7_1.scrollHeight;
      let w = this.$refs.table7_1.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.table7_1, {
        async: true,
        height: h + 20,
        windowHeight: h + 20,
        width: w,
        // backgroundColor: "",
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "动态估值"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnButtonDL7_2(val) {
      if (val == "下载图片") {
        this.FnDownload7_2();
      } else {
        document.getElementById("downloadExcel7_2").click();
        document.getElementById("downloadExcel7_3").click();
        document.getElementById("downloadExcel7_4").click();
      }
    },

    fnButtonDL7_3(val) {
      if (val == "下载图片") {
        this.FnDownload7_3();
      } else {
        // exportToExcel('#chartColumn7','第二章')
        document.getElementById("downloadExcel7_61").click();
      }
    },

    fnButtonDL7_4(val) {
      if (val == "下载图片") {
        this.FnDownload7_4();
        this.FnDownload7_4_2();
      } else {
        document.getElementById("downloadExcel7_7").click();
        document.getElementById("downloadExcel7_8").click();
      }
    },

    // 下载 end

    // 放大 start
    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart7(true);
      });
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart9(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart9(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart9(true);
        });
      }
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart7(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart7(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart7(true);
        });
      }
    },

    fnDropdown3(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart4(true);
        });
      }
    },

    fnDropdown4(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart8(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart8(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart8(true);
        });
      }
    },

    // 放大 end

    // 获取L1
    fnGetL1() {
      //  L1
      let L1NAME = JSON.parse(localStorage.getItem("L1NAME"));
      let a = L1NAME.shift();
      L1NAME.push(a);
      this.category_cascader_valuationTable_L1 = L1NAME.map((node) => {
        return {
          label: node,
          value: node,
        };
      });

      //  L2
      //  let FINALLYCATEGORY = JSON.parse(localStorage.getItem('FINALLYCATEGORY'))
      // this.category_cascader_valuationTable = FINALLYCATEGORY
    },

    renderHeader(h, { column }) {
      return h("span", {}, [
        h("span", {}, column.label.split("/")[0]),
        h("br"),
        h("span", {}, column.label.split("/")[1]),
      ]);
    },
    FnDownload7_2() {
      let h = this.$refs.chartColumn4.scrollHeight;
      let w = this.$refs.chartColumn4.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn4, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "历史数据"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    FnDownload7_3() {
      let h = this.$refs.chartColumn7.scrollHeight;
      let w = this.$refs.chartColumn7.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn7, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "历史数据"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });

      this.FnDownload7_3_2();
    },

    FnDownload7_4() {
      let h = this.$refs.chartColumn8.scrollHeight;
      let w = this.$refs.chartColumn8.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn8, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "估值财报分析"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    FnDownload7_4_2() {
      let h = this.$refs.chartColumn9.scrollHeight;
      let w = this.$refs.chartColumn9.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn9, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "估值财报分析"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart9(true);
      });
    },

    fnOpenDialog3() {
      this.dialogVisible3 = true;
      this.$nextTick(() => {
        this.initChart4(true);
      });
    },

    //  fnOpenDialog4() {
    //   this.dialogVisible3 = true;
    //   this.$nextTick(()=>{
    //     this.initChart4()
    //   })
    // },

    fnOpenDialog5() {
      this.dialogVisible5 = true;
      this.$nextTick(() => {
        this.initChart8(true);
      });
    },

    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == "dark" ? true : false;
      return fnReturnTableStyle(val);
    },

    // 公募REITs
    async fnGetValuationSnapshot() {
      let r_type = "公募REITs";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot1_1 = data.data.data1;
      this.dValuationSnapshot1_1_X = [];
      this.dValuationSnapshot1_1_YL = [];
      this.dValuationSnapshot1_1_YR = [];

      for (let index = 0; index < this.dValuationSnapshot1_1.length; index++) {
        this.dValuationSnapshot1_1_X.push(
          this.dValuationSnapshot1_1[index].tr_date
        );
        this.dValuationSnapshot1_1_YL.push(
          this.dValuationSnapshot1_1[index].close
        );
        this.dValuationSnapshot1_1_YR.push(
          this.dValuationSnapshot1_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot1_2 = data.data.data2;
      // 第三个图
      this.dValuationSnapshot1_3 = data.data.data3;

      this.L1MAX =
        Math.max(...Object.values(this.dValuationSnapshot1_2.bar)) > this.L1MAX
          ? Math.max(...Object.values(this.dValuationSnapshot1_2.bar))
          : this.L1MAX;

      this.L1MAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot1_3.scatter)) >
        this.L1MAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot1_3.scatter))
          : this.L1MAX_percen;
      this.$nextTick(() => {
        this.initChart1();
      });
      // this.initChart1_2();
      // this.initChart1_3();
    },
    initChart1() {
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);

      let dValuationSnapshot1_1_X = this.dValuationSnapshot1_1_X;
      let dValuationSnapshot1_1_YL = this.dValuationSnapshot1_1_YL;
      let dValuationSnapshot1_1_YR = this.dValuationSnapshot1_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "公募REITs"
      ).value;
      this.GMREITs = color;
      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot1_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "指数走势（左轴）",
            data: dValuationSnapshot1_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              // color: "rgba(97, 1, 234, 1)",
              // color: "#5ae8ed",
              color: color,
            },
            symbol: "none",

            // itemStyle : { normal: {label : {show: true}}}
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot1_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              // color: "rgba(97, 1, 234, 0.3)",
              color:
                // theme == "dark"
                //   ? "rgba(90, 232, 237, 0.3)"
                //   : "rgba(90, 232, 237, 0.8)",
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              // color: "rgba(97, 1, 234, 0.3)",
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },

    initChart1_2() {
      this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);

      let dValuationSnapshot1_2 = this.dValuationSnapshot1_2;
      let X = Object.keys(dValuationSnapshot1_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot1_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let L1MAX = this.L1MAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          max: L1MAX,
          // interval:20,
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              return args + "qq";
            },
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      // this.funResize()
    },

    initChart1_3() {
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);

      let dValuationSnapshot1_3 = this.dValuationSnapshot1_3;
      let X = Object.keys(dValuationSnapshot1_3.scatter);
      let Y = Object.values(dValuationSnapshot1_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let L1MAX_percen = this.L1MAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: L1MAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    // 产权类
    async fnGetValuationSnapshot2() {
      let r_type = "产权类";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot2_1 = data.data.data1;
      this.dValuationSnapshot2_1_X = [];
      this.dValuationSnapshot2_1_YL = [];
      this.dValuationSnapshot2_1_YR = [];

      for (let index = 0; index < this.dValuationSnapshot2_1.length; index++) {
        this.dValuationSnapshot2_1_X.push(
          this.dValuationSnapshot2_1[index].tr_date
        );
        this.dValuationSnapshot2_1_YL.push(
          this.dValuationSnapshot2_1[index].close
        );
        this.dValuationSnapshot2_1_YR.push(
          this.dValuationSnapshot2_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot2_2 = data.data.data2;
      // 第三个图
      this.dValuationSnapshot2_3 = data.data.data3;

      this.L1MAX =
        Math.max(...Object.values(this.dValuationSnapshot2_2.bar)) > this.L1MAX
          ? Math.max(...Object.values(this.dValuationSnapshot2_2.bar))
          : this.L1MAX;

      this.L1MAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot2_3.scatter)) >
        this.L1MAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot2_3.scatter))
          : this.L1MAX_percen;
      this.$nextTick(() => {
        this.initChart2();
      });
      // this.initChart2_2();
      // this.initChart2_3();
    },

    initChart2() {
      this.chartColumn2_1 = this.$echarts.init(this.$refs.chartColumn2_1);

      let dValuationSnapshot2_1_X = this.dValuationSnapshot2_1_X;
      let dValuationSnapshot2_1_YL = this.dValuationSnapshot2_1_YL;
      let dValuationSnapshot2_1_YR = this.dValuationSnapshot2_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "产权类"
      ).value;
      this.CQLREITs = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "#21266b",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "#252b90",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          data: dValuationSnapshot2_1_X,
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot2_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",

            // itemStyle : { normal: {label : {show: true}}}
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot2_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn2_1.setOption(option);
      this.chartColumn2_1.resize();
      // this.funResize()
    },

    initChart2_2() {
      this.chartColumn2_2 = this.$echarts.init(this.$refs.chartColumn2_2);

      let dValuationSnapshot2_2 = this.dValuationSnapshot2_2;
      let X = Object.keys(dValuationSnapshot2_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot2_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let L1MAX = this.L1MAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "value",
          max: L1MAX,
          // interval:20,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn2_2.setOption(option);
      this.chartColumn2_2.resize();
      // this.funResize()
    },

    initChart2_3() {
      this.chartColumn2_3 = this.$echarts.init(this.$refs.chartColumn2_3);

      let dValuationSnapshot2_3 = this.dValuationSnapshot2_3;
      let X = Object.keys(dValuationSnapshot2_3.scatter);
      let Y = Object.values(dValuationSnapshot2_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let L1MAX_percen = this.L1MAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: L1MAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn2_3.setOption(option);
      this.chartColumn2_3.resize();
      // this.funResize()
    },

    // 经营权类
    async fnGetValuationSnapshot3() {
      let r_type = "经营权类";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot3_1 = data.data.data1;
      this.dValuationSnapshot3_1_X = [];
      this.dValuationSnapshot3_1_YL = [];
      this.dValuationSnapshot3_1_YR = [];

      for (let index = 0; index < this.dValuationSnapshot3_1.length; index++) {
        this.dValuationSnapshot3_1_X.push(
          this.dValuationSnapshot3_1[index].tr_date
        );
        this.dValuationSnapshot3_1_YL.push(
          this.dValuationSnapshot3_1[index].close
        );
        this.dValuationSnapshot3_1_YR.push(
          this.dValuationSnapshot3_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot3_2 = data.data.data2;
      // 第三个图
      this.dValuationSnapshot3_3 = data.data.data3;

      this.L1MAX =
        Math.max(...Object.values(this.dValuationSnapshot3_2.bar)) > this.L1MAX
          ? Math.max(...Object.values(this.dValuationSnapshot3_2.bar))
          : this.L1MAX;

      this.L1MAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot3_3.scatter)) >
        this.L1MAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot3_3.scatter))
          : this.L1MAX_percen;
      this.$nextTick(() => {
        this.initChart3();
      });
      // this.initChart3_2();
      // this.initChart3_3();
    },

    initChart3() {
      this.chartColumn3_1 = this.$echarts.init(this.$refs.chartColumn3_1);

      let dValuationSnapshot3_1_X = this.dValuationSnapshot3_1_X;
      let dValuationSnapshot3_1_YL = this.dValuationSnapshot3_1_YL;
      let dValuationSnapshot3_1_YR = this.dValuationSnapshot3_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "经营权类"
      ).value;
      this.JYQLREITs = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "#177475",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "#177576",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
          data: dValuationSnapshot3_1_X,
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot3_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",

            // itemStyle : { normal: {label : {show: true}}}
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot3_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn3_1.setOption(option);
      this.chartColumn3_1.resize();
      // this.funResize()
    },

    initChart3_2() {
      this.chartColumn3_2 = this.$echarts.init(this.$refs.chartColumn3_2);

      let dValuationSnapshot3_2 = this.dValuationSnapshot3_2;
      let X = Object.keys(dValuationSnapshot3_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot3_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;
      let L1MAX = this.L1MAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },
        // tooltip: {
        //   // 工具提示
        //   // trigger:'item',
        //   trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
        //   triggerOn: "click", // 触发时机, click代表点击, mouseOver代表鼠标移过
        //   formatter: function (args) {
        //     // 文字格式化
        //     return args[0].name + "的YTD回报率：" + args[0].data + "%";
        //   },
        // },
        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          // type: 'value',
          max: L1MAX,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn3_2.setOption(option);
      this.chartColumn3_2.resize();
      // this.funResize()
    },

    initChart3_3() {
      this.chartColumn3_3 = this.$echarts.init(this.$refs.chartColumn3_3);

      let dValuationSnapshot3_3 = this.dValuationSnapshot3_3;
      let X = Object.keys(dValuationSnapshot3_3.scatter);
      let Y = Object.values(dValuationSnapshot3_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let L1MAX_percen = this.L1MAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: L1MAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn3_3.setOption(option);
      this.chartColumn3_3.resize();
      // this.funResize()
    },

    // 物流仓储
    async fnGetValuationSnapshot4() {
      let r_type = "物流仓储";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot2_1_1 = data.data.data1;
      this.dValuationSnapshot2_1_1_X = [];
      this.dValuationSnapshot2_1_1_YL = [];
      this.dValuationSnapshot2_1_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot2_1_1.length;
        index++
      ) {
        this.dValuationSnapshot2_1_1_X.push(
          this.dValuationSnapshot2_1_1[index].tr_date
        );
        this.dValuationSnapshot2_1_1_YL.push(
          this.dValuationSnapshot2_1_1[index].close
        );
        this.dValuationSnapshot2_1_1_YR.push(
          this.dValuationSnapshot2_1_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot2_1_2 = data.data.data2;
      this.CQMAX =
        Math.max(...Object.values(this.dValuationSnapshot2_1_2.bar)) >
        this.CQMAX
          ? Math.max(...Object.values(this.dValuationSnapshot2_1_2.bar))
          : this.CQMAX;

      // 第三个图
      this.dValuationSnapshot2_1_3 = data.data.data3;
      this.CQMAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot2_1_3.scatter)) >
        this.CQMAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot2_1_3.scatter))
          : this.CQMAX_percen;
      this.$nextTick(() => {
        this.initChart2_1_1();
      });
      // this.initChart2_1_2();
      // this.initChart2_1_3();
    },
    initChart2_1_1() {
      this.chartColumn2_1_1 = this.$echarts.init(this.$refs.chartColumn2_1_1);

      let dValuationSnapshot2_1_1_X = this.dValuationSnapshot2_1_1_X;
      let dValuationSnapshot2_1_1_YL = this.dValuationSnapshot2_1_1_YL;
      let dValuationSnapshot2_1_1_YR = this.dValuationSnapshot2_1_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "物流仓储"
      ).value;
      this.WLCC = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot2_1_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "指数走势（左轴）",
            data: dValuationSnapshot2_1_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              // color: "rgba(97, 1, 234, 1)",
              color: color,
            },
            symbol: "none",

            // itemStyle : { normal: {label : {show: true}}}
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot2_1_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              // color: "rgba(97, 1, 234, 0.3)",
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              // color: "rgba(97, 1, 234, 0.3)",
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn2_1_1.setOption(option);
      this.chartColumn2_1_1.resize();
      // this.funResize()
    },

    initChart2_1_2() {
      this.chartColumn2_1_2 = this.$echarts.init(this.$refs.chartColumn2_1_2);

      let dValuationSnapshot2_1_2 = this.dValuationSnapshot2_1_2;
      console.log(dValuationSnapshot2_1_2,'dValuationSnapshot2_1_2');

      let X = Object.keys(dValuationSnapshot2_1_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot2_1_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let CQMAX = this.CQMAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          max: CQMAX,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn2_1_2.setOption(option);
      this.chartColumn2_1_2.resize();
      // this.funResize()
    },

    initChart2_1_3() {
      this.chartColumn2_1_3 = this.$echarts.init(this.$refs.chartColumn2_1_3);

      let dValuationSnapshot2_1_3 = this.dValuationSnapshot2_1_3;
      let X = Object.keys(dValuationSnapshot2_1_3.scatter);
      let Y = Object.values(dValuationSnapshot2_1_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let CQMAX_percen = this.CQMAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: CQMAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn2_1_3.setOption(option);
      this.chartColumn2_1_3.resize();
      // this.funResize()
    },

    // 产业园
    async fnGetValuationSnapshot5() {
      let r_type = "产业园";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot2_2_1 = data.data.data1;
      this.dValuationSnapshot2_2_1_X = [];
      this.dValuationSnapshot2_2_1_YL = [];
      this.dValuationSnapshot2_2_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot2_2_1.length;
        index++
      ) {
        this.dValuationSnapshot2_2_1_X.push(
          this.dValuationSnapshot2_2_1[index].tr_date
        );
        this.dValuationSnapshot2_2_1_YL.push(
          this.dValuationSnapshot2_2_1[index].close
        );
        this.dValuationSnapshot2_2_1_YR.push(
          this.dValuationSnapshot2_2_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot2_2_2 = data.data.data2;
      this.CQMAX =
        Math.max(...Object.values(this.dValuationSnapshot2_2_2.bar)) >
        this.CQMAX
          ? Math.max(...Object.values(this.dValuationSnapshot2_2_2.bar))
          : this.CQMAX;

      // 第三个图
      this.dValuationSnapshot2_2_3 = data.data.data3;
      this.CQMAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot2_2_3.scatter)) >
        this.CQMAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot2_2_3.scatter))
          : this.CQMAX_percen;
      this.$nextTick(() => {
        this.initChart2_2_1();
      });
      // this.initChart2_2_2();
      // this.initChart2_2_3();
    },
    initChart2_2_1() {
      this.chartColumn2_2_1 = this.$echarts.init(this.$refs.chartColumn2_2_1);

      let dValuationSnapshot2_2_1_X = this.dValuationSnapshot2_2_1_X;
      let dValuationSnapshot2_2_1_YL = this.dValuationSnapshot2_2_1_YL;
      let dValuationSnapshot2_2_1_YR = this.dValuationSnapshot2_2_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let color = this.$store.state.colors.find(
        (node) => node.name == "产业园"
      ).value;
      this.CYY = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot2_2_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot2_2_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot2_2_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn2_2_1.setOption(option);
      this.chartColumn2_2_1.resize();
      // this.funResize()
    },

    initChart2_2_2() {
      this.chartColumn2_2_2 = this.$echarts.init(this.$refs.chartColumn2_2_2);

      let dValuationSnapshot2_2_2 = this.dValuationSnapshot2_2_2;
      let X = Object.keys(dValuationSnapshot2_2_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot2_2_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let CQMAX = this.CQMAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          max: CQMAX,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn2_2_2.setOption(option);
      this.chartColumn2_2_2.resize();
      // this.funResize()
    },

    initChart2_2_3() {
      this.chartColumn2_2_3 = this.$echarts.init(this.$refs.chartColumn2_2_3);

      let dValuationSnapshot2_2_3 = this.dValuationSnapshot2_2_3;
      let X = Object.keys(dValuationSnapshot2_2_3.scatter);
      let Y = Object.values(dValuationSnapshot2_2_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let CQMAX_percen = this.CQMAX_percen;
      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: CQMAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn2_2_3.setOption(option);
      this.chartColumn2_2_3.resize();
      // this.funResize()
    },

    // 保障性住房
    async fnGetValuationSnapshot6() {
      let r_type = "保障性住房";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot2_3_1 = data.data.data1;
      this.dValuationSnapshot2_3_1_X = [];
      this.dValuationSnapshot2_3_1_YL = [];
      this.dValuationSnapshot2_3_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot2_3_1.length;
        index++
      ) {
        this.dValuationSnapshot2_3_1_X.push(
          this.dValuationSnapshot2_3_1[index].tr_date
        );
        this.dValuationSnapshot2_3_1_YL.push(
          this.dValuationSnapshot2_3_1[index].close
        );
        this.dValuationSnapshot2_3_1_YR.push(
          this.dValuationSnapshot2_3_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot2_3_2 = data.data.data2;
      this.CQMAX =
        Math.max(...Object.values(this.dValuationSnapshot2_3_2.bar)) >
        this.CQMAX
          ? Math.max(...Object.values(this.dValuationSnapshot2_3_2.bar))
          : this.CQMAX;

      // 第三个图
      this.dValuationSnapshot2_3_3 = data.data.data3;
      this.CQMAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot2_3_3.scatter)) >
        this.CQMAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot2_3_3.scatter))
          : this.CQMAX_percen;
      this.$nextTick(() => {
        this.initChart2_3_1();
      });
      // this.initChart2_3_2();
      // this.initChart2_3_3();
    },
    initChart2_3_1() {
      this.chartColumn2_3_1 = this.$echarts.init(this.$refs.chartColumn2_3_1);

      let dValuationSnapshot2_3_1_X = this.dValuationSnapshot2_3_1_X;
      let dValuationSnapshot2_3_1_YL = this.dValuationSnapshot2_3_1_YL;
      let dValuationSnapshot2_3_1_YR = this.dValuationSnapshot2_3_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let color = this.$store.state.colors.find(
        (node) => node.name == "保障性住房"
      ).value;
      this.BZXZF = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot2_3_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot2_3_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot2_3_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn2_3_1.setOption(option);
      this.chartColumn2_3_1.resize();
      // this.funResize()
    },

    initChart2_3_2() {
      this.chartColumn2_3_2 = this.$echarts.init(this.$refs.chartColumn2_3_2);

      let dValuationSnapshot2_3_2 = this.dValuationSnapshot2_3_2;
      let X = Object.keys(dValuationSnapshot2_3_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot2_3_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let CQMAX = this.CQMAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          max: CQMAX,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn2_3_2.setOption(option);
      this.chartColumn2_3_2.resize();
      // this.funResize()
    },

    initChart2_3_3() {
      this.chartColumn2_3_3 = this.$echarts.init(this.$refs.chartColumn2_3_3);

      let dValuationSnapshot2_3_3 = this.dValuationSnapshot2_3_3;
      let X = Object.keys(dValuationSnapshot2_3_3.scatter);
      let Y = Object.values(dValuationSnapshot2_3_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let CQMAX_percen = this.CQMAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: CQMAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: "#8d8d8e",
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn2_3_3.setOption(option);
      this.chartColumn2_3_3.resize();
      // this.funResize()
    },

    // 消费
    async fnGetValuationSnapshot7() {
      let r_type = "消费";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot2_4_1 = data.data.data1;
      this.dValuationSnapshot2_4_1_X = [];
      this.dValuationSnapshot2_4_1_YL = [];
      this.dValuationSnapshot2_4_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot2_4_1.length;
        index++
      ) {
        this.dValuationSnapshot2_4_1_X.push(
          this.dValuationSnapshot2_4_1[index].tr_date
        );
        this.dValuationSnapshot2_4_1_YL.push(
          this.dValuationSnapshot2_4_1[index].close
        );
        this.dValuationSnapshot2_4_1_YR.push(
          this.dValuationSnapshot2_4_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot2_4_2 = data.data.data2;
      // 第三个图
      this.dValuationSnapshot2_4_3 = data.data.data3;
      this.$nextTick(() => {
        this.initChart2_4_1();
        this.initChart2_4_2();
        this.initChart2_4_3();
      });
    },
    initChart2_4_1() {
      this.chartColumn2_4_1 = this.$echarts.init(this.$refs.chartColumn2_4_1);

      let dValuationSnapshot2_4_1_X = this.dValuationSnapshot2_4_1_X;
      let dValuationSnapshot2_4_1_YL = this.dValuationSnapshot2_4_1_YL;
      let dValuationSnapshot2_4_1_YR = this.dValuationSnapshot2_4_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let color = this.$store.state.colors.find(
        (node) => node.name == "消费"
      ).value;
      this.XF = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot2_4_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                let num = fnGetMuchDecimals(args);

                if (args == 0) {
                  return 0;
                } else {
                  return args.toFixed(num);
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                let num = fnGetMuchDecimals(args);

                if (args == 0) {
                  return 0;
                } else {
                  return args.toFixed(num) + "%";
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot2_4_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbolSize: 18,
            symbol: dValuationSnapshot2_4_1_YL.length == 1 ? "circle" : "none",
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot2_4_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbolSize: 18,
            symbol: dValuationSnapshot2_4_1_YR.length == 1 ? "circle" : "none",
          },
        ],
      };

      this.chartColumn2_4_1.setOption(option);
      this.chartColumn2_4_1.resize();
    },

    initChart2_4_2() {
      this.chartColumn2_4_2 = this.$echarts.init(this.$refs.chartColumn2_4_2);

      let dValuationSnapshot2_4_2 = this.dValuationSnapshot2_4_2;
      let X = Object.keys(dValuationSnapshot2_4_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot2_4_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          // type: 'value',
          axisLine: {
            show: false,
          },

          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn2_4_2.setOption(option);
      this.chartColumn2_4_2.resize();
    },

    initChart2_4_3() {
      this.chartColumn2_4_3 = this.$echarts.init(this.$refs.chartColumn2_4_3);

      let dValuationSnapshot2_4_3 = this.dValuationSnapshot2_4_3;
      let X = Object.keys(dValuationSnapshot2_4_3.scatter);
      let Y = Object.values(dValuationSnapshot2_4_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: "#8d8d8e",
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn2_4_3.setOption(option);
      this.chartColumn2_4_3.resize();
    },

    // 市政生态
    async fnGetValuationSnapshot8() {
      let r_type = "市政生态";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot3_1_1 = data.data.data1;
      this.dValuationSnapshot3_1_1_X = [];
      this.dValuationSnapshot3_1_1_YL = [];
      this.dValuationSnapshot3_1_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot3_1_1.length;
        index++
      ) {
        this.dValuationSnapshot3_1_1_X.push(
          this.dValuationSnapshot3_1_1[index].tr_date
        );
        this.dValuationSnapshot3_1_1_YL.push(
          this.dValuationSnapshot3_1_1[index].close
        );
        this.dValuationSnapshot3_1_1_YR.push(
          this.dValuationSnapshot3_1_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot3_1_2 = data.data.data2;
      this.JYQMAX =
        Math.max(...Object.values(this.dValuationSnapshot3_1_2.bar)) >
        this.JYQMAX
          ? Math.max(...Object.values(this.dValuationSnapshot3_1_2.bar))
          : this.JYQMAX;

      // 第三个图
      this.dValuationSnapshot3_1_3 = data.data.data3;

      this.JYQMAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot3_1_3.scatter)) >
        this.JYQMAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot3_1_3.scatter))
          : this.JYQMAX_percen;
      this.$nextTick(() => {
        this.initChart3_1_1();
      });
      // this.initChart3_1_2();
      // this.initChart3_1_3();
    },
    initChart3_1_1() {
      this.chartColumn3_1_1 = this.$echarts.init(this.$refs.chartColumn3_1_1);

      let dValuationSnapshot3_1_1_X = this.dValuationSnapshot3_1_1_X;
      let dValuationSnapshot3_1_1_YL = this.dValuationSnapshot3_1_1_YL;
      let dValuationSnapshot3_1_1_YR = this.dValuationSnapshot3_1_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "市政生态"
      ).value;
      this.SZST = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot3_1_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot3_1_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot3_1_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn3_1_1.setOption(option);
      this.chartColumn3_1_1.resize();
      // this.funResize()
    },

    initChart3_1_2() {
      this.chartColumn3_1_2 = this.$echarts.init(this.$refs.chartColumn3_1_2);

      let dValuationSnapshot3_1_2 = this.dValuationSnapshot3_1_2;
      let X = Object.keys(dValuationSnapshot3_1_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot3_1_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let JYQMAX = this.JYQMAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          max: JYQMAX,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn3_1_2.setOption(option);
      this.chartColumn3_1_2.resize();
    },

    initChart3_1_3() {
      this.chartColumn3_1_3 = this.$echarts.init(this.$refs.chartColumn3_1_3);

      let dValuationSnapshot3_1_3 = this.dValuationSnapshot3_1_3;
      let X = Object.keys(dValuationSnapshot3_1_3.scatter);
      let Y = Object.values(dValuationSnapshot3_1_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let JYQMAX_percen = this.JYQMAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: JYQMAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn3_1_3.setOption(option);
      this.chartColumn3_1_3.resize();
    },

    // 能源
    async fnGetValuationSnapshot9() {
      let r_type = "能源";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot3_2_1 = data.data.data1;
      this.dValuationSnapshot3_2_1_X = [];
      this.dValuationSnapshot3_2_1_YL = [];
      this.dValuationSnapshot3_2_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot3_2_1.length;
        index++
      ) {
        this.dValuationSnapshot3_2_1_X.push(
          this.dValuationSnapshot3_2_1[index].tr_date
        );
        this.dValuationSnapshot3_2_1_YL.push(
          this.dValuationSnapshot3_2_1[index].close
        );
        this.dValuationSnapshot3_2_1_YR.push(
          this.dValuationSnapshot3_2_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot3_2_2 = data.data.data2;
      // 第三个图
      this.dValuationSnapshot3_2_3 = data.data.data3;

      this.JYQMAX =
        Math.max(...Object.values(this.dValuationSnapshot3_2_2.bar)) >
        this.JYQMAX
          ? Math.max(...Object.values(this.dValuationSnapshot3_2_2.bar))
          : this.JYQMAX;

      this.JYQMAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot3_2_3.scatter)) >
        this.JYQMAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot3_2_3.scatter))
          : this.JYQMAX_percen;
      this.$nextTick(() => {
        this.initChart3_2_1();
      });
      // this.initChart3_2_2();
      // this.initChart3_2_3();
    },
    initChart3_2_1() {
      this.chartColumn3_2_1 = this.$echarts.init(this.$refs.chartColumn3_2_1);

      let dValuationSnapshot3_2_1_X = this.dValuationSnapshot3_2_1_X;
      let dValuationSnapshot3_2_1_YL = this.dValuationSnapshot3_2_1_YL;
      let dValuationSnapshot3_2_1_YR = this.dValuationSnapshot3_2_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "能源"
      ).value;
      this.NY = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot3_2_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot3_2_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot3_2_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn3_2_1.setOption(option);
      this.chartColumn3_2_1.resize();
      // this.funResize()
    },

    initChart3_2_2() {
      this.chartColumn3_2_2 = this.$echarts.init(this.$refs.chartColumn3_2_2);

      let dValuationSnapshot3_2_2 = this.dValuationSnapshot3_2_2;
      let X = Object.keys(dValuationSnapshot3_2_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot3_2_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let JYQMAX = this.JYQMAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          max: JYQMAX,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn3_2_2.setOption(option);
      this.chartColumn3_2_2.resize();
    },

    initChart3_2_3() {
      this.chartColumn3_2_3 = this.$echarts.init(this.$refs.chartColumn3_2_3);

      let dValuationSnapshot3_2_3 = this.dValuationSnapshot3_2_3;
      let X = Object.keys(dValuationSnapshot3_2_3.scatter);
      let Y = Object.values(dValuationSnapshot3_2_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let JYQMAX_percen = this.JYQMAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: JYQMAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn3_2_3.setOption(option);
      this.chartColumn3_2_3.resize();
    },

    // 收费公路
    async fnGetValuationSnapshot10() {
      let r_type = "收费公路";

      const { data } = await this.$https.get(
        "/api/valuation_snapshot?r_type=" + r_type
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 第一个图
      this.dValuationSnapshot3_3_1 = data.data.data1;
      this.dValuationSnapshot3_3_1_X = [];
      this.dValuationSnapshot3_3_1_YL = [];
      this.dValuationSnapshot3_3_1_YR = [];

      for (
        let index = 0;
        index < this.dValuationSnapshot3_3_1.length;
        index++
      ) {
        this.dValuationSnapshot3_3_1_X.push(
          this.dValuationSnapshot3_3_1[index].tr_date
        );
        this.dValuationSnapshot3_3_1_YL.push(
          this.dValuationSnapshot3_3_1[index].close
        );
        this.dValuationSnapshot3_3_1_YR.push(
          this.dValuationSnapshot3_3_1[index].div_yield * 100
        );
      }

      // 第二个图
      this.dValuationSnapshot3_3_2 = data.data.data2;
      // 第三个图
      this.dValuationSnapshot3_3_3 = data.data.data3;

      this.JYQMAX =
        Math.max(...Object.values(this.dValuationSnapshot3_3_2.bar)) >
        this.JYQMAX
          ? Math.max(...Object.values(this.dValuationSnapshot3_3_2.bar))
          : this.JYQMAX;

      this.JYQMAX_percen =
        Math.max(...Object.values(this.dValuationSnapshot3_3_3.scatter)) >
        this.JYQMAX_percen
          ? Math.max(...Object.values(this.dValuationSnapshot3_3_3.scatter))
          : this.JYQMAX_percen;
      this.$nextTick(() => {
        this.initChart3_3_1();
      });
      // this.initChart3_3_2();
      // this.initChart3_3_3();
    },
    initChart3_3_1() {
      this.chartColumn3_3_1 = this.$echarts.init(this.$refs.chartColumn3_3_1);

      let dValuationSnapshot3_3_1_X = this.dValuationSnapshot3_3_1_X;
      let dValuationSnapshot3_3_1_YL = this.dValuationSnapshot3_3_1_YL;
      let dValuationSnapshot3_3_1_YR = this.dValuationSnapshot3_3_1_YR;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let color = this.$store.state.colors.find(
        (node) => node.name == "收费公路"
      ).value;
      this.SFGL = color;

      let option = {
        grid: {
          height: "75%",
          top: 50,
          bottom: 0,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              let val = Number(node.value).toFixed(2);
              if (idx == 0) {
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                str += `${node.marker}${node.seriesName}：${val}%`;
              }
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 30,
            itemHeight: 3,
            data: [
              {
                name: "指数走势（左轴）",
                color: "rgba(86, 84, 202, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 30,
            y: 8,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 10,
            data: [
              {
                name: "分派率（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 200,
            y: 8,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationSnapshot3_3_1_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 3) {
                  return args + "0";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (String(args).length == 2) {
                  return args + ".0" + "%";
                }
                if (String(args).length == 1 && args !== 0) {
                  return args + ".00" + "%";
                } else {
                  return 0;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "指数走势（左轴）",
            data: dValuationSnapshot3_3_1_YL,
            yAxisIndex: 0,
            type: "line",

            itemStyle: {
              color: color,
            },
            symbol: "none",
          },
          {
            name: "分派率（右轴）",
            data: dValuationSnapshot3_3_1_YR,
            yAxisIndex: 1,
            type: "line",
            areaStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            itemStyle: {
              color:
                theme == "dark" ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
            },
            symbol: "none",
          },
        ],
      };

      this.chartColumn3_3_1.setOption(option);
      this.chartColumn3_3_1.resize();
    },

    initChart3_3_2() {
      this.chartColumn3_3_2 = this.$echarts.init(this.$refs.chartColumn3_3_2);

      let dValuationSnapshot3_3_2 = this.dValuationSnapshot3_3_2;
      let X = Object.keys(dValuationSnapshot3_3_2.bar);
      X = X.map((node) => {
        return node.slice(0, -4);
      });
      let Y = Object.values(dValuationSnapshot3_3_2.bar);

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let JYQMAX = this.JYQMAX;

      let option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          max: JYQMAX,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 12,
            show: false,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: Y,
            itemStyle: {
              // color:'#5f7fe5'
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            barWidth: "16px",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                val = val + "x";
                return val;
              },
            },
          },
        ],
      };

      this.chartColumn3_3_2.setOption(option);
      this.chartColumn3_3_2.resize();
    },

    initChart3_3_3() {
      this.chartColumn3_3_3 = this.$echarts.init(this.$refs.chartColumn3_3_3);

      let dValuationSnapshot3_3_3 = this.dValuationSnapshot3_3_3;
      let X = Object.keys(dValuationSnapshot3_3_3.scatter);
      let Y = Object.values(dValuationSnapshot3_3_3.scatter);
      X = X.map((node) => {
        return node.slice(0, -4);
      });

      let colors = [
        {
          name: "PNAV",
          value: "rgba(99, 230, 226, 1)",
        },
        {
          name: "PFFO",
          value: "rgba(86, 84, 202, 1)",
        },
        {
          name: "PCFO",
          value: "rgba(89, 143, 200, 1)",
        },
        {
          name: "EV/EBITDA",
          value: "rgba(97, 172, 228, 1)",
        },
      ];

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let JYQMAX_percen = this.JYQMAX_percen;

      let option = {
        grid: {
          top: 30,
          left: 0,
          bottom: 10,
          right: 0,
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 10,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          max: JYQMAX_percen,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: false,
            color: "#8d8d8e",
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: "公募REITs",
            data: Y,
            type: "line",
            smooth: false,
            lineStyle: {
              color: "rgba(118, 118, 118, 1)",
            },
            itemStyle: {
              color: function (params) {
                let name = params.name;

                let obj2 = {};
                obj2 = colors.find((item) => {
                  return item.name == name;
                });
                return obj2.value;
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
              formatter: function (val) {
                val = Number(val.value).toFixed(1);
                return val;
              },
            },

            symbol: "circle",
            symbolSize: 18,
          },
        ],
      };

      this.chartColumn3_3_3.setOption(option);
      this.chartColumn3_3_3.resize();
    },

    async fnSecondCategory(val) {
      if (val == "产权类") {
        this.bMAINCARD = false;
        this.bCQLCARD = true;
        this.bJYQLCARD = false;
        await this.fnGetValuationSnapshot4();
        await this.fnGetValuationSnapshot5();
        await this.fnGetValuationSnapshot6();

        await this.$nextTick(() => {
          this.initChart2_1_2();
        });
        await this.$nextTick(() => {
          this.initChart2_1_3();
        });

        await this.$nextTick(() => {
          this.initChart2_2_2();
        });
        await this.$nextTick(() => {
          this.initChart2_2_3();
        });

        await this.$nextTick(() => {
          this.initChart2_3_2();
        });
        await this.$nextTick(() => {
          this.initChart2_3_3();
        });

        // this.fnGetValuationSnapshot7();
      } else {
        this.bMAINCARD = false;
        this.bCQLCARD = false;
        this.bJYQLCARD = true;
        await this.fnGetValuationSnapshot8();
        await this.fnGetValuationSnapshot9();
        await this.fnGetValuationSnapshot10();

        await this.$nextTick(() => {
          this.initChart3_1_2();
        });
        await this.$nextTick(() => {
          this.initChart3_1_3();
        });

        await this.$nextTick(() => {
          this.initChart3_2_2();
        });
        await this.$nextTick(() => {
          this.initChart3_2_3();
        });

        await this.$nextTick(() => {
          this.initChart3_3_2();
        });
        await this.$nextTick(() => {
          this.initChart3_3_3();
        });
      }
    },

    fnFirstCategory() {
      this.bMAINCARD = true;
      this.bCQLCARD = false;
      this.bJYQLCARD = false;
      this.CQL_more = false;
      this.$nextTick(()=>{
          this.initChart1();
          this.initChart1_2();
          this.initChart1_3();
          this.initChart2();
          this.initChart2_2();
          this.initChart2_3();
          this.initChart3();
          this.initChart3_2();
          this.initChart3_3();
      })
      // this.fnGetValuationSnapshot4();
      // this.fnGetValuationSnapshot5();
      // this.fnGetValuationSnapshot6();
    },

    fnMore() {
      this.bCQLCARD = false;
      this.CQL_more = true;
      this.bMAINCARD = false;
      this.bJYQLCARD = false;

      this.fnGetValuationSnapshot7();
    },

    fnGoRight() {
      this.bCQLCARD = true;
      this.CQL_more = false;
      this.bMAINCARD = false;
      this.bJYQLCARD = false;
      this.fnSecondCategory()
    },

    // 表格1
    fnCCascader_select_val(val) {
      // // 1.
      // if (
      //   this.category_cascader_valuationTable_val.includes("公募REITs") &&
      //   val.length > 1
      // ) {
      //   val.splice(val.length - 1, 1);
      // }

      // // 2.
      // if (
      //   val.includes("公募REITs") &&
      //   this.category_cascader_valuationTable_val.length > 1
      // ) {
      //   val = [];
      //   val.push(["公募REITs"]);
      // }

      // //  牛逼！！！

      // this.$refs.CCascader_ref_1.value = val;
      this.category_cascader_valuationTable_val = val;
      this.summaryChecked_DynamicValuation = false;
      this.fnGetValuationTable();
    },

    fnCSelect_select_val(val) {
      this.category_select_valuationTable_val = val;
      this.summaryChecked_DynamicValuation = false;
      this.fnGetValuationTable();
    },

    async fnGetValuationTable() {
      let r_type = this.category_cascader_valuationTable_val;
      // let str0 = r_type.join("&r_type=");

      let summarize = this.summaryChecked_DynamicValuation ? 1 : 0;

      let factor_list = this.category_select_valuationTable_val;
      let str = factor_list.join("&factor_list=");

      const { data } = await this.$https.get(
        "/api/valuation_table?r_type=" +
          r_type +
          "&summarize=" +
          summarize +
          "&factor_list=" +
          str
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = data.data.filter((item) => item["类型"] == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      let obj2 = data.data.filter((item) => item["类型"] == "");
      if (obj2.length > 0) {
        obj2.forEach((item) => {
          TMP.push(item);
        });
      }
      this.dValuationTable = TMP;

      this.dValuationTable = data.data;

      if (summarize == 0) {
        this.dValuationTable.map((node) => {
          node["代码"] = node.reit_code;
          node["名称"] = node.REITs_name;
          node["大类"] = node.REITs_type_L1;
          node["类型"] = node.REITs_type_L2;

          delete node.reit_code;
          delete node.REITs_name;
          delete node.REITs_type_L1;
          delete node.REITs_type_L2;
        });
      }

      // 下载 start
      let json_fields = {
        代码: "代码",
        名称: "名称",
        大类: "大类",
        类型: "类型",
      };
      let arr = Object.keys(this.dValuationTable[0]);
      // console.log(arr,'法拉利');
      // arr.splice(0, 4);
      arr = arr.filter((node) => {
        return (
          node !== "代码" ||
          node !== "名称" ||
          node !== "大类" ||
          node !== "类型"
        );
      });
      arr.forEach((node) => {
        json_fields[node] = node;
      });

      this.json_fields = json_fields;


      // 下载 end

      if (r_type !== "公募REITs") {
        let arr1 = this.dValuationTable.filter((node) => {
          return node["代码"] == "一类汇总" || node["代码"] == "总体汇总";
        });
        // 先删除一类汇总
        arr1.forEach((node) => {
          this.dValuationTable.splice(this.dValuationTable.indexOf(node), 1);
        });

        arr1.forEach((node) => {
          this.dValuationTable.push(node);
        });
      } else {
        // 1. 找出一类，总体
        let arr1 = this.dValuationTable.filter((node) => {
          return node["代码"] == "一类汇总" || node["代码"] == "总体汇总";
        });

        arr1.forEach((node) => {
          this.dValuationTable.splice(this.dValuationTable.indexOf(node), 1);
        });

        // 2. 插入到数组合适位置
        let idx = this.dValuationTable.findIndex((node) => {
          return node["大类"] == "经营权类";
        });

        let idx2 = null;
        let idx3 = null;

        arr1.forEach((node, index) => {
          if (node["大类"] == "产权类") {
            this.dValuationTable.splice(idx, 0, node);
          }

          if (node["大类"] == "经营权类") {
            idx2 = index;
          }

          if (node["大类"] == "总体汇总") {
            idx3 = index;
          }
        });

        if (arr1[idx2] !== undefined && arr1[idx3]) {
          this.dValuationTable.push(arr1[idx2]);
          this.dValuationTable.push(arr1[idx3]);
        }
      }

      // END--------------------------------------------------------------------------------------------

      let CATEGORY = this.dValuationTable.map((node) => {
        return node["类型"];
      });

      CATEGORY = [...new Set(CATEGORY)];

      // 排序
      let ARRTMP = [];
      CATEGORY.forEach((node) => {
        this.dValuationTable.forEach((e) => {
          if (e["类型"] == node) {
            ARRTMP.push(e);
          }
        });
      });

      // this.dValuationTable = ARRTMP;

      let DATA = [];
      CATEGORY.forEach((e) => {
        let obj = {
          name: e,
          max_PB_ttm: 0,
          max_PNAV_ttm: 0,
          max_PFFO_ttm: 0,
          max_PCFO_ttm: 0,
          max_EVEBITDA_ttm: 0,
          // max_pdiv_ttm: 0,
          max_div_yield_ttm: 0,
        };

        // let data = this.dValuationTable.filter((item) => {
        //   if (item["类型"] == e && item["代码"] !== "二类汇总") {
        //     return item;
        //   }
        // });

        let data = this.dValuationTable;

        let data2 = data.map((node) => {
          return node["PB_ttm"];
        });

        let data3 = data.map((node) => {
          return node["PNAV_ttm"];
        });

        let data4 = data.map((node) => {
          return node["PFFO_ttm"];
        });

        let data5 = data.map((node) => {
          return node["PCFO_ttm"];
        });

        let data6 = data.map((node) => {
          return node["EV/EBITDA_ttm"];
        });

        // let data7 = data.map((node) => {
        //   return node["pdiv_ttm"];
        // });

        let data8 = data.map((node) => {
          return node["div_yield_ttm"];
        });

        if (data2[0] == undefined) {
          this.bPB = false;
        } else {
          this.bPB = true;
        }

        if (data3[0] == undefined) {
          this.bPNAV = false;
        } else {
          this.bPNAV = true;
        }

        if (data4[0] == undefined) {
          this.bPFFO = false;
        } else {
          this.bPFFO = true;
        }

        if (data5[0] == undefined) {
          this.bPCFO = false;
        } else {
          this.bPCFO = true;
        }

        if (data6[0] == undefined) {
          this.bEVEBITDA = false;
        } else {
          this.bEVEBITDA = true;
        }

        // if (data7[0] == undefined) {
        //   this.bPDIV = false;
        // } else {
        //   this.bPDIV = true;
        // }

        if (data8[0] == undefined) {
          this.bDIVYIELD = false;
        } else {
          this.bDIVYIELD = true;
        }

        obj.max_PB_ttm = Math.max(...data2);
        obj.max_PNAV_ttm = Math.max(...data3);
        obj.max_PFFO_ttm = Math.max(...data4);
        obj.max_PCFO_ttm = Math.max(...data5);
        obj.max_EVEBITDA_ttm = Math.max(...data6);
        // obj.max_pdiv_ttm = Math.max(...data7);
        obj.max_div_yield_ttm = Math.max(...data8);

        DATA.push(obj);
      });

      DATA.forEach((node) => {
        this.dValuationTable.map((e) => {
          if (node.name == e["类型"]) {
            let node_PB = "";
            let val = Number(e["PB_ttm"]) / node.max_PB_ttm;
            val = (Number(val) * 100).toFixed(0);
            node_PB = val + "px";
            e.node_PB = node_PB;

            let node_PNAV = "";
            let val2 = Number(e["PNAV_ttm"]) / Number(node.max_PNAV_ttm);
            val2 = (Number(val2) * 100).toFixed(0);
            node_PNAV = val2 + "px";
            e.node_PNAV = node_PNAV;

            let node_PFFO = "";
            let val3 = Number(e["PFFO_ttm"]) / node.max_PFFO_ttm;
            val3 = (Number(val3) * 100).toFixed(0);
            node_PFFO = val3 + "px";
            e.node_PFFO = node_PFFO;

            let node_PCFO = "";
            let val4 = Number(e["PCFO_ttm"]) / node.max_PCFO_ttm;
            val4 = (Number(val4) * 100).toFixed(0);
            node_PCFO = val4 + "px";
            e.node_PCFO = node_PCFO;

            let node_EVEBITDA = "";
            let val5 = Number(e["EV/EBITDA_ttm"]) / node.max_EVEBITDA_ttm;
            val5 = (Number(val5) * 100).toFixed(0);
            node_EVEBITDA = val5 + "px";
            e.node_EVEBITDA = node_EVEBITDA;

            // let node_PDIV = "";
            // let val6 = Number(e["pdiv_ttm"]) / node.max_pdiv_ttm;
            // val6 = (Number(val6) * 100).toFixed(0);
            // node_PDIV = val6 + "px";
            // e.node_PDIV = node_PDIV;

            let node_DIVYIELD = "";
            let val7 = Number(e["div_yield_ttm"]) / node.max_div_yield_ttm;
            val7 = (Number(val7) * 100).toFixed(0);
            node_DIVYIELD = val7 + "px";
            e.node_DIVYIELD = node_DIVYIELD;

            // if (
            //   e["代码"] == "二类汇总" ||
            //   e["代码"] == "一类汇总" ||
            //   e["代码"] == "总体汇总"
            // ) {
            //   e.node_PNAV = e.node_PNAV!=='NaNpx'?"100px":'';
            //   e.node_PFFO = e.node_PFFO!=='NaNpx'?"100px":'';
            //   e.node_PCFO = e.node_PCFO!=='NaNpx'?"100px":'';
            //   e.node_EVEBITDA = e.node_EVEBITDA!=='NaNpx'?"100px":'';
            //   e.node_DIVYIELD = e.node_DIVYIELD!=='NaNpx'?"100px":'';
            // }
          }
        });
      });

      if (summarize == 0) {
        let LEIXING = this.dValuationTable.map((node) => {
          return node["类型"];
        });

        LEIXING = [...new Set(LEIXING)];

        let tmpARR = [];
        LEIXING.forEach((node) => {
          let arr = this.dValuationTable.filter((item) => {
            return item["类型"] == node;
          });

          arr.forEach((e) => {
            tmpARR.push(e);
          });
        });

        this.dValuationTable = tmpARR;
      }
    },

    // 2. cell
    fnCell({ row, column, rowIndex, columnIndex }) {
      // if(row !==undefined){
      //   if (row.代码 == "二类汇总") {
      //   return "background: #343742;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 14px;border-bottom: none; ";
      // }
      // if (row.代码 == "一类汇总" || row.代码 == "总体汇总") {
      //   return "background: #4c4f5e;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 14px;border-bottom: none; ";
      // }
      // }

      if (row !== undefined && this.$store.state.theme == "dark") {
        if (row.代码 == "二类汇总") {
          return "background: #343742;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
        if (row.代码 == "一类汇总" || row.代码 == "总体汇总") {
          return "background: #4c4f5e;color: rgba(218, 218, 218, 1);font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
      } else {
        if (row.代码 == "二类汇总") {
          return "background: #fbfbfb;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
          // return "background: #fbfbfb;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
        if (row.代码 == "一类汇总" || row.代码 == "总体汇总") {
          return "background: #f5f5f5;color: #707070;font-weight:700;letter-spacing: 0.21px;font-size: 16px;border-bottom: none; ";
        }
      }
    },

    fnClick(row, column, cell) {
      let arr = [row["名称"], row["代码"]];
      this.$eventBus.$emit("skipPage", arr);
    },

    fnChangeBtn1(val) {},

    // chart4
    fnCSelect_select_val_two(val) {
      this.factor_chart4 = val;
      this.fnGetValuationAnalysisSingle();
      this.fnGetValuationAnalysisSingle2();
    },
    fnCCascader_select_val_two(val) {
      this.r_type_chart4 = val;

      this.fnGetValuationAnalysisSingle();
      this.fnGetValuationAnalysisSingle2()
    },

    fnCTimeButtons_select_val(val) {
      this.start_date_chart4 = val;
      this.fnGetValuationAnalysisSingle();
      this.fnGetValuationAnalysisSingle2();
    },

    CCategoryButton_select_val(val) {
      if (val == "分位数") {
        val = "percentile";
      } else {
        val = "std";
      }
      this.show_mode_chart4 = val;

      this.fnGetValuationAnalysisSingle();
    },
    async fnGetValuationAnalysisSingle() {
      this.loading_chart4 = true;
      let r_type = this.r_type_chart4;
      let factor = this.factor_chart4;
      let start_date = this.start_date_chart4;
      let show_mode = this.show_mode_chart4;
      let n = "1M";

      const { data } = await this.$https.get(
        "/api/valuation_analysis_single?r_type=" +
          r_type +
          "&factor=" +
          factor +
          "&start_date=" +
          start_date +
          "&show_mode=" +
          show_mode +
          "&n=" +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 下载 start
      this.json_fields_data1 = {};
      this.json_fields_pic1_lines = {};
      this.json_fields_ipo_date = {};

      this.json_fields_data1_data = [];
      this.json_fields_pic1_lines_data = [];
      this.json_fields_ipo_date_data = [];

      // 1.data1
      this.json_fields_data1_data = data.data.data1;
      let factor123 = this.factor_chart4 + "（左轴）";
      this.json_fields_data1 = {
        日期: "tr_date",
      };

      this.json_fields_data1[factor123] = "factor_data-left";
      this.json_fields_data1["指数点位（右轴）"] = "close_price-right";

      // 2. pic1_lines
      this.json_fields_pic1_lines_data.push(data.data.pic1_lines.line);
      // 分位线，标准差文案
      let text1 = this.show_mode_chart4 == "std" ? "标准差（+1）" : "80分位数";
      let text2 = this.show_mode_chart4 == "std" ? "标准差（-1）" : "20分位数";
      this.json_fields_pic1_lines[text1] = "upper";
      this.json_fields_pic1_lines["平均值"] = "upper";
      this.json_fields_pic1_lines[text2] = "lower";

      // 3. ipo
      this.json_fields_ipo_date_data = data.data.ipo_date;
      this.json_fields_ipo_date = {
        日期: "IPO_date",
        股票代码: "reit_code",
        股票名称: "REITs_name",
      };


      // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
       if (levelName == "普通客户") {
          let result = fnComputeTime("3M");
          this.json_fields_data1_data = this.json_fields_data1_data.filter((node) => {
          return node["tr_date"] >= result;
        });

        this.json_fields_ipo_date_data = this.json_fields_ipo_date_data.filter((node) => {
          return node["IPO_date"] >= result;
        });
       }else{
          let result = fnComputeTime("6M");
          this.json_fields_data1_data = this.json_fields_data1_data.filter((node) => {
          return node["tr_date"] >= result;
        });

        this.json_fields_ipo_date_data = this.json_fields_ipo_date_data.filter((node) => {
          return node["IPO_date"] >= result;
        });
       }


      // 下载 end

      // 第一个图
      this.dValuationAnalysisSingle = data.data.data1;
      this.dValuationAnalysisSingle_X = [];
      this.dValuationAnalysisSingle_YL = [];
      this.dValuationAnalysisSingle_YR = [];
      // IPO
      this.dValuationAnalysisSingle_IPO = data.data.ipo_date;

      this.dValuationAnalysisSingle_pic1_lines = data.data.pic1_lines;

      for (
        let index = 0;
        index < this.dValuationAnalysisSingle.length;
        index++
      ) {
        this.dValuationAnalysisSingle_X.push(
          this.dValuationAnalysisSingle[index].tr_date
        );
        this.dValuationAnalysisSingle_YL.push(
          this.dValuationAnalysisSingle[index]["factor_data-left"]
        );
        this.dValuationAnalysisSingle_YR.push(
          this.dValuationAnalysisSingle[index]["close_price-right"]
        );
      }

      //  直方图
      // 1. 拿到数据

      // 1.0 拿到最后一天的X轴数据
      let obj =
        this.dValuationAnalysisSingle[this.dValuationAnalysisSingle.length - 1];
      let lastday = Number(obj["factor_data-left"]).toFixed(2);

      let dhistogramData = this.dValuationAnalysisSingle_YL;
      dhistogramData = dhistogramData.map((node) => {
        return Number(node).toFixed(2);
      });

      const result = dhistogramData.reduce((acc, value) => {
        const key = value.toString(); // 将值转换为字符串，作为键值
        if (!acc[key]) {
          acc[key] = []; // 如果键值不存在，则创建一个新数组作为值
        }
        acc[key].push(value); // 将当前值添加到对应的键值数组中
        return acc;
      }, {});

      let arr1 = Object.values(result);
      //  应该是不用的 - 0112
      let arr2 = arr1.map((node) => {
        let val = (Number(node.length) / Number(dhistogramData.length)) * 100;
        val = val.toFixed(1);
        if (String(val).length == 3) {
          return val + "0";
        } else {
          return val;
        }
      });

      arr1 = arr1.flat();

      let arr3 = Object.keys(result);

      this.dhistogramData_X = arr3;
      this.dhistogramData_Y = arr2;

      // 直方图markline
      let histogram_last =
        this.dValuationAnalysisSingle[this.dValuationAnalysisSingle.length - 1];
      let histogram_last_val = histogram_last["factor_data-left"].toFixed(2);
      this.histogram_last_val = histogram_last_val;

      // 频率直方图
      this.initChart8_1_arr1 = arr1;
      this.initChart8_1_lastday = lastday;

      this.$nextTick(() => {
        this.initChart8_1();
        this.initChart4();
      });
      // this.initChart6();
    },
    initChart4(val) {
      if (!val) {
        this.chartColumn4 = this.$echarts.init(
          document.querySelectorAll("#chartColumn4")[0]
        );
      } else {
        this.chartColumn4 = this.$echarts.init(
          document.querySelectorAll("#chartColumn4")[1]
        );
      }

      // this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
      this.chartColumn4.clear();
      let dValuationAnalysisSingle = this.dValuationAnalysisSingle;
      let dValuationAnalysisSingle_X = this.dValuationAnalysisSingle_X;
      let dValuationAnalysisSingle_YL = this.dValuationAnalysisSingle_YL;
      let dValuationAnalysisSingle_YR = this.dValuationAnalysisSingle_YR;
      let dValuationAnalysisSingle_IPO = this.dValuationAnalysisSingle_IPO;
      // ipo
      let arr1 = dValuationAnalysisSingle_IPO.map((node) => {
        return node.IPO_date;
      });

      arr1 = [...new Set(arr1)];

      let markPointData = arr1.map((node) => {
        let obj = {
          yAxis: 0,
          xAxis: node,
        };
        return obj;
      });

      let dValuationAnalysisSingle_pic1_lines =
        this.dValuationAnalysisSingle_pic1_lines;

      let offsetNum = 180;

      // 分位线，标准差文案
      let text1 = this.show_mode_chart4 == "std" ? "标准差（+1）" : "80分位数";
      let text2 = this.show_mode_chart4 == "std" ? "标准差（-1）" : "20分位数";
      let factor_chart4 = this.factor_chart4;


      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        grid: {
          top: 70,
          bottom: 20,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {

            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node, idx) => {
              if (idx) {
                let num =
                  (parseInt(Math.abs(node.value)) + "").length >= 2 ? 1 : 2;
                let val = Number(node.value).toFixed(num);
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                if (factor_chart4 == "div_yield_ttm") {
                  let zhi = Number(node.value) * 100;
                  let num = (parseInt(Math.abs(zhi)) + "").length >= 2 ? 1 : 2;
                  let val = Number(zhi).toFixed(num);
                  str += `${node.marker}${node.seriesName}：${val}%`;
                } else {
                  let num =
                    (parseInt(Math.abs(node.value)) + "").length >= 2 ? 1 : 2;
                  let val = Number(node.value).toFixed(num);
                  str += `${node.marker}${node.seriesName}：${val}`;
                }
              }

              str += "<br>";

              return str;
            });
            return str;
          },
        },

        legend: [
          {
            show: true,
            itemWidth: 15,
            itemHeight: 15,
            data: [
              {
                // name: "PB",
                name:
                  this.factor_chart4 == "div_yield_ttm"
                    ? "分红回报率（左轴）"
                    : this.factor_chart4 + "（左轴）",
                icon: "circle",
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 20,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: "指数点位（右轴）",
                icon: "roundRect",
                color: "rgba(56, 56, 118, 1)",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 180,
            y: 20,
          },

          {
            show: true,
            itemWidth: 15,
            itemHeight: 15,
            data: [
              {
                name: "IPO标志",
                icon: "triangle",
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 220 + offsetNum,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: text1,
                lineStyle: {
                  type: "dotted",
                  width: 3,
                },
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 350 + offsetNum,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: "平均值",
                icon: "roundRect",
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,
              fontSize: 14,
            },
            left: 490 + offsetNum,
            y: 20,
          },

          {
            show: true,
            itemWidth: 30,
            itemHeight: 5,
            data: [
              {
                name: text2,
                lineStyle: {
                  type: "dotted",
                  width: 3,
                },
                //  color:'rgbargba(86, 84, 202, 1)',
              },
            ],
            textStyle: {
              color: OPTIONDATA.legend.textStyle.color,

              fontSize: 14,
            },
            left: 590 + offsetNum,
            y: 20,
          },
        ],

        xAxis: {
          type: "category",
          // boundaryGap: true,
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 50,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationAnalysisSingle_X,
          // data: ['1','2','3','4'],
        },
        yAxis: [
          {
            scale: true,
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "left",
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (factor_chart4 == "div_yield_ttm") {
                  args = args * 100;
                  let num = fnGetMuchDecimals(args)
                  return args.toFixed(num) + "%";
                } else {
                  return args;
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            // type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            position: "right",
            axisLabel: {
              fontSize: 14,
              show: true,

              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                return args.toFixed(num);
              },
            },
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name:
              this.factor_chart4 == "div_yield_ttm"
                ? "分红回报率（左轴）"
                : this.factor_chart4 + "（左轴）",
            data: dValuationAnalysisSingle_YL,
            yAxisIndex: 0,
            type: "line",
            areaStyle: {
              color: "rgba(93, 204, 200, 1)",
            },

            itemStyle: {
              color: "rgba(93, 204, 200, 1)",
            },
            symbol: "none",
          },

          {
            name: "指数点位（右轴）",
            data: dValuationAnalysisSingle_YR,
            yAxisIndex: 1,
            type: "line",

            itemStyle: {
              color: "rgba(86, 84, 202, 1)",
            },
            symbol: "none",
          },

          {
            name: "IPO标志",
            yAxisIndex: 1,
            type: "line",
            itemStyle: {
              color: "rgba(212, 48, 48, 1)",
            },

            symbol: "none",
            markPoint: {
              // 标记的数据，可以是最大值最小值也可以是自定义的坐标
              data: markPointData,
              symbol: "triangle", // 标记图形
              symbolSize: 18,
              symbolOffset: [0, "50%"],

              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  // color:'#7c7c7c',
                  // color:'#fff',
                  color: OPTIONDATA.title.textStyle.color,
                  position: "top",
                  align: "center",
                  backgroundColor: "Transparent",
                  padding: [0, 0, 0, 0],
                  borderRadius: 3,
                  fontSize: 14,
                  // width:90,height:90,
                  formatter: function (val) {
                    let name = val.name;
                    let arr = dValuationAnalysisSingle_IPO.filter((node) => {
                      return node.IPO_date == name;
                    });

                    let str = "";
                    str += `${arr[0].IPO_date} `;
                    str += "\n";

                    arr.forEach((node, idx) => {
                      //  str += `<span style=\"font-weight: bolder;\">${node.IPO_date}</span>`;
                      if (arr.length > 1) {
                        // str+='IPO: '+
                        str += "IPO：";
                        str += `{d|${node.REITs_name}}`;
                        str += "\n";
                      } else {
                        str += "IPO：";
                        str += `  {d|${node.REITs_name}}`;
                        // str+='IPO: '+ node.REITs_name
                      }
                    });

                    //  str += `${arr[0].IPO_date} `;

                    return str;
                  },
                  rich: {
                    b: {
                      color: "red",
                    },
                  },
                },
              },

              // 标注点的样式
              itemStyle: {
                color: "rgba(212, 48, 48, 1)", // 标注点颜色
                label: {
                  show: true,
                },
              },
            },
          },

          {
            name: "平均值",
            yAxisIndex: 0,
            data: dValuationAnalysisSingle_pic1_lines.line.avg,
            type: "line",
            itemStyle: {
              color: theme == "dark" ? "rgba(255, 255, 255, 1)" : "#878787",
            },
            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: dValuationAnalysisSingle_pic1_lines.line.avg,
                },
              ],

              lineStyle: {
                type: "solid",
                color: theme == "dark" ? "rgba(255, 255, 255, 1)" : "#878787",
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },

          {
            name: text1,
            yAxisIndex: 0,
            type: "line",
            data: dValuationAnalysisSingle_pic1_lines.line.upper,
            itemStyle: {
              color: "rgba(255, 174, 88, 1)",
            },

            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: dValuationAnalysisSingle_pic1_lines.line.upper,
                },
              ],

              lineStyle: {
                type: "dashed",
                color: "rgba(255, 174, 88, 1)",
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },

          {
            name: text2,
            yAxisIndex: 0,
            type: "line",
            itemStyle: {
              color: "rgba(121, 157, 255, 1)",
            },

            markLine: {
              symbol: ["none", "none"],

              data: [
                {
                  yAxis: dValuationAnalysisSingle_pic1_lines.line.lower,
                },
              ],

              lineStyle: {
                type: "dashed",
                color: "rgba(121, 157, 255, 1)",
                width: 1,
              },

              label: {
                show: false,
              },
            },
          },
        ],
      };

      this.chartColumn4.setOption(option);
      this.chartColumn4.resize();
      this.loading_chart4 = false;
      // this.funResize()
    },

    // chart5
    fnCTimeButtons_select_val_three(val) {
      this.start_date_chart5 = val;
      this.fnGetValuationAnalysisSingle2();
    },
    CotherButtons_select_val(val) {
      this.other_chart1 = val;
      this.fnGetValuationAnalysisSingle2();
    },

    async fnGetValuationAnalysisSingle2() {
      let r_type = this.r_type_chart4;
      let factor = this.factor_chart4;
      // let start_date = this.start_date_chart5;
      let start_date = this.start_date_chart4;

      let n = this.other_chart1;

      // switch (this.other_chart1) {
      //   case "1M":
      //     n = ;
      //     break;
      //   case "3M":
      //     n = "H-o-H";
      //     break;

      //   case "6M":
      //     n = "Y-o-Y";
      //     break;

      //   case "1Y":
      //     n = "Y-o-Y";
      //     break;
      // }

      const { data } = await this.$https.get(
        "/api/valuation_analysis_single_data2?r_type=" +
          r_type +
          "&factor=" +
          factor +
          "&start_date=" +
          start_date +
          "&n=" +
          n
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      // 1. 获取好数据，x轴，y轴数据要*100加%
      // 2. 赋值

      this.last_red_line = data.data.last_red_line;

      let arr1 = data.data.scatter;

      let arr2 = arr1.map((item) => {
        return [item.x, item.y, item.tr_date];
      });

      arr1 = arr1.map((item) => {
        return [item.x, item.y];
      });

      this.dValuationAnalysisSingle2 = arr1;
      this.chart5_arr2 = arr2;
      this.$nextTick(() => {
        this.initChart5();
      });
    },
    initChart5() {
      this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);
      this.chartColumn5.clear();

      let arr2 = this.chart5_arr2;

      let dValuationAnalysisSingle2 = this.dValuationAnalysisSingle2;
      let arr = dValuationAnalysisSingle2.map((node) => {
        return node[0];
      });
      let last_red_line = this.last_red_line;

      let min = Math.min(...arr);
      let max = Math.max(...arr);
      let arr3 = [min, max, last_red_line];

      let minVal = Math.min(...arr3);
      let maxVal = Math.max(...arr3);

      echarts.registerTransform(ecStat.transform.regression);

      let factor_chart4 = this.factor_chart4;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        dataset: [
          {
            source: dValuationAnalysisSingle2,
          },
          {
            transform: {
              type: "ecStat:regression",
              // 'linear' by default.
              // config: { method: 'linear', formulaOn: 'end'}
            },
          },
        ],
        title: {
          // 标题设置
          text: "收\n\n益\n\n率", // 标题文字
          textStyle: {
            // 标题文字样式设置
            // color: "#fff",
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 14,
          },
          left: 10,
          top: "middle",
        },
        grid: {
          top: 80,
          bottom: 30,
          left: 35,
          containLabel: true,
        },
        legend: {
          orient: "horizontal",
          left: 20,
          itemWidth: 20,
          itemHeight: 10,
          top: 25,
          textStyle: {
            // color: "#fff",
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: [
            {
              name:
                this.factor_chart4 == "div_yield_ttm"
                  ? "分红回报率"
                  : this.factor_chart4,
              icon: "roundRect",
            },
          ],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";

            args.forEach((node, idx) => {
              if (!idx) {
                let index = node.dataIndex;
                str += `日期：${arr2[index][2]}`;
                str += "<br>";
                let x = arr2[index][0];
                

                if(node.seriesName == '分红回报率'){
                    x = Number(x) * 100
                    let num = parseInt(Math.abs(x) + "").length >= 2 ? 1 : 2;
                    x = x.toFixed(num)+'%'
                }else{
                    let num = parseInt(Math.abs(x) + "").length >= 2 ? 1 : 2;
                    x = x.toFixed(num);
                }

                str += `${node.marker}${factor_chart4}：${x}`;
                str += "<br>";
                let y = arr2[index][1] * 100;
                let num2 = parseInt(Math.abs(y) + "").length >= 2 ? 1 : 2;
                y = y.toFixed(num2);
                str += `${node.marker}一个月后回报率：${y}%`;
                return str;
              }
            });
            return str;
          },
        },
        xAxis: {
          type: "value",
          scale: true,
          splitNumber: 3,
          min: minVal.toFixed(2),
          max: maxVal.toFixed(2),
          // max: (Number(last_red_line)*1.001).toFixed(2),
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            // color: "rgba(255, 255, 255, 0.85)",
            color: OPTIONDATA.xAxis.axisLabel.color,
            formatter: function (args) {
              if (factor_chart4 == "div_yield_ttm") {
                  args = args * 100;
                  let num = fnGetMuchDecimals(args)
                  return args.toFixed(num) + "%";
                } else {
                  return args;
                }
              console.log(val,'等i去');
              return Number(val).toFixed(2);
            }, //y轴百分比
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            formatter: function (val) {
              val = val * 100;
              val = val.toFixed(1);

              if (val < 0) {
                if (String(val).length == 4) {
                  val = val + "0";
                } else {
                  val = val;
                }
              } else {
                if (String(val).length == 3) {
                  val = val + "0";
                } else {
                  val = val;
                }
              }

              val = val + "%";

              return val;
            },
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },

        series: [
          {
            name:
              this.factor_chart4 == "div_yield_ttm"
                ? "分红回报率"
                : this.factor_chart4,
            data: dValuationAnalysisSingle2,
            type: "scatter",
            symbolSize: 13,
            cursor: "pointer",
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  xAxis: last_red_line,
                },
              ],
              lineStyle: {
                type: "solid",
                color: "rgba(255, 0, 0, 1)",
                width: 2,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  color: "red",
                  fontSize: 14,
                  formatter: function (val) {
                    return val.value;
                  },
                },
              },
            },
          },
          {
            name: "线性回归",
            type: "line",
            datasetIndex: 1,
            symbolSize: 0.1,
            symbol: "circle",
            itemStyle: {
              type: "dashed",
              color: theme == "dark" ? "#fff" : "#878787",
            },
            label: { show: false, fontSize: 16 },
            labelLayout: { dx: -20 },
            encode: { label: 2, tooltip: 1 },
          },
        ],
      };

      this.chartColumn5.setOption(option);
      this.chartColumn5.resize();
      // this.funResize()
    },

    initChart6() {
      // 废弃
      // this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);

      let dhistogramData_X = this.dhistogramData_X;
      let dhistogramData_Y = this.dhistogramData_Y;
      let histogram_last_val = this.histogram_last_val;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        title: {
          // 标题设置
          text: "频率分布直方图qq", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,

            fontSize: 16,
          },
          top: 15,
          left: "center",
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 20,
          right: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: 20,
          top: 30,
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: 10,
          itemWidth: 24,
          itemHeight: 12,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
          data: [this.factor_chart4],
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        // tooltip: {
        //   // 工具提示
        //   // trigger:'item',
        //   trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
        //   triggerOn: "click", // 触发时机, click代表点击, mouseOver代表鼠标移过
        //   formatter: function (args) {
        //     // 文字格式化
        //     return args[0].name + "的YTD回报率：" + args[0].data + "%";
        //   },
        // },
        xAxis: {
          type: "category",
          data: dhistogramData_X,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: "rgba(255, 255, 255, 0.85)",
          },
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
            lineStyle: {
              color: "#dcdcdd",
            },
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            formatter: function (val) {
              val = val.toFixed(1);
              if (String(val).length == 3) {
                val = val + "0";
              } else {
                val = val;
              }
              val = val + "%";
              return val;
            },
            color: "rgba(255, 255, 255, 0.85)",
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#48494c",
            },
          },
        },
        series: [
          {
            name: this.factor_chart4,
            type: "bar",
            data: dhistogramData_Y,
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            barWidth: "35px",
            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  xAxis: histogram_last_val,
                },
              ],
              lineStyle: {
                type: "solid",
                color: "rgba(255, 0, 0, 1)",
                width: 2,
              },
              label: {
                show: false,
              },
            },
            label: {
              show: true,
              position: "top",
              // fontSize: 14,
              formatter: function (value) {
                if (value.name == "公募REITs") {
                  return `{a|${value.data}%}  {val|YTD回报率}`;
                } else {
                  return "";
                }
              },
              rich: {
                a: {
                  color: "#d43030",
                  fontSize: 24,
                  fontWeight: "bolder",
                },
                val: {
                  color: "#bababa",
                  fontSize: 14,
                  verticalAlign: "bottom",
                },
              },
            },
          },
          {
            // data: [29000, 33000, 34000, 34100],
            data: dhistogramData_Y,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#fff",
            },
            symbol: "none",
          },
        ],
      };

      // this.chartColumn6.setOption(option);
      // this.chartColumn6.resize();
      // this.funResize()
    },

    // chart7
    fnGetCbtnsSep_chart5(val) {
      this.dButtons_separrate_current_chart5 = val;

      this.fnGetValuationBandByType();
    },
    fnCSelect_select_val_three(val) {
      this.valuation_chart7 = val;
      this.fnGetValuationBandByType();
    },
    fnCCascader_select_val_three(val) {
      this.r_type_chart7 = val;
      this.fnGetValuationBandByType();
    },
    fnCTimeButtons_select_val_LISHISHUJU(val) {
      this.start_date_LISHISHUJU = val;
      this.$nextTick(() => {
        this.initChart7();
      });
    },

    async fnGetValuationBandByType() {
      this.loading = true;
      // let r_type_ = this.r_type_chart7?this.r_type_chart7:'公募REITs';
      // let valuation = this.valuation_chart7?this.valuation_chart7:'PB';

      let r_type_ = this.r_type_chart7;
      let valuation = this.valuation_chart7;

      const { data } = await this.$https.get(
        "/api/valuation_band_by_type?r_type_=" +
          r_type_ +
          "&valuation=" +
          valuation
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      let DATA = data.data;
      this.DATA_chart7 = DATA;
      this.DATA_chart7ba = DATA.slice();

      // 下载 start
      this.json_fields_column7 = {};
     

      this.DATA_chart7ba = this.DATA_chart7ba.map((node, idx) => {
        let keys = Object.keys(node);
        let values = Object.values(node);
        keys = keys.map((item) => {
          if (item.includes(".")) {
            item = item.toString().replace(/[.,]/g, "");
          }
          return item;
        });
        let obj = {};
        keys.forEach((ele, idx) => {
          obj[ele] = values[idx];
        });

        return obj;
      });


      let keys = Object.keys(this.DATA_chart7[0]);
      let keys2 = Object.keys(this.DATA_chart7ba[0]);


      this.json_fields_column7 = {
         日期: "tr_date",
         收盘价: "current",
      }

      keys.forEach(node=>{
        let val = ''
        if(node.includes('.')){
          val = node.toString().replace(/[.,]/g, "");
        let obj = keys2.find(ele=>ele == val)
        if(obj){
          this.json_fields_column7[node] = obj
        }
        }
      })

      // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
       if (levelName == "普通客户") {
          let result = fnComputeTime("3M");
          this.DATA_chart7ba = this.DATA_chart7ba.filter((node) => {
          return node["tr_date"] >= result;
        });
       }else{
          let result = fnComputeTime("6M");
          this.DATA_chart7ba = this.DATA_chart7ba.filter((node) => {
          return node["tr_date"] >= result;
        });
       }


     

      // 下载 end

      this.$nextTick(() => {
        this.initChart7();
      });

      return;

      this.dValuationBandByType = data.data;
      this.dValuationBandByType_X = [];
      this.dValuationBandByType_97x = [];
      this.dValuationBandByType_0x = [];
      this.dValuationBandByType_11x = [];
      this.dValuationBandByType_19x = [];
      this.dValuationBandByType_23x = [];
      this.dValuationBandByType_current = [];

      for (let index = 0; index < this.dValuationBandByType.length; index++) {
        this.dValuationBandByType_X.push(
          this.dValuationBandByType[index].tr_date
        );
        this.dValuationBandByType_97x.push(
          this.dValuationBandByType[index]["0.97x"]
        );
        this.dValuationBandByType_0x.push(
          this.dValuationBandByType[index]["1.0x"]
        );
        this.dValuationBandByType_11x.push(
          this.dValuationBandByType[index]["1.11x"]
        );
        this.dValuationBandByType_19x.push(
          this.dValuationBandByType[index]["1.19x"]
        );
        this.dValuationBandByType_23x.push(
          this.dValuationBandByType[index]["1.23x"]
        );
        this.dValuationBandByType_current.push(
          this.dValuationBandByType[index]["current"]
        );
      }

      this.initChart7();
    },
    initChart7(val) {
      if (!val) {
        this.chartColumn7 = this.$echarts.init(
          document.querySelectorAll("#chartColumn7")[0]
        );
      } else {
        this.chartColumn7 = this.$echarts.init(
          document.querySelectorAll("#chartColumn7")[1]
        );
      }
      this.chartColumn7.clear();

      // 对DATA进行切分

      let time = this.start_date_LISHISHUJU;
      let DATA = this.DATA_chart7;
      let timeRes = this.fnComputeTime(time);

      // 裁切数组
      if (timeRes !== null) {
        DATA = DATA.filter((node) => {
          return node.tr_date >= timeRes;
        });
      }

      let dValuationBandByType_X = DATA.map((node) => {
        return node.tr_date;
      });

      let data1 = Object.keys(DATA[0]);
      data1.shift();
      data1.slice(0, 20);

      let seriesArr = [];
      data1.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };

        DATA.forEach((e) => {
          obj.data.push(e[node]);
        });

        seriesArr.push(obj);
      });

      seriesArr.unshift(seriesArr.pop());

      let NAME = seriesArr.map((node) => {
        if (node.name == "current") {
          return "收盘价";
        } else {
          return node.name;
        }
      });

      NAME = NAME.filter((node) => {
        return node !== "收盘价";
      });

      NAME.unshift("收盘价");

      let seriesARRAY = seriesArr.map((node) => {
        return {
          name: node.name == "current" ? "收盘价" : node.name,
          data: node.data,
          type: "line",
          smooth: this.dButtons_separrate_current_chart5 ? true : false,
          symbol: "none",
          lineStyle: {
            width: node.name == "current" ? 3 : 2,
          },

          label: {
            show: false,
            position: "top",
          },
        };
      });

      let valuation_chart7 = this.valuation_chart7;

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        legend: {
          show: true,
          orient: "vertical",
          right: 20,
          top: "middle",
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: 20,
          itemWidth: 45,
          itemHeight: 7,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 16,
          },
          data: NAME,
        },

        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let zhi = Number(node.value);
              if (valuation_chart7 == "div_yield") {
                let val = Number(zhi).toFixed(2) + "%";
                str += "<br>";
                str += `${node.marker}${node.seriesName}：${val}`;
              } else {
                let val = Number(zhi).toFixed(2);
                str += "<br>";
                str += `${node.marker}${node.seriesName}：${val}`;
              }

              return str;
            });
            return str;
          },
        },

        grid: {
          left: 20,
          top: 20,
          bottom: 35,
          right: 135,
          containLabel: true,
        },
        color: [
          // "#ffffff",
          theme == "dark" ? "#fff" : "#878787",
          "#5e5ce6",
          "#5acac6",
          "#608fe5",
          "#dda579",
          "#c24492",
        ],

        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dValuationBandByType_X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              if (valuation_chart7 == "div_yield") {
                let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                return args.toFixed(num) + "%";
              } else {
                let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
                return args.toFixed(num);
              }

              //   args = Number(args).toFixed(2);
              if (args < 0) {
                if (String(args).length == 4) {
                  args = args + "0";
                } else {
                  args = args;
                }
              } else {
                if (String(args).length == 3) {
                  args = args + "0";
                }
                if (String(args).length == 1) {
                  args = args + ".00";
                } else {
                  args = args;
                }
              }

              args = args + "%";
              return args;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: seriesARRAY,
      };

      this.chartColumn7.setOption(option);
      this.chartColumn7.resize();
      this.loading = false;
      // this.funResize()
    },

    initChart8_1() {
      this.chartColumn6 = this.$echarts.init(this.$refs.chartColumn6);
      this.chartColumn6.clear();

      let data = this.initChart8_1_arr1;
      let lastday = this.initChart8_1_lastday;

      let factor_chart4 = this.factor_chart4

      echarts.registerTransform(ecStat.transform.histogram);
      let dhistogramData_Y = this.dhistogramData_Y;

      let data2 = [7, 6, 9, 2, 6, 17, 3, 5, 1];
      let arr2 = this.fnCountHMD(data2, 10);
      let source = data;
      source = source.map((node) => {
        return Number(node);
      });

      var bins = ecStat.histogram(source, "sturges");
      //
      let idx = bins.bins.findIndex((node) => {
        return node.sample.some((item) => item == lastday);
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      var option = {
        title: {
          // 标题设置
          text: "频率分布直方图", // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,
            fontSize: 16,
          },
          top: 15,
          left: "center",
        },
        grid: {
          top: 80,
          bottom: 20,
          left: 20,
          right: 20,
          containLabel: true,
        },
        legend: {
          show: true,
          orient: "horizontal",
          left: 20,
          top: 30,
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: 10,
          itemWidth: 24,
          itemHeight: 12,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: [
            this.factor_chart4 == "div_yield_ttm"
              ? "分红回报率"
              : this.factor_chart4,
          ],
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          // formatter:function(val){
          //   console.log(val,'ddeddede');
          // }
        },
        xAxis: {
          type: "category",
          scale: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#dcdcdd",
            },
            onZero: false, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
             formatter: function (args) {
                if (factor_chart4 == "div_yield_ttm") {
                  args = args * 100;
                  let num = fnGetMuchDecimals(args)
                  return args.toFixed(num) + "%";
                } else {
                  return args;
                }
              },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#dcdcdd",
              },
            },
            axisTick: {
              show: false,
              //x轴刻度相关设置
              alignWithLabel: true,
            },
            axisLabel: {
              fontSize: 14,
              show: true,
              //  formatter:function(val){
              //   console.log(val,'val12qc');
              //       // val = val.toFixed(1)
              //       // if( String(val).length == 3){
              //       //       val = val +'0'
              //       //     }else{
              //       //       val =  val
              //       //     }
              //       // val = val + '%'
              //       return val
              //     },
              color: OPTIONDATA.yAxis.axisLabel.color,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
          // {
          //     name: '核密度',
          //     scale: true,
          //     type: 'value'
          // },
        ],
        series: [
          {
            yAxisIndex: 0,
            name:
              this.factor_chart4 == "div_yield_ttm"
                ? "分红回报率"
                : this.factor_chart4,
            type: "bar",
            barWidth: "30px",
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "#fff",
              },
            },
            itemStyle: {
              color: "rgba(90, 202, 198, 1)",
            },
            data: bins.data,
            markLine: {
              symbol: ["none", "none"],
              data: [
                {
                  xAxis: idx,
                },
              ],
              lineStyle: {
                type: "solid",
                color: "rgba(255, 0, 0, 1)",
                width: 2,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                  color: "red",
                  fontSize: 14,
                  formatter: function (val) {
                    return val.value;
                  },
                },
              },
            },
            // datasetIndex: 1
          },
          // {
          //      yAxisIndex: 1,
          // name: '核密度',
          // symbol: 'none',
          // data: arr2,
          // type: 'line',
          // }
        ],
      };

      this.chartColumn6.setOption(option);
      this.chartColumn6.resize();
      // this.funResize()
    },

    // 计算核密度
    fnCountHMD(data, bandwidth) {
      // 高斯核函数
      function gaussianKernel(x) {
        return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow(x, 2));
      }

      // 计算核密度曲线
      function calculateKernelDensity(data, bandwidth) {
        const kernelDensity = [];
        const n = data.length;

        for (let i = 0; i < n; i++) {
          let sum = 0;
          const xi = data[i];

          for (let j = 0; j < n; j++) {
            const xj = data[j];
            const diff = (xi - xj) / bandwidth;
            sum += gaussianKernel(diff);
          }

          const density = sum / (n * bandwidth);
          kernelDensity.push(density);
        }

        return kernelDensity;
      }

      const kernelDensity = calculateKernelDensity(data, bandwidth);

      return kernelDensity;
    },

    // chart8
    fnCCascader_select_val_chart8(val) {
      if (val !== "公募REITs") {
        this.disabled_chart8 = true;
        this.summaryChecked = false;
      } else {
        this.disabled_chart8 = false;
      }
      this.category_cascader_val_chart8 = val;
      this.fnGetCalIrr();
      // this.fnGetCalIrrSingle();
    },
    fnCTimeButtons_select_val_chart8(val) {
      this.start_date_chart8 = val;
      this.$nextTick(() => {
        this.initChart8();

        this.initChart9();
      });
    },

    async fnGetCalIrr() {
      this.loading_chart8 = true;
      let r_type = this.category_cascader_val_chart8;
      // let str = r_type.join("&r_type=");

      let level = "L2";
      if (r_type == "公募REITs") {
        level = "L0";
      }
      if (r_type == "产权类" || r_type == "经营权类") {
        level = "L1";
      }

      this.chart9_title = r_type;

      let sel_10yr = this.summaryChecked ? 1 : 0;

      const { data } = await this.$https.get(
        "/api/cal_irr_npv?r_type=" +
          r_type +
          "&level=" +
          level +
          "&sel_10yr=" +
          sel_10yr+
          "&re=0.05"+
          "&cal_mode=IRR"+
          "&increase_mode=ind"
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dCalIrr = data.data;
      this.dCalIrr_down = data.data;

      // 下载 start
      let TMP = Object.keys(this.dCalIrr[0]);
      TMP.forEach((node) => {
        if (node == "CN10YR") {
          this.json_fields_column8["10年国债Yield Spread"] = node;
        } else if (node == "yield_spread") {
          this.json_fields_column8["Yield Spread"] = node;
        } else if (
          node == "data" ||
          node == this.category_cascader_val_chart8
        ) {
          this.json_fields_column8["内含报酬率（IRR）"] = node;
        } else if (node == "tr_date") {
          this.json_fields_column8["日期"] = node;
        }
      });

      this.column8Title =
        this.category_cascader_val_chart8 + " 内含报酬率走势 对比";

      
        // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
       if (levelName == "普通客户") {
          let result = fnComputeTime("3M");
          this.dCalIrr_down = this.dCalIrr_down.filter((node) => {
          return node["tr_date"] >= result;
        });
       }else{
          let result = fnComputeTime("6M");
          this.dCalIrr_down = this.dCalIrr_down.filter((node) => {
          return node["tr_date"] >= result;
        });
       }


      // 下载 end
      this.$nextTick(() => {
        this.initChart8();
      });
    },
    initChart8(val) {
      if (!val) {
        this.chartColumn8 = this.$echarts.init(
          document.querySelectorAll("#chartColumn8")[0]
        );
      } else {
        this.chartColumn8 = this.$echarts.init(
          document.querySelectorAll("#chartColumn8")[1]
        );
      }

      // this.chartColumn8 = this.$echarts.init(this.$refs.chartColumn8);
      this.chartColumn8.clear();
      let time = this.start_date_chart8;
      let dCalIrr = this.dCalIrr;
      let timeRes = this.fnComputeTime(time);

      // 裁切数组
      if (timeRes !== null) {
        dCalIrr = dCalIrr.filter((node) => {
          return node.tr_date >= timeRes;
        });
      }

      let X = dCalIrr.map((node) => {
        return node.tr_date;
      });

      let ARR = Object.keys(dCalIrr[0]);

      let name = ARR[1];

      let Y = dCalIrr.map((node) => {
        return node[name];
      });

      let Y2 = [];
      let Y3 = [];

      if (this.summaryChecked) {
        Y2 = dCalIrr.map((node) => {
          return node.CN10YR;
        });

        Y3 = dCalIrr.map((node) => {
          return node.yield_spread;
        });
      }

      let colors = this.$store.state.colors;
      let ARR2 = colors.filter((node) => {
        return node.name == name;
      });

      let color_Y = "";
      if (ARR2.length) {
        color_Y = ARR2[0].value;
      } else {
        // color_Y = "#5ae8ed";
        color_Y = colors.find((node) => node.name == "公募REITs").value;
      }
      this.color_Y = color_Y;

      let LEGEND = ["内含报酬率（IRR）"];

      if (Y2.length > 0) {
        LEGEND.push("10年国债Yield Spread");
      }

      if (Y3.length > 0) {
        LEGEND.push("Yield Spread");
      }

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let option = {
        tooltip: {
          trigger: "axis",

          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `${args[0].axisValueLabel}`;
            str += "<br>";
            args.forEach((node) => {
              let val = (Number(node.value) * 100).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}%`;
              str += "<br>";

              return str;
            });
            return str;
          },
        },

        grid: {
          left: 50,
          top: 90,
          bottom: 35,
          right: 50,
          containLabel: true,
        },

        legend: {
          show: true,
          orient: "horizontal",
          left: 50,
          top: 35,
          align: "left",

          icon: "roundRect",
          itemGap: 30,
          itemHeight: 5,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: "inherit",
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: LEGEND,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              if (args == 0) {
                return 0;
              } else {
                args = (Number(args) * 100).toFixed(2);
                args = args + "%";
                return args;
              }

              // if (args < 0) {
              //   if (String(args).length == 4) {
              //     args = args + "0";
              //   } else {
              //     args = args;
              //   }
              // } else {
              //   if (String(args).length == 3) {
              //     args = args + "0";
              //   }
              //   if (String(args).length == 1) {
              //     args = args + ".00";
              //   } else {
              //     args = args;
              //   }
              // }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: "内含报酬率（IRR）",
            data: Y,
            type: "line",
            smooth: false,
            itemStyle: {
              color: color_Y,

              // color: "rgba(94, 92, 230, 1)",
            },

            symbol: "none",

            label: {
              show: false,
              position: "top",
            },
          },
          {
            name: "10年国债Yield Spread",
            data: Y2,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#2b7eb3",
            },
            symbol: "none",

            label: {
              show: false,
              position: "top",
            },
          },
          {
            name: "Yield Spread",
            data: Y3,
            type: "line",
            smooth: false,
            itemStyle: {
              color: "#932d13",
            },
            areaStyle: {
              color: "#932d13",
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },
            symbol: "none",

            label: {
              show: false,
              position: "top",
            },
          },
        ],
      };

      this.chartColumn8.setOption(option);
      this.chartColumn8.resize();
      if(!val){
        this.fnGetCalIrrSingle();
      }
      this.loading_chart8 = false;
      // this.funResize()
    },
    // 计算时间范围
    fnComputeTime(TIME) {
      let date = new Date();
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + " ";

      if (TIME == "3M") {
        if (date.getMonth() + 1 - 3 >= 1) {
          M =
            (date.getMonth() + 1 - 3 < 10
              ? "0" + (date.getMonth() + 1 - 3)
              : date.getMonth() + 1 - 3) + "-";
        } else {
          Y = date.getFullYear() - 1 + "-";
          M =
            (12 - 3 + date.getMonth() + 1 < 10
              ? "0" + (12 - 3 + date.getMonth() + 1)
              : 12 - 3 + date.getMonth() + 1) + "-";
        }
      }

      if (TIME == "6M") {
        if (date.getMonth() + 1 - 6 >= 1) {
          M =
            (date.getMonth() + 1 - 6 < 10
              ? "0" + (date.getMonth() + 1 - 6)
              : date.getMonth() + 1 - 6) + "-";
        } else {
          Y = date.getFullYear() - 1 + "-";
          M =
            (12 - 6 + date.getMonth() + 1 < 10
              ? "0" + (12 - 6 + date.getMonth() + 1)
              : 12 - 6 + date.getMonth() + 1) + "-";
        }
      }

      if (TIME == "YTD") {
        M = "01" + "-";
        D = "01";
      }

      if (TIME == "1Y") {
        Y = date.getFullYear() - 1 + "-";
      }

      if (TIME == "3Y") {
        Y = date.getFullYear() - 3 + "-";
      }

      let result = "";
      if (TIME == "MAX") {
        result = null;
      } else {
        result = Y + M + D;
      }

      return result;
    },

    // chart9
    async fnGetCalIrrSingle() {
      this.loading_chart9 = true;
      // let r_type = "公募REITs";
      let r_type = this.category_cascader_val_chart8;
      // let str = r_type.join("&r_type=");

      let level = "L2";
      if (r_type == "公募REITs") {
        level = "L0";
      }
      if (r_type == "产权类" || r_type == "经营权类") {
        level = "L1";
      }

      const { data } = await this.$https.get(
        "/api/cal_irr_npv_single?r_type=" + r_type + "&level=" + level+"&re=0.05"+"&cal_mode=IRR"+
          "&increase_mode=ind"
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dCalIrrSingle = data.data;

      let CODELIST = JSON.parse(localStorage.getItem("CODELIST"));
      let NAMES = CODELIST.map((node) => {
        return {
          name: node.reitCode,
          value: node.reitsName,
        };
      });

      this.data_column8 = JSON.parse(JSON.stringify(data.data)).map((node) => {
        return {
          tr_date: node.tr_date,
          data: node.data,
          reit_code: NAMES.find((e) => {
            return e.name == node.reit_code;
          }).value,
        };
      });

      this.json_fields_column9 = {
        日期: "tr_date",
        实际收益率: "data",
        股票名称: "reit_code",
      };

      this.column9Title = this.chart9_title + " 对应个股内含报酬率走势 对比";

        // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
       if (levelName == "普通客户") {
          let result = fnComputeTime("3M");
          this.data_column8 = this.data_column8.filter((node) => {
          return node["tr_date"] >= result;
        });
       }else{
          let result = fnComputeTime("6M");
          this.data_column8 = this.data_column8.filter((node) => {
          return node["tr_date"] >= result;
        });
       }



      this.$nextTick(() => {
        this.initChart9();
      });
    },
    initChart9(val) {
      if (!val) {
        this.chartColumn9 = this.$echarts.init(this.$refs.chartColumn9);
      } else {
        this.chartColumn9 = this.$echarts.init(this.$refs.chartColumn9_dialog);
      }

      // this.chartColumn9 = this.$echarts.init(this.$refs.chartColumn9);
      this.chartColumn9.clear();

      let time = this.start_date_chart8;
      let dCalIrrSingle = this.dCalIrrSingle;
      let timeRes = this.fnComputeTime(time);

      // 裁切数组
      if (timeRes !== null) {
        dCalIrrSingle = dCalIrrSingle.filter((node) => {
          return node.tr_date >= timeRes;
        });
      }

      // 1，找出时间(x轴)
      let DATE = dCalIrrSingle.map((node) => {
        return node.tr_date;
      });

      DATE = [...new Set(DATE)];
      this.DDL_DATE = DATE[DATE.length - 1];

      let arrTmp2 = this.DDL_DATE.split("-");
      this.DDL_DATE = arrTmp2[0] + "年" + arrTmp2[1] + "月" + arrTmp2[2] + "日";

      // 2.
      let DATA = [];
      DATE.forEach((node, idx) => {
        let obj = {
          name: node, // X轴
          data: [], // 数据
          data2: [], // 散点数据
          max: 0, // 一天中的最大值
          min: 0, // 一天中的最小值
          Y: 0, //  柱图颜色区域
          T: 0,
          two: 0,
          eight: 0,
          two2: 0,
        };

        dCalIrrSingle.forEach((e) => {
          if (e.tr_date == node) {
            obj.data.push(e.data);
            let obj2 = {
              yAxis: e.data,
              xAxis: node,
              reit_code: e.reit_code,
            };
            obj.data2.push(obj2);
          }
        });

        obj.max = Math.max(...obj.data);
        obj.min = Math.min(...obj.data);

        // 2. 计算柱状图颜色区域
        if (obj.min > 0) {
          obj.Y = obj.max - obj.min;
          obj.T = obj.min;
        } else {
          if (obj.max < 0) {
            obj.Y = obj.min;
            obj.T = obj.max;
            // obj.min = obj.max
          } else {
            obj.Y = obj.max;
            obj.T = obj.min;
          }
        }

        // 3. 计算二八位置
        let objlength = Number(obj.max) - Number(obj.min);
        let objTwoeightRatio = objlength * 0.2;

        if (obj.min >= 0) {
          obj.two = Math.abs(objTwoeightRatio / obj.Y);
          obj.two2 = 1 - obj.two;
        } else {
          if (obj.max < 0) {
          } else {
            if (obj.max > objTwoeightRatio) {
              obj.two = Math.abs(objTwoeightRatio / obj.Y);
            } else {
              obj.two = 0;
            }

            if (objTwoeightRatio < Math.abs(obj.min)) {
              obj.eight = Math.abs(objTwoeightRatio / obj.min);
              obj.two2 = 0;
            } else {
              obj.eight = 1;
              obj.two2 = objTwoeightRatio - obj.min;
            }
          }
        }

        DATA.push(obj);
      });

      // DATA = DATA.filter((node) => {
      //   return node.Y > 0;
      // });

      // 基座数据
      let DATA2 = DATA.map((node) => {
        return node.T;
      });

      // 蓝色条数据
      let DATA3 = DATA.map((node) => {
        return node.Y;
      });

      //标记点数据
      let DATA4 = DATA.map((node) => {
        return node.data2;
      });

      DATA4 = DATA4.flat();

      DATA4 = DATA4.map((node) => {
        let arr = [];
        arr.push(node.xAxis);
        arr.push(node.yAxis);
        arr.push(node.reit_code);
        return arr;
      });

      let DATA5 = [];
      let DATA6 = [];

      DATE.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        DATA4.forEach((e) => {
          if (node == e[0]) {
            let arr = [];
            arr.push(e[1]);
            arr.push(e[2]);
            obj.data.push(arr);
          }
        });

        function fnSort(a, b) {
          return b[0] - a[0];
        }
        obj.data = obj.data.sort(fnSort);

        obj.data.forEach((item) => {
          let arr = [];
          arr.push(node);
          arr.push(item);

          DATA5.push(arr);
        });
      });

      // DATA4 = DATA5;
      DATA6 = DATA5;
      DATA4 = DATA5.map((node) => {
        let arr = [];
        arr.push(node[0]);
        arr.push(node[1][0]);
        return arr;
      });

      // 排序 完美 ！

      let CODENAME = this.$store.state.CODENAME;

      let COLOR = this.color_Y;

      //let COLOR = 'rgba(123 , 122, 177,1)'

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        grid: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 55,
          containLabel: true,
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
          },
          textStyle: {
            fontSize: 14,
            color: "#666",
          },
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += `<div style="width: 100%;">${args[0].axisValueLabel}</div>`;
            str += `<div style="column-count: 2;">`;

            // str += "<br>";
            args.forEach((node) => {
              if (node.seriesType !== "scatter") return;
              let name = DATA6[node.dataIndex][1][1];
              let val = (Number(node.value[1]) * 100).toFixed(2);

              str += `<div style="display: flex;justify-content: space-between;"><span>${node.marker}${CODENAME[name]}：</span><span>${val}%</span></div>`;
              // str += "<br>";

              return str;
            });

            str += `</div>`;

            return str;
          },
        },
        // 标记
        xAxis: {
          type: "category",
          data: DATE,
          axisLine: {
            show: true,
            onZero: true, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
            lineStyle: {},
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 10,
            fontSize: 14,
            padding: [0, 0, 0, 0],
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: "value",
          interval: 0.05,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,

            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              if (val == 0) {
                return 0;
              } else {
                val = Number(val) * 100;
                let data = (val + "").split(".");
                if (data > 0) {
                  if (data < 10) {
                    val = val.toFixed(2);
                  } else {
                    val = val.toFixed(1);
                  }
                } else {
                  if (data > -10) {
                    val = val.toFixed(2);
                  } else {
                    val = val.toFixed(1);
                  }
                }

                val = val + "%";
                return val;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },

        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "transparent",
              // color: "transparent",
              color: function (val) {
                // 1. max<0,min<0
                let max = DATA[val.dataIndex].max;
                let min = DATA[val.dataIndex].min;

                if (max < 0) {
                  return "transparent";
                } else {
                  // return COLOR

                  if (min > 0) {
                    return "transparent";
                  } else {
                    let eight = DATA[val.dataIndex].eight;
                    eight = eight.toFixed(2);

                    let Y = DATA[val.dataIndex].Y;
                    Y = Y.toFixed(2);

                    return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: "#fff",
                      },

                      {
                        offset: eight,
                        color: COLOR,
                      },
                      {
                        offset: 1,
                        color: COLOR,
                      }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                    ]);
                  }

                  return;

                  if (val.value > 0) {
                    return "transparent";
                  } else {
                    // return COLOR

                    if (Y <= 0) {
                      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#fff",
                        },

                        {
                          offset: 0.2,
                          color: COLOR,
                        },
                        {
                          offset: 0.8,
                          color: COLOR,
                        },
                        {
                          offset: 1,
                          color: "#fff",
                        },
                      ]);
                    }

                    if (eight == 1) {
                      return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        {
                          offset: 0,
                          color: "#333",
                        },

                        {
                          offset: eight,
                          color: COLOR,
                        },
                        {
                          offset: 1,
                          color: COLOR,
                        }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                      ]);
                    } else {
                      return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        {
                          offset: 0,
                          color: "#ccc",
                        },

                        {
                          offset: eight,
                          color: COLOR,
                        },
                        {
                          offset: 1,
                          color: COLOR,
                        }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                      ]);
                    }
                  }
                }
              },
            },
            // emphasis: {
            //   itemStyle: {
            //     borderColor: "transparent",
            //     color: "transparent",
            //   },
            // },

            data: DATA2,
          },

          {
            type: "scatter", // 指明图表的类型为散点图
            symbol: "roundRect",
            data: DATA4,
            itemStyle: {
              color: theme == "dark" ? "#fff" : "#9e9e9e",
              borderWidth: 1,
            },
            symbolSize: [10, 5],
          },
          {
            type: "bar",
            stack: "Total",

            data: DATA3,
            barWidth: "10px",

            itemStyle: {
              borderColor: "transparent",
              // color: "transparent",
              color: function (val) {
                // return COLOR

                // return COLOR
                let two = DATA[val.dataIndex].two;
                two = two.toFixed(2);

                let two2 = DATA[val.dataIndex].two2;
                two2 = two2.toFixed(2);

                let eight = DATA[val.dataIndex].eight;
                eight = eight.toFixed(2);

                if (DATA[val.dataIndex].min > 0) {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#fff",
                    },
                    {
                      offset: 0.2,
                      color: COLOR,
                    },
                    {
                      offset: 0.9,
                      color: COLOR,
                    },

                    // {
                    //                           offset: 0.6,
                    //                           color: '#1684fc'
                    //                         },
                    {
                      offset: 1,
                      color: "#fff",
                    }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                  ]);
                } else {
                  if (DATA[val.dataIndex].max < 0) {
                    return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#fff",
                      },
                      {
                        offset: 0.2,
                        color: COLOR,
                      },
                      {
                        offset: 0.8,
                        color: COLOR,
                      },
                      {
                        offset: 1,
                        color: "#fff",
                      }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                    ]);
                  } else {
                    if (DATA[val.dataIndex].two == 0) {
                      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#fff",
                        },

                        {
                          offset: 1,
                          color: COLOR,
                        }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                      ]);
                    } else {
                      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#fff",
                        },
                        {
                          offset: two,
                          color: COLOR,
                        },
                        {
                          offset: 1,
                          color: COLOR,
                        }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                      ]);
                    }

                    // return '#e74033'
                    //  return COLOR;
                  }
                }
              },
            },
            // markPoint: {
            //   data: DATA4,
            //   symbol: "roundRect", // 标记图形
            //   symbolSize: [10, 2],

            //   // 标注点的样式
            //   itemStyle: {
            //     color: "#fff", // 标注点颜色
            //   },
            // },
          },
        ],
        dataZoom: {
          // 开启数据平移qc
          type: "slider", // 滑动条单独显示
          show: true, // 是否显示滑动条
          startValue: DATE[DATE.length - 100], // 展示区域内容的起始数值
          endValue: DATE[DATE.length], // 展示区域内容的结束数值 当前展示x坐标下标为0-16
          // height: 5, // 滑动条组件高度
          // bottom: 30, // 距离图表区域下边的距离
          showDetail: false, // 拖拽时是否显示详情
          // showDataShadow: false,
          // fillerColor: "#D4D4D4", // 平移条的填充颜色
          // borderColor: "transparent", // 边框颜色
          zoomLock: true, // 锁定视图
          brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
          // 通过该属性可以只滑动，不显示缩放功能
          // handleStyle: {
          //   opacity: 0,
          // },
          // lineStyle: {
          //   opacity: 0,
          // },
          // textStyle: {
          //   fontSize: 0,
          // },
        },
      };

      this.chartColumn9.setOption(option);
      this.chartColumn9.resize();
      this.loading_chart9 = false;
      // this.funResize()
    },

    async handleClick(e) {
      this.activeName = e.name;
      localStorage.setItem("activeName", this.activeName);
      let activeName = e.name;
      if (activeName == "first") {
        this.$eventBus.$emit("setBreadcrumbs", "概览");

        await this.fnGetValuationSnapshot();
        await this.fnGetValuationSnapshot2();
        await this.fnGetValuationSnapshot3();

        await this.$nextTick(() => {
          this.initChart1_2();
        });

        await this.$nextTick(() => {
          this.initChart1_3();
        });

        await this.$nextTick(() => {
          this.initChart2_2();
        });
        await this.$nextTick(() => {
          this.initChart2_3();
        });

        await this.$nextTick(() => {
          this.initChart3_2();
        });
        await this.$nextTick(() => {
          this.initChart3_3();
        });

        // this.fnGetValuationSnapshot7();
      }

      if (activeName == "second") {
        this.$eventBus.$emit("setBreadcrumbs", "动态估值");
        this.fnGetValuationTable();
      }

      if (activeName == "third") {
        this.$eventBus.$emit("setBreadcrumbs", "历史估值");
        this.fnGetValuationAnalysisSingle();
        this.fnGetValuationAnalysisSingle2();
      }

      if (activeName == "fourth") {
        this.$eventBus.$emit("setBreadcrumbs", "估值Bands");
        this.fnGetValuationBandByType();
      }

      if (activeName == "fifth") {
        this.isVip = fnGetLevelName() == "VIP客户" ? true : false;
        this.$eventBus.$emit("setBreadcrumbs", "估值财报分析");
        if (!this.isVip) return;
        this.fnGetCalIrr();
        // this.fnGetCalIrrSingle();
      }
    },

    // 计算L1 Y轴
    fnCalL1() {},
  },
  filters: {
    isZero: function (val) {
      let value = val.substring(0, val.length - 1);
      if (value == 0) {
        return "";
      } else {
        return val;
      }
    },
  },
  components: {
    CSelect,
    CCategoryButton,
    CCascader,
    CButtonsSeparate,
    CTimeButtons,
    CotherButtons,
    CButtonsDownload,
    CDialog,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--TABPANEHEIGHT": this.TABPANEHEIGHT + "px",
        "--GMREITs": this.GMREITs,
        "--CQLREITs": this.CQLREITs,
        "--JYQLREITs": this.JYQLREITs,
        "--WLCC": this.WLCC,
        "--CYY": this.CYY,
        "--BZXZF": this.BZXZF,
        "--XF": this.XF,
        "--SZST": this.SZST,
        "--NY": this.NY,
        "--SFGL": this.SFGL,
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetTheme();
      fnDownloadBgc();
      this.$nextTick(() => {
        if (this.activeName == "first") {

          if(this.bMAINCARD){
             this.initChart1();
              this.initChart1_2();
              this.initChart1_3();
              this.initChart2();
              this.initChart2_2();
              this.initChart2_3();
              this.initChart3();
              this.initChart3_2();
              this.initChart3_3();
          }

          if(this.bCQLCARD){
                  this.initChart2_1_1();
          this.initChart2_1_2();
          this.initChart2_1_3();

          this.initChart2_2_1();
          this.initChart2_2_2();
          this.initChart2_2_3();

          this.initChart2_3_1();
          this.initChart2_3_2();
          this.initChart2_3_3();
          }

          if(this.bJYQLCARD){
              this.initChart3_1_1();
          this.initChart3_1_2();
          this.initChart3_1_3();

          this.initChart3_2_1();
          this.initChart3_2_2();
          this.initChart3_2_3();

          this.initChart3_3_1();
          this.initChart3_3_2();
          this.initChart3_3_3();
          }



          if(this.CQL_more){
          this.initChart2_4_1();
          this.initChart2_4_2();
          this.initChart2_4_3();

          }
  
        }

        if (this.activeName == "third") {
          this.initChart4();
          this.initChart5();
          this.initChart8_1();
        }

        if (this.activeName == "fourth") {
          this.initChart7();
        }

        if (this.activeName == "fifth") {
          this.initChart8();
          this.initChart9();
        }
      });

      return;
      this.initChart();
      this.initChart2();
      this.initChart3();
      this.initChart4();
      this.initChart5();
    },
  },
};
</script>

<style scoped>
.openBtn {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.openBtn:hover {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  background-size: contain;
}

.btnArea_center {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  width: 100%;
}

.container {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  /* line-height: calc(var(--ROOTFONTFIZE) * (20 / 144)); */
  height: 100%;
  /* padding-right: 20px; */
  color: rgba(255, 255, 255, 1);
  /* height: auto; */
}

.top {
  width: 100%;
  height: calc(var(--ROOTFONTFIZE) * (470 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom {
  display: flex;
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.bottom .left,
.bottom .right {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (430 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom .right {
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.tit {
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid rgba(21, 115, 254, 1);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

.other_chart1_style {
  display: flex;
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.other_chart1_style div:nth-child(1) {
  flex: 3;
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  justify-content: center;
}

.other_chart1_style div:nth-child(2) {
  flex: 6;
}

.other_chart1 {
  /* width: 60%; */
}

/* ------------------------------------------------------------------ */
.card {
  /* background-color: rgba(29, 31, 37, 1); */
  background-color: var(--charts_bgc);
  height: auto;
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144)); */
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  overflow: hidden;
}

.card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  /* border-left: 5px solid rgba(21, 115, 254, 1); */
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  display: flex;
  justify-content: space-between;
}

.card_tit2 {
  display: flex;
  justify-content: end;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card .main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

.hasMore {
}

.hasMore:hover .btnMo,
.hasMore:hover .leftBtn {
  opacity: 1;
  cursor: pointer;
}

.btnMo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 18%;
  width: auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  background-color: rgba(127, 127, 127, 0.1);
  transition: opacity 0.5s;
}

.OVERVIEWICONSTYLE {
  background-color: #383838;
  color: #f7ecdc;
  font-size: 25px;
  padding: 10px;
  border-radius: 50%;
}

/* .btnMo:hover,
.leftBtn:hover {
  opacity: 1;
  cursor: pointer;
} */

.leftBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0 !important;
  height: 18%;
  width: auto !important;
  padding: 0 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  background-color: rgba(127, 127, 127, 0.1);
  transition: opacity 0.5s;
}

.specialMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.card .main .main_item {
  width: 30%;
  height: calc(var(--ROOTFONTFIZE) * (300 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  /* background-color: #1a1c21; */
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* border: 0.2px solid #35373b; */
  border: 1px solid var(--charts_brc);
  /* margin-left: 20px; */
}

.h400 {
  height: 400px !important;
}

.main_item_nothing {
  background-color: transparent !important;
  border: none !important;
}

.card .main .main_item:nth-child(n + 4) {
  height: calc(var(--ROOTFONTFIZE) * (420 / 144));
}

.main_item_first {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) dotted
    rgba(118, 118, 118, 0.48);
}

.main_item_second {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.main_item_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
  color: var(--legndAreaItem_color);
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.main_item_top div span {
  /* color: rgba(132, 131, 222, 1); */
  /* color: #6101ea; */
  /* color: #5ae8ed; */
  color: var(--GMREITs);
}

.CQL_color {
  color: var(--CQLREITs) !important;
}

.JYQL_color {
  color: var(--JYQLREITs) !important;
}

.WLCH_color {
  color: var(--WLCC) !important;
}

.CYY_color {
  color: var(--CYY) !important;
}

.BZXZF_color {
  color: var(--BZXZF) !important;
}

.XF_color {
  color: var(--XF) !important;
}

.SZST_color {
  color: var(--SZST) !important;
}

.NY_color {
  color: var(--NY) !important;
}

.SFGL_color {
  color: var(--SFGL) !important;
}

.main_item_tit {
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
  color: var(--chart_title);
}

.card_top {
  display: flex;
  justify-content: space-between;
  padding: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.card_top span {
  display: flex;
  align-items: center;
}
.mr20 {
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.ml20 {
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card_bottom {
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: 0 !important;
}

.card_history_top {
  display: flex;
  padding-left: calc(var(--ROOTFONTFIZE) * (25 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
  align-items: center;
}

.pl {
  padding-left: 0 !important;
}

.card_history_top div {
  color: rgba(255, 255, 255, 1);
}

.valuationCard {
  height: calc(var(--ROOTFONTFIZE) * (920 / 144));
}

.historymain {
  height: calc(var(--ROOTFONTFIZE) * (530 / 144));
  max-height: calc(var(--ROOTFONTFIZE) * (530 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  /* background-color: #1a1c21;
  border: 0.2px solid rgba(135, 135, 135, 0.3); */
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  margin: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.abslouteMain {
  height: calc(var(--ROOTFONTFIZE) * (365 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #1a1c21;
  border: 0.2px solid rgba(135, 135, 135, 0.3);
  margin: calc(var(--ROOTFONTFIZE) * (38 / 144))
    calc(var(--ROOTFONTFIZE) * (45 / 144))
    calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.abslouteMainUp {
  padding-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.abslouteChecked {
  margin: 0 calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.abslouteChecked_span {
  color: var(--stock_area_item_color1);
}

.HistoricalValuation {
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.HistoricalValuationMain {
  padding-top: 0px !important;
}

.HistoricalValuation_info {
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144)) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (17.38 / 144));
  color: var(--stock_area_item_color2);
}

.scatterArea {
  display: flex;
  gap: calc(var(--ROOTFONTFIZE) * (30 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144)) !important;
}

.scatterArea div:nth-child(1) {
  flex: 3;
  /* margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144)); */
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  border: 0.2px solid var(--charts_brc);
}

.scatterArea div:nth-child(2) {
  flex: 7;
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  border: 0.2px solid var(--charts_brc);
}

.el-tabs {
  background-color: var(--bigger-card-bgc);
  /* padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144)); */
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  /* background-color: #1573fe !important; */
  background-color: var(--primary-color) !important;
}

/* el-table start */
::v-deep .table1 table {
  border: none;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc) !important;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: 1px solid var(--table_brc);
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc) !important;
  padding-bottom: 90px;
}

::v-deep .el-table__body-wrapper {
  height: auto !important;
  max-height: 100% !important;
  padding-bottom: 10px !important;
}

/* el-table end */

::v-deep .el-tab-pane {
  display: block;
  width: 100%;
  height: var(--TABPANEHEIGHT);
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.value_area {
  display: flex;
  align-items: center;
  justify-content: start;
}

.a {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #4ec0c5;
}

.b {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #4ec0c5;
}

.c {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #5387be;
}

.d {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #526cbf;
}

.e {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #5150bf;
}

.canvasArea {
  /* background-color: pink !important; */
}

.historymain2 {
  height: calc(var(--ROOTFONTFIZE) * (620 / 144));
}

.DDLINFO {
  margin-left: calc(var(--ROOTFONTFIZE) * (16 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(158, 158, 158, 1);
}

.main_item_bottom {
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  text-align: right;
  padding-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
  color: #5ae8ed;
}

.main_item_bottom:hover {
  cursor: pointer;
}

.summary_style {
  display: flex;
  color: var(--stock_area_item_color1);
}

.noVip {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  color: #78787a;
  color: #bababb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--ROOTFONTFIZE) * (500 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: #373a42;
  margin: calc(var(--ROOTFONTFIZE) * (20 / 144))
    calc(var(--ROOTFONTFIZE) * (30 / 144));
  border: 1px solid #333335;
}

.display_none {
  display: none;
}

.icon-taocan-suoxiao {
  font-size: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.echarts_area {
  position: relative;
  padding: 0 !important;
  overflow: hidden;
  /* margin-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144)) !important; */
}

/* .icon-fangda {
  right: 6%;
} */

::v-deep .el-table__empty-block {
  /* background-color: #1d1f25 !important; */
}

/* // 滚动条样式 */
/* ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 12px;
  height: 6px;
  background-color: pink;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}


::v-deep .el-table__body-wrapper{
  background-color:  #1d1f25;
}


::v-deep .el-table th.gutter {
  display: table-cell !important;
  height: 50px;
  background: #4a4c51;
  position: absolute;
  right: 0;
  top: 0;
} */
</style>
