<template>
  <div class="container" :style="cssVars" id="rootchart">
    <div class="AREA">
      <div class="left_area">
        <!-- 地图 -->

        <!-- 1150 -->
        <div
          class="map"
          ref="chartColumn1"
          id="chartColumn1"
          style="width: 100%; height: 100%"
        ></div>
      </div>
      <div class="right_area">
        <div class="right_area_top">
          <div class="left" id="leftArea" >
            <div class="tit">
              CREITs底层资产按照类别估值总额

              <div class="downloadBtns">
                <CButtonsDownload
                  :dButtonsDownloads="dButtonsDownloads"
                  @fnButtondownload="fnButtonDL"
                />

                <span style="display: none">
                  <download-excel
                    id="downloadExcel9_1"
                    :data="dRpDateAssetValue_down"
                    :fields="json_fields9_1"
                    header="CREITs底层资产按照类别估值总额"
                    name="CREITs底层资产按照类别估值总额.xls"
                  >
                  </download-excel>
                </span>
              </div>
            </div>
            <div class="pieAreaPack">
              <div class="pieArea">
                <span
                  @click="fnOpenDialog"
                  class="iconfont icon-fangda icon-fangda2"
                ></span>
                <div
                  
                  id="chart"
                  class="canvasArea"
                  ref="chartColumn2"
                  style="width: 100%; height: 485px"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_area_bottom">
          <div class="right">
            <div class="tit">CREITs底层资产估值占比</div>
            <div class="pieAreaPack">
              <div class="pieArea">
              <span
                @click="fnOpenDialog2"
                class="iconfont icon-fangda icon-fangda2"
              ></span>
               <div
                class="canvasArea"
                ref="chartColumn3"
                id="chartColumn3"
                style="width: 100%; height: 485px"
              ></div>

              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            id="chart"
            class="canvasArea pieArea"
            ref="chartColumn2_dialog"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible2"
    >
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog"
      >
        <div>
          <el-dropdown
            size="mini"
            trigger="click"
            type="primary"
            @command="fnDropdown2"
          >
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="idx"
                v-for="(node, idx) in popUpWindowDatas"
                :key="idx"
              >
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            class="canvasArea pieArea"
            ref="chartColumn3_dialog"
            id="chartColumn3"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import chinaJSON from "@/assets/json/china.json";
import CButtonsDownload from "@/components/Basic/CButtons_download";
import CDialog from "@/components/Basic/CDialog";

import html2canvas from "html2canvas";

import { fnDownloadBgc,fnComputeTime } from "@/utils/util";

export default {
  components: {},
  computed: {},
  created() {
    this.fnGetLastAssetValue();
    this.fnGetRpDateAssetInfo();
    this.fnGetRpDateAssetValue();
  },
  mounted() {
    window.addEventListener("resize", this.funResize);
    this.fnGetWidth();
    // this.initChart3();
    // this.initChart1();
    // this.fnGetWidth()
    // this.funResize();
  },
   beforeDestroy(){
     window.removeEventListener("resize", this.funResize);
     this.chartColumn1&&this.chartColumn1.dispose()
    this.chartColumn2&&this.chartColumn2.dispose()
    this.chartColumn3&&this.chartColumn3.dispose()
  },
  data() {
    return {
      sCurrentTheme: this.$store.state.theme == "dark" ? true : false,
      dialogVisible: false,
      dialogVisible2: false,
      width_dialog: "80%",
      height_dialog: "73vh",
      height_chart: "70vh",
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: "5vh",

      value: "",
      chartColumn3: null,
      chartColumn2: null,
      chartColumn2_dialog: null,
      chartColumn3_dialog: null,
      chartColumn1: null,
      dLastAssetValue: [],
      dRpDateAssetValue_MAP: [],
      dRpDateAssetValue: [],
      dRpDateAssetValue_down: [],

      dRpDateAssetValue_X: [],
      dRpDateAssetValue_ARRAY: [],

      CATEGORYDATA: this.$store.state.category_cascader_options,
      ROOTFONTFIZE: 0,
      json_fields9_1: {},

      dButtonsDownloads: [
        {
          id: "241017",
          name: "下载图片",
        },
        {
          id: "241017",
          name: "下载表格",
        },
      ],

      // chinaJSON: chinaJSON,
    };
  },
  methods: {
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == "dark" ? true : false;
    },
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem("ROOTFONTFIZE", this.ROOTFONTFIZE);

      var chartColumn1 = document.getElementById("chartColumn1");
      if(chartColumn1){
        chartColumn1.style.height = this.ROOTFONTFIZE * (1150 / 144) + "px";
      }

      var chart = document.getElementById("chart");
      if(chart){ 
        chart.style.height = this.ROOTFONTFIZE * (485 / 144) + "px";
      }

      var chartColumn3 = document.getElementById("chartColumn3");
      if(chartColumn3){
        chartColumn3.style.height = this.ROOTFONTFIZE * (485 / 144) + "px";
      }

      return;

      // var element = document.getElementById("chart");
      // var width = element.offsetWidth;

      // var element2 = document.getElementById("leftArea");
      // var width2 = element2.offsetWidth;

      // // element2.style.height= (width * 0.77)+'px'
      // element.style.height = width * 0.67 + "px";
      // element2.style.height = width2 * 0.77 + "px";
    },

    funResize() {
      // this.fnGetWidth()
      this.$nextTick(() => {
        this.chartColumn1&&this.chartColumn1.resize();
        this.chartColumn2&&this.chartColumn2.resize();
        this.chartColumn3&&this.chartColumn3.resize();
      });
    },

    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart2(true);
      });
    },

    fnOpenDialog2() {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initChart3(true);
      });
    },

    fnDropdown(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = "70vh";
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = "70vh";
        this.$nextTick(() => {
          this.initChart2(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = "47vh";
        this.$nextTick(() => {
          this.initChart2(true);
        });
      }
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = "70vh";
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = "70vh";
        this.$nextTick(() => {
          this.initChart3(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = "47vh";
        this.$nextTick(() => {
          this.initChart3(true);
        });
      }
    },

    // 下载 start
    fnButtonDL(val) {
      if (val == "下载图片") {
        this.FnDownload();
      } else {
        document.getElementById("downloadExcel9_1").click();
      }
    },

    // 下载
    FnDownload() {
      let h = this.$refs.chartColumn2.scrollHeight;
      let w = this.$refs.chartColumn2.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn2, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "CREITs底层资产按照类别估值总额"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    // 下载 end

    async fnGetRpDateAssetInfo() {
      const { data } = await this.$https.get("/api/rp_date_asset_info");

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dRpDateAssetValue_MAP = data.data;
      this.$nextTick(() => {
        this.initChart1();
      });
    },

    async initChart1() {
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let data = this.dRpDateAssetValue_MAP;

      let colors = this.$store.state.colors;

      let NAME = data.map((node) => {
        return node.资产类型;
      });

      NAME = [...new Set(NAME)];

      //   console.log(NAME,'NAME波及小苏1');

      let orderData = JSON.parse(localStorage.getItem("ORDER"));
      let TMP = [];
      orderData.forEach((node) => {
        let obj = NAME.filter((item) => item == node);
        if (obj.length > 0) {
          obj.forEach((item) => {
            TMP.push(item);
          });
        }
      });

      NAME = TMP;

      //   console.log(NAME,'NAME波及小苏');

      let color = [];
      NAME.forEach((e) => {
        let obj = colors.find((node) => {
          return node.name == e;
        });

        color.push(obj.value);
      });

      // 计算每个省市有多少个
      data.forEach((node) => {
        if (node["所在省份"].length == 2) {
          return (node["所在省份"] = node["所在省份"] + "市");
        }
      });
      // foreach 漂亮!!!

      let provinceHave = data.map((node) => {
        return node["所在省份"];
      });
      provinceHave = [...new Set(provinceHave)];

      let mapData = provinceHave.map((node) => {
        let obj = {
          name: node,
          value: 0,
        };
        data.forEach((item) => {
          if (node == item["所在省份"]) {
            obj.value++;
          }
        });
        return obj;
      });

      mapData.forEach((node) => {
        node.name = node.name.slice(0, 2);
      });

      let seriesArr = data.map((v, index) => {
        return {
          name: v["资产类型"],
          data: [[v.lon, v.lat]],
          type: "effectScatter",
          rippleEffect: {
            scale: 1.8, // 设置涟漪动画的缩放比例
          },
          coordinateSystem: "geo",
          symbolSize: 6 + (Number(v["最新估值（亿元）"]) / 100) * 20,
          // symbolSize: v["最新估值（亿元）"]  / 2,
          cursor: "pointer",
          itemStyle: {
            color: function (val) {
              let name = val.seriesName;
              let obj = colors.find((node) => {
                return node.name == name;
              });
              return obj.value;
            },
          },
        };
      });

      let obj = {
        data: mapData,
        geoIndex: 0,
        type: "map",
        tooltip: {
          show: false,
        },
      };
      seriesArr.unshift(obj);

      // 获取中国地图的矢量数据
      // const ret =  await axios.get('http://10.168.1.124:8890/json/china.json')
      // 1.
      this.$echarts.registerMap("chinaMap", chinaJSON);

      color.unshift("");

      NAME = NAME.map((node, idx) => {
        let obj = {
          name: node,
          itemStyle: {
            color: color[idx + 1],
          },
        };
        return obj;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          left: 0,
        },
        geo: {
          type: "map",
          map: "chinaMap", // chinaMap需要和registerMap中的第一个参数保持一致
          roam: true, // 设置允许缩放以及拖动的效果
          label: {
            show: true, // 展示标签
            textStyle: {
              color: "#333",
            },
            emphasis: {
              textStyle: {
                color: "#666",
              },
            },
          },
          itemStyle: {
            normal: {
              // borderColor: '#3b3d42',
              // borderWidth: 1,
              areaColor: "#fff",
            },
            emphasis: {
              areaColor: "#d8d8d9",
              // borderWidth: 0,
              // color: 'green'
            },
          },
        },

        tooltip: {
          trigger: "item",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            if (args.seriesType == "map") return;

            args.value[0];

            let obj = data.find((node) => {
              return node.lon == args.value[0] && node.lat == args.value[1];
            });

            let marker1 = '<span style="color:#666666;">资产名称：</span>';
            let marker2 = '<span style="color:#666666;">资产类型：</span>';
            let marker3 = '<span style="color:#666666;">所在省份：</span>';
            let marker4 = '<span style="color:#666666;">所在城市：</span>';
            let marker5 = '<span style="color:#666666;">用地性质：</span>';
            let marker6 = '<span style="color:#666666;">上市时间：</span>';
            let marker7 =
              '<span style="color:#666666;">最新估值（亿元）：</span>';
            let marker8 = '<span style="color:#666666;">运营到期时间：</span>';
            let marker9 = '<span style="color:#666666;">股票代码：</span>';
            let marker10 = '<span style="color:#666666;">股票简称：</span>';

            let str = "";
            str +=
              // str += `${marker}${args.seriesIndex}`;

              str += `<span style=\"font-weight: bolder;\">${obj["资产名称"]}</span>`;
            str += "<br>";

            str += `${marker9} <span style=\"color:#787878 ;\">${obj["reit_code"]}</span>`;
            str += "<br>";

            str += `${marker10} <span style=\"color:#787878 ;\">${obj["REITs_简称"]}</span>`;
            str += "<br>";

            str += `${marker2} <span style=\"color:#787878 ;\">${obj["资产类型"]}</span>`;
            str += "<br>";
            str += `${marker3} <span style=\"color:#787878 ;\">${obj["所在省份"]}</span>`;
            str += "<br>";
            str += `${marker4} <span style=\"color:#787878 ;\">${obj["所在城市"]}</span>`;
            str += "<br>";
            str += `${marker5} <span style=\"color:#787878 ;\">${obj["用地性质"]}</span>`;
            str += "<br>";
            str += `${marker8} <span style=\"color:#787878 ;\">${obj["运营到期时间"]}</span>`;
            str += "<br>";
            str += `${marker7} <span style=\"color:#787878 ;\">${obj["最新估值（亿元）"]}</span>`;
            str += "<br>";
            str += `${marker6} <span style=\"color:#787878 ;\">${obj["上市时间"]}</span>`;

            str += "<br>";

            return str;
          },
        },

        legend: {
          orient: "vertical",
          top: "middle",
          icon: "circle",
          right: this.ROOTFONTFIZE * (10 / 144),
          itemGap: this.ROOTFONTFIZE * (20 / 144),
         
          itemStyle: {
            // color: "inherit",
          },
           textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          

          align: "left",
          data: NAME,
        },
        color: color,
        // color:['pink'],

        series: seriesArr,

        visualMap: {
          show: false,
          // type: "piecewise",
          // orient: "horizontal",
          min: 0,
          max: 20,
          seriesIndex: 0,
          // itemWidth: 30,
          // itemHeight: 10,
          inRange: {
            color: ["#ffffff", "#a4a5a7"], // 控制颜色渐变的范围
          },
          // left: 70,
          // bottom: 90,
          // text: ["loss", "gain"],
          // textStyle: {
          // color: "rgba(218, 218, 218, 1)",
          // fontSize: 16,
          // top: 50,
          // },
          calculable: false, // 出现滑块
        },
      };

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },

    async fnGetRpDateAssetValue() {
      // let start_date = 10;

      const { data } = await this.$https.get("/api/rp_date_asset_value");

      if (data.code !== 200) return this.$message.error(data.msg);
      this.dRpDateAssetValue = data.data;
      this.dRpDateAssetValue_down = data.data;

      this.json_fields9_1 = {
        日期: "tr_date",
        类型: "REITs_type_L2",
        估值总额: "data",
      };

      // 时间筛选
      let levelName = window.sessionStorage.getItem("levelName");
       if (levelName == "普通客户") {
          let result = fnComputeTime("3M");
          this.dRpDateAssetValue_down = this.dRpDateAssetValue_down.filter((node) => {
          return node["tr_date"] >= result;
        });
       }else{
          let result = fnComputeTime("6M");
          this.dRpDateAssetValue_down = this.dRpDateAssetValue_down.filter((node) => {
          return node["tr_date"] >= result;
        });
       }



      // 下载end

      // 对数组进行分类
      // 1. 找出key
      let keys = this.dRpDateAssetValue.map((val) => {
        return val.REITs_type_L2;
      });
      keys = [...new Set(keys)];

      // 2. 根据key，设置数组
      let arr1 = [];
      keys.forEach((node) => {
        let obj = {
          name: "",
          data: [],
        };

        this.dRpDateAssetValue.forEach((e) => {
          if (e.REITs_type_L2 == node) {
            obj.name = node;
            obj.data.push(e);
          }
        });

        arr1.push(obj);
      });

      // 3. 完成 漂亮！！！

      // 4. 获取x轴数据

      let arr2 = this.dRpDateAssetValue.map((node) => {
        return node.tr_date;
      });

      arr2 = [...new Set(arr2)];

      // 重点：没有的日期值，置空
      let arr4 = [];
      arr1.forEach((item) => {
        let obj_1 = {
          name: item.name,
          data: [],
        };
        arr2.forEach((node) => {
          let obj_2 = item.data.find((val) => {
            return val.tr_date == node;
          });

          if (obj_2) {
            let val = obj_2.data / 10000;
            obj_1.data.push(val);
          } else {
            obj_1.data.push(0);
          }
        });
        arr4.push(obj_1);
      });
      // 完成,漂亮 !!!

      // 排序
      let JYQL = [];
      let CQL = [];

      arr4.forEach((node) => {
        this.CATEGORYDATA.forEach((e, idx) => {
          if (e.name == node.name || e.data.includes(node.name)) {
            if (!idx) {
              CQL.push(node);
            } else {
              JYQL.push(node);
            }
          }
        });
      });

      arr4 = [...CQL, ...JYQL];

      this.dRpDateAssetValue_X = arr2;
      this.dRpDateAssetValue_ARRAY = arr4;
      this.$nextTick(() => {
        this.initChart2();
      });
    },
    initChart2(val) {

      if (!val) {
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      } else {
        this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
      }

      // this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
      this.chartColumn2.clear();

      let dRpDateAssetValue_X = this.dRpDateAssetValue_X;
      let dRpDateAssetValue_ARRAY = this.dRpDateAssetValue_ARRAY;

      let name = dRpDateAssetValue_ARRAY.map((node) => {
        return node.name;
      });

      let ORDER = JSON.parse(localStorage.getItem("ORDER"));

      let tmp = [];
      ORDER.forEach((node) => {
        let value = name.find((item) => {
          return node == item;
        });
        if (value) {
          tmp.push(value);
        }
      });

      name = tmp;

      let colors = this.$store.state.colors;

      dRpDateAssetValue_ARRAY.forEach((node) => {
        let obj = colors.find((item) => {
          return item.name == node.name;
        });

        node["color"] = obj.value;
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let seriesArr = dRpDateAssetValue_ARRAY.map((v, index) => {
        return {
          name: v.name,
          data: v.data,
          type: "line",
          smooth: false,
          symbol: "none",
          itemStyle: {
            color: v.color,
            opacity: theme == "dark" ? 0.5 : lightOpacity,
          },

          label: {
            show: false,
            position: "top",
          },
          areaStyle: {
            color: v.color,
            opacity: theme == "dark" ? 0.5 : lightOpacity,
          },
          stack: "all",
        };
      });

      let chartWidth = document.getElementById("chart").offsetWidth;
      let option = {
        legend: {
          show: true,
          orient: "horizontal",
          width: chartWidth > 770 ? "auto" : this.ROOTFONTFIZE * (590 / 144),
          left: this.ROOTFONTFIZE * (20 / 144),
          // right: this.ROOTFONTFIZE * (500 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          align: "left",
          // x:50,
          // x2: 150,
          icon: "roundRect",
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (30 / 144),
          itemHeight: this.ROOTFONTFIZE * (10 / 144),
          padding: [0, 0, 0, 0],
          // lineStyle: {
          //   color: "inherit",
          // },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.legend.textStyle.fontSize / 144),
          },

          data: name,
          // color: ['#c23531','#5754f7','#a84d38','#ff7432']
        },
        tooltip: {
          trigger: "axis",
          triggerOn: "mousemove|click",
          formatter: function (args) {
            let str = "";
            str += args[0].axisValueLabel;
            str += "<br>";
            args.forEach((node) => {
              let num =
                (parseInt(Math.abs(node.value)) + "").length >= 2 ? 1 : 2;
              let val = Number(node.value).toFixed(num);
              str += `${node.marker}${node.seriesName}：${val}`;
              str += "<br>";

              return str;
            });
            return str;
          },
          // formatter: function (args) {
          //   //  args.map(node=>{
          //   //   node.value = node.value.toFixed(2)
          //   // })

          //   // console.log(args,'args1');
          //   return args;
          // },
        },
        // color: colorArr,
        grid: {
          left: this.ROOTFONTFIZE * (20 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          top: this.ROOTFONTFIZE * (105 / 144),
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.fontSize / 144),
            show: true,
            showMaxLabel: true,
            margin:
              this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.margin / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: dRpDateAssetValue_X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          name: "单位(亿元)",
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          scale: true,
          type: "value",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              args = Number(args).toFixed(num);
              return args;
            },
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },

        series: seriesArr,
      };

      this.chartColumn2.setOption(option);
      this.chartColumn2.resize();
      // this.funResize()
    },

    async fnGetLastAssetValue() {
      // let start_date = 10;

      const { data } = await this.$https.get("/api/last_asset_value");

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dLastAssetValue = data.data;
      this.$nextTick(() => {
        this.initChart3();
      });
      // this.initChart1();
    },
    initChart3(val) {

      if (!val) {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      } else {
        this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3_dialog);
      }

      // this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);

      let dLastAssetValue = this.dLastAssetValue;
      // 排序
      let ORDER = JSON.parse(localStorage.getItem("ORDER"));

      let FINALLYDATA = [];
      ORDER.forEach((node) => {
        if (dLastAssetValue[node]) {
          let obj = {
            name: node,
            value: dLastAssetValue[node],
          };
          FINALLYDATA.push(obj);
        }
      });

      let colors = this.$store.state.colors;
      let NAME = FINALLYDATA.map((node) => {
        return node.name;
      });

      let colorArr = [];
      NAME.forEach((e) => {
        colors.forEach((node) => {
          if (e == node.name) {
            colorArr.push(node.value);
          }
        });
      });

      let OPTIONDATA =
        this.$store.state.theme == "dark"
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;

      let chartWidth = document.getElementById("chart").offsetWidth;

      // orient: "vertical",horizontal
      let option = {
        legend: {
          left: this.ROOTFONTFIZE * (20 / 144),
          top: this.ROOTFONTFIZE * (20 / 144),
          width: Number(chartWidth) > 650 ? 'auto' : this.ROOTFONTFIZE * (500 / 144),
          // width: this.ROOTFONTFIZE * (500 / 144),
          align: "left",

          // right: this.ROOTFONTFIZE * (30 / 144),
          itemGap: this.ROOTFONTFIZE * (20 / 144),
          orient: "horizontal",
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize:
              this.ROOTFONTFIZE * (OPTIONDATA.legend.textStyle.fontSize / 144),
          },
        },
        color: colorArr,
        grid: {
          left: 0,
        },
        series: [
          {
            type: "pie",
            left: 0,
            top: 50,
            itemStyle: {
              opacity: theme == "dark" ? 0.5 : lightOpacity,
            },

            radius: ["40%", "70%"],
            avoidLabelOverlap: true,

            label: {
              show: false,
              position: "center",
              color: OPTIONDATA.legend.textStyle.color,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: this.ROOTFONTFIZE * (40 / 144),
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: FINALLYDATA.map((node) => {
              return {
                value: node.value,
                name: node.name,
                label: {
                  normal: {
                    show: true,
                    position: "outside",
                    formatter: "{d}%",
                  },
                },
              };
            }),
            // data: [
            //   {
            //     value: dLastAssetValue[NAME[0]],
            //     name: NAME[0],
            //     label: {
            //       normal: {
            //         show: true,
            //         position: "outside",
            //         formatter: "{d}%",
            //       },
            //     },
            //   },
            //   {
            //     value: dLastAssetValue[NAME[1]],
            //     name: NAME[1],
            //     label: {
            //       normal: {
            //         show: true,
            //         position: "outside",
            //         formatter: "{d}%",
            //       },
            //     },
            //   },
            //   {
            //     value: dLastAssetValue[NAME[2]],
            //     name: NAME[2],
            //     label: {
            //       normal: {
            //         show: true,
            //         position: "outside",
            //         formatter: "{d}%",
            //       },
            //     },
            //   },
            //   {
            //     value: dLastAssetValue[NAME[3]],
            //     name: NAME[3],
            //     label: {
            //       normal: {
            //         show: true,
            //         position: "outside",
            //         formatter: "{d}%",
            //       },
            //     },
            //   },
            //   {
            //     value: dLastAssetValue[NAME[4]],
            //     name: NAME[4],
            //     label: {
            //       normal: {
            //         show: true,
            //         position: "outside",
            //         formatter: "{d}%",
            //       },
            //     },
            //   },
            //   {
            //     value: dLastAssetValue[NAME[5]],
            //     name: NAME[5],
            //     label: {
            //       normal: {
            //         show: true,
            //         position: "outside",
            //         formatter: "{d}%",
            //         color: "#fff",
            //       },
            //     },
            //   },
            // ],
          },
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },
  },
  components: {
    CButtonsDownload,
    CDialog,
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      fnDownloadBgc();
      this.$nextTick(() => {
        this.initChart1();
        this.initChart2();
        this.initChart3();
      });
    },
  },
};
</script>

<style scoped>
.container {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  width: 100% !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  padding-right: 20px !important;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 1);
}

.AREA {
  padding: 0 !important ;
  display: flex;
  /* height: calc(var(--ROOTFONTFIZE) * (1200 / 144)); */
  background-color: var(--bigger-card-bgc);
}

.left_area {
  flex: 7;
  /* flex: 5; */

  /* height: calc(var(--ROOTFONTFIZE) * (820 / 144)); */
  box-sizing: border-box;
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144)) !important; */
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.right_area {
  /* margin-left: 10px; */
  flex: 5;
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-left: 0;
  padding-right: 0 !important;
  padding-top: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right_area_top {
  /* margin-bottom: 20px; */
}

.left,
.right {
  height: calc(var(--ROOTFONTFIZE) * (560 / 144));
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  box-sizing: border-box;
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.top {
  width: 100%;
  height: calc(var(--ROOTFONTFIZE) * (470 / 144));
  border-radius: 20px;
  background: rgba(29, 31, 37, 1);
}

.bottom {
  display: flex;
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.bottom .left,
.bottom .right {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (430 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom .right {
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.tit {
  /* margin-top: 15px; */
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  letter-spacing: 0px;
  color: var(--chart_title);
  /* border-left: 5px solid rgba(21, 115, 254, 1); */
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);

  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));

  display: flex;
  justify-content: space-between;
}

.pieAreaPack {
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.pieArea {
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border: 0.2px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  position: relative;
}

.map {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--charts_bgc);
}

.canvasArea {
}
</style>
