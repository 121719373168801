<template>
  <div id="rootchart" :style="cssVars" class="container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="近期热点" name="first">
        <div class="RecentHtspots">
          <div class="RecentHtspots_item">
            <div class="imgArea">
              <img
                src="https://img.js.design/assets/smartFill/img352164da74c4b8.jpeg"
                alt=""
              />
            </div>
            <div class="info">
              <span>我们从分享时...</span>
              <span class="more">
                MORE
                <i class="el-icon-d-arrow-right"></i>
              </span>
            </div>
          </div>

          <div class="RecentHtspots_item">
            <div class="imgArea">
              <img
                src="https://img.js.design/assets/smartFill/img352164da74c4b8.jpeg"
                alt=""
              />
            </div>
            <div class="info">
              <span>作为用户和...</span>
              <span class="more">
                MORE
                <i class="el-icon-d-arrow-right"></i>
              </span>
            </div>
          </div>

          <div class="RecentHtspots_item">
            <div class="imgArea">
              <img
                src="https://img.js.design/assets/smartFill/img352164da74c4b8.jpeg"
                alt=""
              />
            </div>
            <div class="info">
              <span>用户主动点击分享...</span>
              <span class="more">
                MORE
                <i class="el-icon-d-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="IPOAREA">
          <div class="comingIPO">近期即将上市项目</div>
          <!-- :height="
              dPreipoList.length == 0
                ? null
                : 100 + (dPreipoList.length + 1) * 35
            " -->
          <el-table
            class="table1"
            :header-cell-style="styleBindFun"
            :data="dPreipoList"
            @row-click="fnCell"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              align="center"
              width="100"
              type="index"
            >
            </el-table-column>

            <el-table-column prop="基金代码" label="基金代码" align="left">
            </el-table-column>

            <el-table-column
              align="left"
              prop="REITs名称"
              label="REITs名称"
              width="450"
            >
            </el-table-column>
            <el-table-column align="center" prop="资产大类" label="资产大类">
            </el-table-column>

            <el-table-column align="center" prop="资产细分" label="资产细分">
            </el-table-column>
            <el-table-column align="center" prop="上市状态" label="上市状态">
            </el-table-column>
            <el-table-column align="center" prop="发行类型" label="发行类型">
            </el-table-column>
            <el-table-column
              align="left"
              prop="原始权益人"
              label="原始权益人"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="left"
              prop="基金管理人"
              label="基金管理人"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>

          <el-pagination
            @current-change="handledPreipoList"
            :page-size="10"
            layout="total,  prev, pager, next, jumper"
            :total="dPreipoListTotal"
          >
          </el-pagination>

          <div class="comingIPO mt20">其余等待上市项目</div>
          <el-table
            class="table1"
            @row-click="fnCell2"
            :header-cell-style="styleBindFun"
            :data="dAuditList"
            style="width: 100%"
          >
            <el-table-column
              align="center"
              label="序号"
              width="100"
              type="index"
            >
            </el-table-column>
            <el-table-column
              width="400"
              align="left"
              prop="REITs名称"
              label="REITs名称"
            >
            </el-table-column>
            <el-table-column align="center" prop="资产大类" label="资产大类">
            </el-table-column>

            <el-table-column align="center" prop="资产细分" label="资产细分">
            </el-table-column>
            <el-table-column align="center" prop="上市状态" label="上市状态">
            </el-table-column>
            <el-table-column align="center" prop="发行类型" label="发行类型">
            </el-table-column>
            <el-table-column
              align="left"
              prop="原始权益人"
              label="原始权益人"
              width="300"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="left"
              prop="基金管理人"
              label="基金管理人"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column align="center" prop="最后更新" label="最后更新">
            </el-table-column>
            <el-table-column align="center" prop="申报日期" label="申报日期">
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handledAuditList"
            :page-size="10"
            layout="total,  prev, pager, next, jumper"
            :total="dAuditListTotal"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="政策动态" name="second">
        <div class="CARD">
          <div class="card_title zhengce">
            <div class="card_left">政策动态</div>
            <div class="zhengceChoose">
              <span>
                <CSelect
                  @CSelect_select_val="fnSelect_announcement"
                  :clearable="false"
                  :auto="true"
                  :options="announcementData"
                  :default="announcementData_default"
                  >公告类型</CSelect
                >
              </span>

              <span>
                <CSelect
                  class="CSelect_authority"
                  ref="CSelect_1"
                  :auto="true"
                  @CSelect_select_val="fnSelect_authority"
                  :clearable="false"
                  :options="authorityData"
                  :default="authorityData_default"
                  >发布机构</CSelect
                >
              </span>
            </div>
          </div>

          <div class="card_body">
            <div
              v-for="(node, idx) in dPolicyListData"
              :key="idx"
              class="card_body_item2"
            >
              <div class="card_body_item2_top">
                <span>{{ node.fileSource }}</span>

                <span class="doc_number">{{ node.doc_number }}</span>
              </div>
              <div class="card_body_item2_bottom">
                <span
                  @click="fnClick3(node)"
                  class="card_body_item2_span"
                  :style="{
                    'font-weight':
                      node.policyType == '重要政策' ? 'bolder' : 'normal',
                  }"
                >
                  {{ node.cnName }}</span
                >
                <span>{{ node.publishDate }}</span>
              </div>
            </div>

            <!-- color:
                      node.policyType == '重要政策'
                        ? '#fff'
                        : 'rgba(246, 246, 246, 0.8)', -->

            <!-- <div class="card_body_item">
                <span>所以监听用户的截图操作，提...</span>
                <span>2023-07-07</span>
              </div>

              <div class="card_body_item">
                <span>用户分享内容到社交媒体或好友，不...</span>
                <span>2023-07-07</span>
              </div> -->
          </div>

          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size1"
            layout="total,  prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination>

          <!-- <div class="card_more">
               <span class="more">
               MORE
               <i class="el-icon-d-arrow-right"></i>
            </span>
            </div>   -->
        </div>
      </el-tab-pane>
      <el-tab-pane label="市场快讯" name="third">
        <div v-if="CARDDETAIL" class="CARD">
          <div class="card_title">
            <div class="card_left">快讯动态</div>
            <!-- <div class="card_right">
              <span>公告类型</span>
              <span><CSelect /></span>
            </div> -->
          </div>
          <!-- 之前的 start -->
          <!-- <div class="card_body">


            <div  v-for="(node,idx) in dNewsList" :key="idx">
              <div v-if="node['newsTitle'].length<50" class="card_body_item">
              <span  @click="fnNewsDetail(node)"  class="card_body_item_title">{{node.newsTitle}}</span>
              <span class="card_body_item_date">{{node.publishDate}}</span>
              </div>

              <div v-else class="QuickNews">
                <span class="QuickNews_info_area">
                  <div class="QuickNews_info">快讯</div></span>
                <span class="QuickNews_main">
                  <span @click="fnNewsDetail(node)" class="QuickNews_main_tit">{{node.newsTitle}}</span>
                  <span class="QuickNews_main_date">{{node.publishDate}}</span>
                </span>
              </div>

            </div>

          </div> -->
          <!-- 之前的 end -->

          <!-- 最新的 start -->
          <div class="card_body">
            <!-- :color="activity.color" -->
            <!-- :size="activity.size" -->
            <!-- <el-timeline>
    <el-timeline-item
      v-for="(activity, index) in dNewsList"
      :key="index"
      :size="mini"
      :timestamp="activity.publishDate">  
      {{activity.newsTitle}}
    </el-timeline-item>
  </el-timeline> -->
            <div class="item" v-for="(node, idx) in dNewsList" :key="idx">
              <div
                class="time"
                :class="node['newsType'] == '快讯' ? 'kuaixunColor' : ''"
              >
                {{ node.publishDate }}
              </div>
              <div class="content" @click="fnNewsDetail(node)">
                <div v-show="node['newsType'] == '快讯'" class="kuaixun">
                  快讯
                </div>
                <div class="contentArea">
                  {{ node.newsTitle }}
                </div>
                <div
                  class="circle"
                  :class="node['newsType'] == '快讯' ? 'kuaixunBgColor' : ''"
                ></div>
              </div>
            </div>
          </div>

          <!-- 最新的 start -->

          <el-pagination
            @current-change="handleCurrentChange3"
            :page-size="size_newsList"
            layout="total,  prev, pager, next, jumper"
            :total="total_newsList"
          >
          </el-pagination>
        </div>

        <div v-else class="CARDDETAIL">
          <div class="CARDDETAIL_top">
            <div class="CARDDETAIL_tit">
              <span v-if="dNewsDetail_quickNewsTit">{{
                dNewsDetail_quickNewsTit
              }}</span>
              <span v-else>{{ dNewsDetail.newsTitle }}</span>

              <!-- <span >{{dNewsDetail.newsTitle}}</span>               -->

              <el-button @click="fnBack()" class="backBtn_style" size="small"
                >返回</el-button
              >
            </div>
            <div class="CARDDETAIL_date">
              来源: {{ dNewsDetail.newsSource }}
              <span class="ml30">{{
                new Date(dNewsDetail.update_date).toLocaleString().split(" ")[0]
              }}</span>
            </div>
          </div>
          <div class="CARDDETAIL_main">
            <div
              v-for="(node, idx) in newsContentData"
              :key="idx"
              class="newsContentData_item"
            >
              {{ node }}
            </div>

            <!-- {{dNewsDetail.newsContent}} -->
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="定期报告" name="fourth">
        <div class="CARD">
          <div class="card_title">
            <div class="card_left">定期报告</div>
            <!-- <div class="card_right">
                <span>
                    <CSelect
                    class="CSelect_1"
              ref="CSelect_1"
              @CSelect_select_val="fnSelect1"
              :clearable="false"
              :options="announcementData"
              :default="announcementData_default"
              >报告类型</CSelect> 
                </span>
            </div> -->
          </div>
          <div class="card_body">
            <div
              v-for="(node, idx) in dqSubject"
              :key="idx"
              class="card_body_item2"
            >
              <!-- <div class="card_body_item2_top">

                <span>{{node.fileSource}}</span>
              </div> -->
              <div class="card_body_item2_bottom">
                <span
                  @click="fnClick_subjectdingqi(node)"
                  class="card_body_item2_span"
                >
                  {{ node.name }}</span
                >
                <span>{{ node.date }}</span>
              </div>
            </div>
          </div>

          <!-- <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size1"
            layout="total,  prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination> -->

          <!-- <div class="card_more">
               <span class="more">
               MORE
               <i class="el-icon-d-arrow-right"></i>
            </span>
            </div>   -->
        </div>
        <!-- <div class="CARD">
          <div class="card_title">
            <div class="card_left">定期报告</div>
            <div class="card_right">
                <span>公告类型</span>
                <span><CSelect /></span>
              </div>
          </div>
          <div class="card_body">
            <div
              class="card_body_item"
              v-for="(node, idx) in dFileFolderList"
              :key="idx"
            >
              <span>{{ node.fileTitle }}</span>

              <span @click="fnClick2(node)" class="iconfont icon-pdf"></span>
              <span>{{ node.publishDate }}</span>
            </div> -->

        <!-- <div class="card_body_item">
                <span>作为用户和产品之间的桥梁...</span>
                <span class="iconfont icon-pdf"></span>
                <span>2023-07-07</span>
              </div>

              <div class="card_body_item">
                <span>用户分享内容到社交媒体或...</span>
                <span class="iconfont icon-pptwenjian_file-ppt"></span>
                <span>2023-07-07</span>
              </div> -->
        <!-- </div>

          <el-pagination
            @current-change="handleCurrentChange2"
            :page-size="size2"
            layout="total,  prev, pager, next, jumper"
            :total="total2"
          >
          </el-pagination> -->
        <!-- <div class="card_more">
               <span class="more">
               MORE
               <i class="el-icon-d-arrow-right"></i>
            </span>
            </div>   -->
        <!-- </div> -->
      </el-tab-pane>
      <el-tab-pane label="深度专题" name="fivth">
        <div class="CARD">
          <div class="card_title">
            <div class="card_left">深度专题</div>
            <!-- <div class="card_right">
                <span>
                    <CSelect
                    class="CSelect_1"
              ref="CSelect_1"
              @CSelect_select_val="fnSelect1"
              :clearable="false"
              :options="announcementData"
              :default="announcementData_default"
              >报告类型</CSelect> 
                </span>
            </div> -->
          </div>
          <div class="card_body">
            <div
              v-for="(node, idx) in dSubject"
              :key="idx"
              class="card_body_item2"
            >
              <!-- <div class="card_body_item2_top">

                <span>{{node.fileSource}}</span>
              </div> -->
              <div class="card_body_item2_bottom">
                <span
                  @click="fnClick_subject(node)"
                  class="card_body_item2_span"
                >
                  {{ node.name }}</span
                >
                <span>{{ node.date }}</span>
              </div>
            </div>
          </div>

          <!-- <el-pagination
            @current-change="handleCurrentChange"
            :page-size="size1"
            layout="total,  prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination> -->

          <!-- <div class="card_more">
               <span class="more">
               MORE
               <i class="el-icon-d-arrow-right"></i>
            </span>
            </div>   -->
        </div>

        <div v-show="false">
          <!-- <iframe width="100%" height="1300" src="../../../assets/测试.pdf" frameborder="0"></iframe> -->
          <iframe
            width="90%"
            height="1300"
            src="http://demo.uii.net:8055/web/conghua/深度专题.html"
          ></iframe>
        </div>

        <!-- <div class="CARD">
          <div class="card_title">
            <div class="card_left">深度专题</div>
            <div class="card_right">
              <span>公告类型</span>
              <span><CSelect /></span>
            </div>
          </div>
          <div class="card_body">
            <div class="card_body_item">
              <span>在常用的社交分享组件中，...</span>
              <span class="iconfont icon-file-excel"></span>
              <span>2023-07-07</span>
            </div>

            <div class="card_body_item">
              <span>下面我们会从不同维度来分析...</span>
              <span class="iconfont icon-pdf"></span>
              <span>2023-07-07</span>
            </div>

            <div class="card_body_item">
              <span>尤其是刚上线的产品，很难通过产品的内部...</span>
              <span class="iconfont icon-pptwenjian_file-ppt"></span>
              <span>2023-07-07</span>
            </div>
          </div>
          <div class="card_more">
            <span class="more">
              MORE
              <i class="el-icon-d-arrow-right"></i>
            </span>
          </div>
        </div> -->
      </el-tab-pane>
    </el-tabs>

    <CDialog ref="CDialog" :dialogVisible="dialogVisible">
      <el-dialog
        title="新闻公告"
        :visible.sync="dialogVisible"
        width="80%"
        top="5vh"
      >
        <div class="announcement">
          <div v-if="dFileFolderListSingle.length == 0">
            <el-empty description="暂无数据"></el-empty>
          </div>

          <div
            v-else
            class="Announcement_item"
            v-for="(node, idx) in dFileFolderListSingle"
            :key="idx"
          >
            <div class="Announcement_item_left">
              <div @click="fnClick(node)" class="Announcement_item_left_two">
                {{ node.web_show_str }}
              </div>
              <div class="Announcement_item_left_three">
                {{ node.pub_date }}
              </div>
            </div>
            <div class="Announcement_item_right">
              <span
                >{{ node.folder_l1 | folderL1_Filter }} -
                {{ node.folder_l2 | folderL2_Filter }}</span
              >
              <span @click="fnClick(node)" class="iconfont icon-pdf"></span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            class="frontBtn"
            size="small"
            @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelect from "@/components/Basic/CSelect";
import CCascader from "@/components/Basic/CCascader";
import CDialog from "@/components/Basic/CDialog";

import { fnReturnTableStyle } from "@/utils/util";

// import VuePpt from 'vue-ppt'

export default {
  components: {
    CSelect,
    CCascader,
    CDialog,
    // VuePpt
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      // this.$eventBus.$emit("setBreadcrumbs", "近期热点");
    });
    this.fnGetPolicyList();
    // this.fnGetFileFolderList();

    this.fnGetNewsList();
    this.fnGetPreipoList();
    this.fnGetSpecialFocus();
    this.fnGetAuditList();
    let name = localStorage.getItem("activeName");
    this.handleClick({ name: name });
  },
  mounted() {
    this.fnGetWidth();
  },
  data() {
    return {
      activeName: "first",
      Folder_L3: this.$store.state.Folder_L3,
      Folder_L3_default: "all",
      // 政策 start
      dPolicyListData: [],
      dPolicyListDataFinally: [],
      current1: 1,
      total1: 0,
      size1: 0,
      announcementData: [],
      announcementData_default: "",
      authorityData: [],
      authorityData_default: "",
      zhengceFlAG: true,
      // 政策 end

      dFileFolderList: [],
      total2: 0,
      size2: 0,

      // 市场快讯 start
      dNewsList: [],
      dNewsList_finally: [],
      total_newsList: 0,
      current_newsList: 0,
      size_newsList: 0,
      CARDDETAIL: true,
      dNewsDetail: [],
      dNewsDetail_quickNewsTit: null,
      newsContentData: [],
      // 市场快讯 end

      // 近期热点 start
      dPreipoList: [],
      dPreipoListFinally: [],
      dPreipoListTotal: 0,
      dAuditList: [],
      dAuditListFinally: [],
      dAuditListTotal: 0,
      dFileFolderListSingle: [],
      dialogVisible: false,
      codeSingle: "",
      audit_id_Single: "",
      audit_id_Single2: "",
      default_Folder_L1: "ALL",
      default_Folder_L2: "all",
      currentDialog: "one",

      // 近期热点 end

      // 深度专题 start
      dSubject: [
        // {
        //   name: "【深度篇】2023年首批消费基础设施专题回顾",
        //   date: "2024-04-19",
        // },
        // {
        //   name: "葱花投研专题-REITs常态化发行之1014号文解读",
        //   date: "2024-07-29",
        // },
      ],
      dqSubject: [
        // {
        //   name: "REITs管理费数据(2023H1)_v2",
        //   date: "2024-05-11",
        // },
      ],
      // 深度专题 end

      ROOTFONTFIZE: 0,
      TABPANEHEIGHT: 0,
    };
  },
  methods: {
    // 深度专题
    async fnGetSpecialFocus() {
      this.dSubject = [];
      this.dqSubject = [];
      const res = await this.$https.get("/api/conghua/special_focus/list");
      console.log("dSubject",res);
      if(res.status==200){
        res.data.forEach(item=>{
          if(item.type=='theme'){
            this.dSubject.push({
              name:item.title,
              date:item.create_time,
              url:item.url
            });
          }
          if(item.type=='report'){
            this.dqSubject.push({
              name:item.title,
              date:item.create_time,
              url:item.url
            });
          }
        });

      }
    },
    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById("rootchart");
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      let OFFSETHEIGHT = element.offsetHeight;
      // this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      this.TABPANEHEIGHT = localStorage.getItem("TABPANEHEIGHT");

      // var chartColumn1 = document.getElementById("chartColumn1");
      // chartColumn1.style.height = this.ROOTFONTFIZE * (371 / 144) + "px";

      // var chartColumn2 = document.getElementById("chartColumn2");
      // chartColumn2.style.height = this.ROOTFONTFIZE * (360 / 144) + "px";

      // var chartColumn4 = document.getElementById("chartColumn4");
      // chartColumn4.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn5 = document.getElementById("chartColumn5");
      // chartColumn5.style.height = this.ROOTFONTFIZE * (450 / 144) + "px";

      // var chartColumn3 = document.getElementById("chartColumn3");
      // chartColumn3.style.height = this.ROOTFONTFIZE * (358 / 144) + "px";

      return;
    },

    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == "dark" ? true : false;
      return fnReturnTableStyle(val);
    },

    handleClick(e) {
      this.activeName = e.name;
      this.$eventBus.$emit("setBreadcrumbs", e.label ? e.label : "近期热点");
      localStorage.setItem("activeName", this.activeName);
    },

    // 近期热点 start
    // 1.
    async fnGetPreipoList() {
      const res = await this.$https.get("/api/preipoList");

      if (res.data.success !== true)
        return this.$message.error("获取数据失败！");

      if (res.data.data == "目前没有快上市项目") {
        this.dPreipoListFinally = [];
      } else {
        this.dPreipoListFinally = res.data.data;
      }

      this.dPreipoListTotal = this.dPreipoListFinally.length;
      // 分页 start
      this.dPreipoList = this.dPreipoListFinally.filter((node, idx) => {
        return idx >= 0 && idx < 10;
      });
    },

    handledPreipoList(val) {
      let start = (val - 1) * 10;
      let end = start + 10;
      // 分页 start
      this.dPreipoList = this.dPreipoListFinally.filter((node, idx) => {
        return idx >= start && idx < end;
      });
    },

    // 1.
    async fnGetAuditList() {
      const res = await this.$https.get("/api/auditList");

      if (res.data.success !== true)
        return this.$message.error("获取数据失败！");
      this.dAuditListFinally = res.data.data;
      function compare(val1, val2) {
        return new Date(val2["最后更新"]) - new Date(val1["最后更新"]);
      }
      this.dAuditListFinally.sort(compare);
      this.dAuditListTotal = this.dAuditListFinally.length;
      // this.dAuditList = this.dAuditListFinally
      // 分页 start
      this.dAuditList = this.dAuditListFinally.filter((node, idx) => {
        return idx >= 0 && idx < 10;
      });
    },

    handledAuditList(val) {
      let start = (val - 1) * 10;
      let end = start + 10;
      // 分页 start
      this.dAuditList = this.dAuditListFinally.filter((node, idx) => {
        return idx >= start && idx < end;
      });
    },

    fnCell(data) {
      this.currentDialog = "one";
      this.codeSingle = data["基金代码"];
      this.audit_id_Single = data["audit_id"];

      this.fnGetFileFolderListSingle();
    },

    async fnGetFileFolderListSingle() {
      let code = this.codeSingle;
      let audit_id = this.audit_id_Single;

      let folderL1 =
        this.default_Folder_L1 == "ALL" ? "" : this.default_Folder_L1;
      let folderL2 =
        this.default_Folder_L2 == "all" ? "" : this.default_Folder_L2;

      const res = await this.$https.post(
        "/api/fileFolderList" +
          "?code=" +
          code +
          "&folderL1=" +
          folderL1 +
          "&folderL2=" +
          folderL2 +
          "&audit_id=" +
          audit_id
      );

      if (res.success == true) return this.$message.error("获取数据失败");

      this.dFileFolderListSingle = res.data.data;
      this.dialogVisible = true;
    },

    // 等待上市
    fnCell2(data) {
      this.currentDialog = "two";
      this.audit_id_Single2 = data["audit_id"];
      this.fnGetFileFolderListSingle2();
    },
    async fnGetFileFolderListSingle2() {
      let code = this.audit_id_Single2;

      let folderL1 =
        this.default_Folder_L1 == "ALL" ? "" : this.default_Folder_L1;
      let folderL2 =
        this.default_Folder_L2 == "all" ? "" : this.default_Folder_L2;

      const res = await this.$https.post(
        "/api/fileFolderList" +
          "?code=" +
          code +
          "&folderL1=" +
          folderL1 +
          "&folderL2=" +
          folderL2
      );

      if (res.success == true) return this.$message.error("获取数据失败");

      this.dFileFolderListSingle = res.data.data;
      this.dialogVisible = true;
    },

    fnCCascader(val) {
      this.default_Folder_L1 = val[0];
      this.default_Folder_L2 = val[1] ? val[1] : "";
      if (this.currentDialog == "one") {
        this.fnGetFileFolderListSingle();
      } else {
        this.fnGetFileFolderListSingle2();
      }
    },

    // 近期热点 end

    async fnGetRpDateAssetValue() {
      // let start_date = 10;

      const { data } = await this.$https.get("/api/rp_date_asset_value");

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dRpDateAssetValue = data.data;

      return;

      this.initChart2();
    },

    // 市场快讯 start
    // 市场快讯
    async fnGetNewsList(val) {
      // let page = val ? val : 1;
      const { data } = await this.$https.post("/api/newsList");

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dNewsList_finally = data.data;
      this.size_newsList = 20;
      this.current_newsList = 1;

      this.dNewsList_finally.forEach((node) => {
        node.publishDate = node.publishDate.slice(
          0,
          node.publishDate.length - 3
        );
      });

      this.fnArraySplice();

      // this.total_newsList = data.data.total;
      // this.size_newsList = data.data.size;
    },

    // 数组切分
    fnArraySplice() {
      let val1 = (Number(this.current_newsList) - 1) * this.size_newsList;
      let val2 = Number(this.current_newsList) * this.size_newsList;
      this.dNewsList = this.dNewsList_finally.slice(val1, val2);
      this.total_newsList = this.dNewsList_finally.length;
    },

    handleCurrentChange3(val) {
      this.current_newsList = val;
      this.fnArraySplice();
    },

    fnBack() {
      this.CARDDETAIL = true;
    },

    async fnNewsDetail(val) {
      let value = val.newsId;
      const { data } = await this.$https.post(
        "/api/newsDetail?newsId=" + value
      );

      if (data.success !== true) return this.$message.error(data.msg);

      this.dNewsDetail = data.data[0];
      if (data.data[0].newsType == "快讯") {
        this.dNewsDetail_quickNewsTit = "葱花快讯";
        // let arr =  data.data.newsTitle.split('【')
        // this.dNewsDetail_quickNewsTit =  arr[1].split('】')
      } else {
        this.dNewsDetail_quickNewsTit = null;
      }

      // this.update_date = this.dNewsDetail.update_date.toLocaleString().split(" ")[0]

      // 内容数组
      this.newsContentData = this.dNewsDetail.newsContent.split("\n");

      this.CARDDETAIL = false;
    },

    // 市场快讯 end

    // 政策动态 -start
    async fnGetPolicyList() {
      // let policyType = this.announcementData_default

      let fileSource = this.authorityData_default;
      let policyType = this.announcementData_default;

      const { data } = await this.$https.post(
        "/api/policyList?fileSource=" + fileSource + "&policyType=" + policyType
      );

      if (data.success !== true) return this.$message.error(data.msg);

      this.dPolicyListDataFinally = data.data;

      if (this.zhengceFlAG) {
        // 赋值选择数组1
        let policyType = this.dPolicyListDataFinally.map((node) => {
          return node.policyType;
        });
        policyType = [...new Set(policyType)];
        this.announcementData = policyType.map((node) => {
          return {
            label: node,
            value: node,
          };
        });
        let obj = {
          label: "全部",
          value: "",
        };
        this.announcementData.push(obj);

        // 赋值选择数组2
        let fileSourceData = this.dPolicyListDataFinally.map((node) => {
          return node.fileSource;
        });
        fileSourceData = [...new Set(fileSourceData)];
        this.authorityData = fileSourceData.map((node) => {
          return {
            label: node,
            value: node,
          };
        });

        this.authorityData.push(obj);
        this.zhengceFlAG = false;
      }

      this.size1 = 20;
      this.current1 = 1;
      this.fnArraySplice2();
    },

    // 数组切分
    fnArraySplice2() {
      let val1 = (Number(this.current1) - 1) * this.size1;
      let val2 = Number(this.current1) * this.size1;
      this.dPolicyListData = this.dPolicyListDataFinally.slice(val1, val2);
      this.total1 = this.dPolicyListDataFinally.length;

      // this.dNewsList.forEach(node=>{
      //   node.publishDate = node.publishDate.slice(0,node.publishDate.length-3)
      // })
    },

    fnSelect_announcement(val) {
      this.announcementData_default = val;
      this.fnGetPolicyList();
    },
    fnSelect_authority(val) {
      this.authorityData_default = val;
      this.fnGetPolicyList();
    },

    fnClick(node) {
      let url = node.url;
      window.open(url);
    },

    fnClick3(node) {
      let url = node.fileUrl;
      window.open(url);
    },

    fnClick_subject(node) {
      if(node.url){
        window.open(node.url);
      }
    },

    fnClick_subjectdingqi(node) {
      if(node.url){
        window.open(node.url);
      }
    },

    handleCurrentChange(val) {
      this.current1 = val;
      this.fnArraySplice2(val);
    },

    fnSelect1(val) {
      this.announcementData_default = val;
      this.fnGetPolicyList();
    },

    // 政策动态 -end

    // 获取文件分类
    async fnGetFileFolderList(val) {
      let page = val ? val : 1;
      const { data } = await this.$https.get(
        "apit/fileFolderList?page=" + page
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dFileFolderList = data.data.records;
      this.total2 = data.data.total;
      this.size2 = data.data.size;
    },

    handleCurrentChange2(val) {
      this.fnGetFileFolderList(val);
    },

    fnClick2(node) {
      let url = node.fileUrl;
      let name = node.reitsName + ".pdf";
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      link.click();
    },
  },

  filters: {
    dateFilter: function (val) {
      let arr = val.split(" ");
      return arr[0];
    },
    folderL1_Filter: function (val) {
      let Folder_L1 = {
        定期公告: "Periodic_Report",
        募集发行: "Issuance",
        底层资产公告: "REITs_Assets",
        基金运营公告: "Fund_Operation",
        其他公告: "Others",
      };
      for (let i in Folder_L1) {
        if (Folder_L1[i] == val) {
          return i;
        }
      }
    },
    folderL2_Filter: function (val) {
      let Folder_L1 = {
        季报: "Quarterly_report",
        年报: "Annual_report",
        中期: "Midterm_report",
        IPO: "IPO",
        IPO附件: "IPO_appendix",
        扩募: "SO",
        扩募附件: "SO_appendix",
        评估报告: "Valuation_report",
        运营数据: "Operational_data",
        收益分配公告: "Div_notice",
        限售及解除限售公告: "Restrictions",
        投资者关系活动公告: "IR",
        风险提示公告: "Risk_Warning",
        "停牌/复牌公告": "Suspension_Resumption",
        增持及回购公告: "Accumulation_Repurchase",
        做市商调整公告: "Market_Maker",
        提示性公告: "Informative_Notice",
      };
      for (let i in Folder_L1) {
        if (Folder_L1[i] == val) {
          return i;
        }
      }
    },
  },
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
        "--TABPANEHEIGHT": this.TABPANEHEIGHT + "px",
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      // this.initChart();
      // this.initChart2();
      // this.initChart3();
      // this.initChart4();
      // this.initChart5();
    },
  },
};
</script>

<style scoped>
.container {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
}

.RecentHtspots {
  /* height: 500px; */
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (150 / 144));
}

.RecentHtspots_item {
  width: 30%;
  /* flex: 1; */
  height: calc(var(--ROOTFONTFIZE) * (208 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (120 / 144));
}

.RecentHtspots_item:last-child {
  margin-right: 0 !important;
}

.imgArea {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.imgArea img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  padding: calc(var(--ROOTFONTFIZE) * (25 / 144))
    calc(var(--ROOTFONTFIZE) * (15 / 144)) 0
    calc(var(--ROOTFONTFIZE) * (15 / 144));
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.info span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: var(--stock_area_item_color1);
}

.more {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(21, 115, 254, 1);
  color: var(--primary-color);
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.el-icon-d-arrow-right {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: bolder;
  color: var(--primary-color);
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

/* card  start ---------------------------------------------------------------------------------- */
.CARD {
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
}
.card_title {
  display: flex;
  justify-content: space-between;
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.zhengce {
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--ROOTFONTFIZE) * (16 / 144));
}

.card_left {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  padding-left: calc(var(--ROOTFONTFIZE) * (15 / 144));
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
}

.card_right {
  display: flex;
  align-items: start;
  padding-bottom: 0 !important;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card_right span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 0px !important;
  color: rgba(255, 255, 255, 1);
}

.CSelect_1 {
  padding-bottom: 0 !important;
}

.card_body {
  /* margin-top: 20px; */
}

.card_body_item2 {
  margin-bottom: calc(var(--ROOTFONTFIZE) * (35 / 144));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.card_body_item2_top {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  font-weight: bolder;
  color: var(--stock_area_item_color2);
}

.card_body_item2_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    rgba(118, 118, 118, 1);
}

.card_body_item2_bottom span:nth-child(1) {
  flex: 9;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: #d1d1d2;
  color: var(--item_color);
  /* color: #000; */
  /* padding-right: 100px; */
  /* color: red; */
}

.card_body_item2_bottom span:nth-child(1):hover {
  cursor: pointer;
}

.card_body_item2_bottom span:nth-child(2) {
  flex: 1;
  text-align: end;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: rgba(255, 255, 255, 1);
  color: var(--item_color);
}

.card_body_item {
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.card_body_item .card_body_item_title {
  /* flex: 9; */
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  /* padding-right: 100px; */
  color: rgba(246, 246, 246, 0.8);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.card_body_item .card_body_item_title:hover {
  cursor: pointer;
}

.card_body_item .card_body_item_date {
  flex: 1;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: rgba(158, 158, 158, 1);
  padding-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    rgba(118, 118, 118, 1);
}

.QuickNews {
  display: flex;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (35 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    rgba(118, 118, 118, 1);
}

.QuickNews_info_area {
  flex: 1;
}

.QuickNews_info {
  width: auto;
  padding: 0px calc(var(--ROOTFONTFIZE) * (10 / 144));
  height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  background-color: var(--primary-color);
  font-size: calc(var(--ROOTFONTFIZE) * (15 / 144));
  font-weight: bolder;
  letter-spacing: 0.1px;
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  text-align: center;
  /* padding-right: 100px; */
  color: #fff;
}

.QuickNews_main {
  flex: 30;
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  /* padding-right: 100px; */
  color: rgba(246, 246, 246, 0.8);
  border-left: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    rgba(118, 118, 118, 1);
}

.QuickNews_main_date {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: rgba(158, 158, 158, 1);
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.QuickNews_main_tit:hover {
  cursor: pointer;
}

.card_more {
  text-align: right;
  padding-right: calc(var(--ROOTFONTFIZE) * (25 / 144));
}

/*  */
.CARDDETAIL {
  padding: calc(var(--ROOTFONTFIZE) * (30 / 144));
  background-color: var(--detail_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.CARDDETAIL_top {
  padding-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid #4b4c4e;
}

.CARDDETAIL_tit {
  color: var(--detail_tit_color);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  display: flex;
  justify-content: space-between;
}

.CARDDETAIL_date {
  color: var(--detail_date_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.CARDDETAIL_main {
  color: var(--detail_main_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  /* text-indent: 2em; */
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  letter-spacing: 0.1;
  white-space: pre-wrap;
}

.newsContentData_item {
  text-indent: 2em;
  /* margin-bottom: 5px; */
}

.zhengceChoose {
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.CSelect_authority {
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

/* card  end ---------------------------------------------------------------------------------- */

/* 快讯 */
.item {
  display: flex;
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.time {
  color: var(--kuaixun_color);
  padding-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.content {
  color: var(--kuaixun_color2);
  border-left: calc(var(--ROOTFONTFIZE) * (3 / 144)) solid var(--kuaixun_brc);
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144));
  position: relative;
  width: 80%;
  display: flex;
}

.content:hover {
  cursor: pointer;
  color: var(--primary-color);
  opacity: 0.7;
  /* color: #fefefe; */
}

.circle {
  width: calc(var(--ROOTFONTFIZE) * (12 / 144));
  height: calc(var(--ROOTFONTFIZE) * (12 / 144));
  background-color: var(--kuaixun_circle_bgc);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-60%);
}

.content:hover .circle {
  background-color: var(--primary-color);
  opacity: 0.7;
}

.kuaixun {
  background-color: var(--primary-color);
  color: #fff;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  padding: calc(var(--ROOTFONTFIZE) * (2 / 144))
    calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (2 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  width: calc(var(--ROOTFONTFIZE) * (30 / 144));
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: inline-block;
  white-space: nowrap;
}

.contentArea {
  width: 100%;
  line-height: 1.5;
}

.kuaixunBgColor {
  background-color: var(--primary-color);
}

.kuaixunColor {
  color: var(--primary-color);
}

.doc_number {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  color: var(--doc_number_color);
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.comingIPO {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  color: var(--chart_title);
  border-left: 5px solid var(--primary-color);
  padding-right: 20px;
  padding-left: 13px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.mt20 {
  margin-top: 20px;
}

/*  el-tabs -------------------------------------------------------------------------- */
.el-tabs {
  background-color: var(--bigger-card-bgc);
  /* padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144)); */
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}

::v-deep .el-tab-pane {
  display: block;
  /* height: 784px; */
  height: var(--TABPANEHEIGHT);
  /* height: calc(var(--ROOTFONTFIZE) * (1040 / 144)); */
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

/* iconfont */

.iconfont {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144)) !important;
}

.iconfont:hover {
  cursor: pointer;
}

/* // 分页 - start */

.el-pagination {
  text-align: center;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

::v-deep .el-pagination__total,
::v-deep .el-pagination__jump {
  color: var(--pagination_editor_color) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
}

::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
  background-color: transparent;
  color: var(--pagination_prev_color) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
}

::v-deep .el-pager li {
  background-color: var(--pagination_li_bgc) !important;
  color: var(--pagination_li_color) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144)) !important;
}

::v-deep .el-pager li.active {
  background-color: var(--primary-color) !important;
  color: var(--pagination_li_active_color) !important;
}

::v-deep .el-pagination__editor.el-input .el-input__inner {
  background-color: var(--pagination_editor_bgc) !important;
  color: var(--pagination_editor_color);
  border: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    var(--pagination_editor_brc);
}

::v-deep .el-timeline-item__timestamp {
  position: absolute;
  left: calc(var(--ROOTFONTFIZE) * (-150 / 144));
  top: 0;
  color: var(--pagination_timestamp_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}
/* // 分页 - end */

/* el-table start */

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid var(--table_brc);
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  /* background-color: var(--table_color_hover); */
  color: var(--table_color_hover);
}

::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell:hover {
  cursor: pointer;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc);
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc);
}

/* el-table end */

/* el-dialog start */
/* ::v-deep .el-dialog__header {
  background-color: #2a2a2b;
  background-color: #3b3d42;
}

::v-deep .el-dialog__title {
  color: #fff;
}

::v-deep .el-dialog__close.el-icon {
  color: #fff !important;
}

::v-deep .el-dialog__body {
  background-color: #2a2a2b;
  background-color: #3b3d42;
  color: #fff;
  border-top: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid #484849;
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid #484849;
  display: flex;
  flex-direction: column;
}

::v-deep .el-dialog__footer {
  background-color: #2a2a2b;
  background-color: #3b3d42;
}
*/

.frontBtn {
  background-color: var(--primary-color);
  color: var(--Btn-color);
  border: none;
}

.frontBtn:hover {
  background-color: var(--primary-color);
  color: var(--Btn-color);
  /* 加个蒙版mask */
  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ); /* Safari 和旧版浏览器兼容性 */
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ); /* 标准语法 */
}

/* el-dialog end */

/* dialog 内容 start */
.choose_area {
  /* margin-top: 24px; */
  display: flex;
  /* padding-left: 45px; */
}

.choose_area div {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  /* line-height: 20px; */
  color: rgba(255, 255, 255, 1);

  display: flex;
  align-items: center;
  margin-right: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.announcement {
  /* margin-top: 36px; */
  max-height: calc(var(--ROOTFONTFIZE) * (500 / 144));
  overflow-y: auto;
}

.announcement::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.announcement::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 5px;
}

.announcement::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  background-color: transparent;
}

.Announcement_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (15 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (90 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (1 / 144)) solid
    rgba(118, 118, 118, 1);
}

.Announcement_item_left {
  flex: 10;
}

.Announcement_item_left_two {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: rgba(254, 254, 254, 1);
  color: var(--comingIPO_color);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* background-color: pink; */
}

.Announcement_item_left_two:hover {
  cursor: pointer;
}

.Announcement_item_left_three {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(158, 158, 158, 1);
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.Announcement_item_right {
  position: relative;
  /* color: red; */
  color: var(--Btn-color2);
}

.icon-pdf {
  color: #fff;
  font-size: calc(var(--ROOTFONTFIZE) * (24 / 144));
  position: static;
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

/* dialog 内容 end */
</style>
